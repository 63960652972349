import { Product } from '@buyiq-app/product/models/product';

export class PromotionTargets {
    chainIds: Array<number> = [];
    catalogIds: Array<number> = [];
    stateIds: Array<number> = [];

    constructor(options?: Partial<PromotionTargets>) {
        Object.assign(this, options);
    }
}

export class Advertiser {
    name: string;
    id: number;
    legacyId: number;
    activePromotions: number;
    logo: string;

    constructor(options?: Partial<Advertiser>) {
        Object.assign(this, options);
    }
}

export class PromotionEmail {
    recipient: string;
    subjectLine: string;

    constructor(options?: Partial<PromotionEmail>) {
        Object.assign(this, options);
    }
}

export class PromotionBrand {
    id: number;
    name: string;
    url: string;
    email: PromotionEmail;

    constructor(options?: Partial<PromotionBrand>) {
        Object.assign(this, options);
    }
}

export class PromotionNewProduct {
    sampleEmail: PromotionEmail;

    constructor(options?: Partial<PromotionNewProduct>) {
        Object.assign(this, options);
    }
}

export class PromotionSample {
    maxEmailResponses: number;

    constructor(options?: Partial<PromotionSample>) {
        Object.assign(this, options);
    }
}

export class PromotionMgcAd {
    fullPageAdImageUrl: string;

    constructor(options?: Partial<PromotionMgcAd>) {
        Object.assign(this, options);
    }
}

export class PromotionContactInformation {
    email: PromotionEmail;
    phone: string;

    constructor(options?: Partial<PromotionContactInformation>) {
        Object.assign(this, options);
    }
}

export class Promotion {
    id: number;
    name: string;
    startDate: Date;
    endDate: Date;
    elevated: boolean;
    advertiser: Advertiser;
    description: string;
    title: string;
    displayLocations: Array<PromotionDisplayLocationType>;
    targets: PromotionTargets;
    brand: PromotionBrand;
    newProduct: PromotionNewProduct;
    sample: PromotionSample;
    mgcAd: PromotionMgcAd;
    contactInformation: PromotionContactInformation;
    products: Array<Product>;
    typeId: PromotionTypeId;
    imageUrl: string;
    thumbnailUrl: string;
    industryVerticals: number []

    // UI only
    hasUserResponse = false;

    constructor(options?: Partial<Promotion>) {
        Object.assign(this, options);
    }
}

export enum PromotionTypeId {
    Deal = 9,
    NewVendor = 10,
    NewProduct = 11,
    Sample = 12,
    BannerFormatA = 13,
    BannerFormatB = 14,
    FullPageAd = 15,
    DisplayAd = 16,
    PencilAd = 17
}

export enum PromotionDisplayLocationType {
    Search = 1,
    OrderCentralDashboard = 2,
    All = 3,
    GcEdiDashboard = 4
}

export class PromotionParameters {
    advertiserId: number;
    advertiserIds: Array<number>;
    promoTypes: Array<PromotionTypeId>;
    brandIds: Array<number>;
    storeId: number;
    startDate: Date;
    endDate: Date;
    upc: string;
    displayLocations: Array<PromotionDisplayLocationType>;

    constructor(options?: Partial<PromotionParameters>) {
        Object.assign(this, options);
    }
}

export class NewProductsCache {
    vendorName: string;
    vendorId: number;

    constructor(options?: Partial<NewProductsCache>) {
        Object.assign(this, options);
    }
}


export type PromotionFilterFn = (promotionResponse: Promotion) => boolean;
