import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Amplify } from '@aws-amplify/core';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.useProductionMode) {
    enableProdMode();
}

Amplify.configure({
    Auth: {
        region: environment.region,
        identityPoolId: environment.identityPoolId,
        userPoolId: environment.userPoolId,
        userPoolWebClientId: environment.cognitoClientId,
        authenticationFlowType: 'USER_PASSWORD_AUTH'
    }
});

document.addEventListener('DOMContentLoaded', () => {
    platformBrowserDynamic()
        .bootstrapModule(AppModule)
        .catch(err => {
            // eslint-disable-next-line no-console
            console.log(err);
        });
});
