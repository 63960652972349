import { PromotionTypeId } from '@buyiq-app/promotions/models/promotion';

export const pages = {
    newProducts: 'New Products',
    batchReview: 'Batch Review',
    newVendors: 'New Vendors',
    missedDeals: 'Missed Deals',
    samples: 'Samples',
    scan: 'Scan',
    promotionsPage: 'Full Page Ads Page',
};

export const components = {
    sampleCard: 'Sample Card',
    productCard: 'Product Card',
    newVendorCard: 'New Vendors Card',
    newProductButton: 'New Products button',
    newProductsCardAddToOrder: 'New Products card add to order',
    newProductsCardRequestInfo: 'New Products card request information',
    newProductsRequestInformationDialog:
        'New Products request information dialog',
    newProductsCancelInformationRequest:
        'New Products cancel information request',
    newProductsSubmitInformationRequest:
        'New Products submit information request',
    newProductReturnNotification: 'New Product return notification',
    newProductsCardAdButton: 'New Products card ad button',
    newProductsCardDescription: 'New Products card description',
    newVendorsCardDescription: 'New Vendors card description',
    newVendorsRequestInformationDialog: 'New Vendors request account dialog',
    details: 'Details',
    navigationMenuSample: 'Main Menu Samples Option',
    samplesRequestInformationDialog: 'Samples request information dialog',
    samplesRequestInformationCancel:
        'Samples request information dialog cancel button',
    samplesRequestInformationSubmit:
        'Samples request information dialog submit button',
    allMissedDealsProductCardGraphic: 'All Missed Deals product card graphic',
    newVendorsMenuItem: 'New Vendors menu item',
    newVendorsCardAdButton: 'New Vendors card ad button',
    newVendorsCardRequestInfo: 'New Vendors card request information',
    newVendorsCancelInformationRequest:
        'New Vendors cancel information request',
    newVendorsSubmitInformationRequest:
        'New Vendors submit information request',
    allMissedDealsMenuItem: 'All Missed Deals menu item',
    allMissedDealsProductCardAdButton:
        'All Missed Deals product card ad button',
    missedDealsCardAddToOrder: 'Missed deals card add to order',
    missedDealsAlert: 'Review page missed deals alert',
    dealsReturnAlert: 'Scan page return to missed deals alert',
};

export const promotionTypeToComponentName = new Map<PromotionTypeId, string>([
    [PromotionTypeId.NewProduct, components.productCard],
    [PromotionTypeId.NewVendor, components.newVendorCard],
    [PromotionTypeId.Sample, components.sampleCard],
    [PromotionTypeId.Deal, components.productCard],
]);

export const promotionDisplayName = new Map<PromotionTypeId, string>([
    [PromotionTypeId.NewProduct, 'New Product'],
    [PromotionTypeId.Sample, 'Sample'],
    [PromotionTypeId.Deal, 'Missed Deal'],
    [PromotionTypeId.NewVendor, 'New Vendor'],
]);
