import { Injectable } from '@angular/core';
import { Impression } from '@buyiq-app/promotions/models/impression';
import { ImpressionResource } from '@buyiq-app/promotions/resources/impression.resource';
import { ApplicationId } from '@buyiq-core/models/application';
import { UserService } from '@buyiq-core/user/user.service';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Click } from '../models/click';
import { ClickResource } from '../resources/click.resource';

@Injectable({
    providedIn: 'root'
})
export class PromotionTrackingService {
    constructor(
        private userService: UserService,
        private impressionResource: ImpressionResource,
        private clickResource: ClickResource
    ) {
    }

    logClick(click: Click): Observable<Click> {
        return this.userService.getCurrentUser()
            .pipe(
                switchMap(user => {
                    const updatedClick = new Click({
                        ...click,
                        userId: user.id,
                        storeId: user.currentStore?.legacyStoreId ?? null,
                        application: ApplicationId.BuyiqSamplePage,
                        upc: click.upc ?? null,
                        quantity: click.quantity ?? null
                    });
                    return this.clickResource.create(updatedClick);
                })
            );
    }

    logImpression(impression: Impression): Observable<Impression> {
        return this.userService.getCurrentUser()
            .pipe(
                switchMap(user => {
                    const updatedImpression = new Impression({
                        ...impression,
                        userId: user.id,
                        storeId: user.currentStore?.legacyStoreId ?? null,
                        application: ApplicationId.BuyiqSamplePage,
                        upc: impression.upc ?? null,
                        quantity: impression.quantity ?? null
                    });
                    return this.impressionResource.create(updatedImpression);
                })
            );
    }
}
