import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Auth, CognitoUser } from '@aws-amplify/auth';
import { Hub, HubCapsule } from '@aws-amplify/core';
import { UserService } from '@buyiq-core/user/user.service';
import { BehaviorSubject, from, Observable, of, Subject, switchMap } from 'rxjs';
import { catchError, map, takeUntil, tap } from 'rxjs/operators';
import { UserCredentials } from '../models/login';
import { routeParts } from '../route-parts';

@Injectable({
    providedIn: 'root'
})
export class AuthService implements OnDestroy {
    private loggedInState = new BehaviorSubject<boolean>(false);
    private readonly unsubscribe = new Subject<void>();

    constructor(
        private router: Router,
        private userService: UserService
    ) {
        Hub.listen('auth', evt => this.onAuthEvent(evt));
        this.isAuthenticated();
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    isAuthenticated(): Observable<boolean> {
        // Had to catch the rejected promise here to prevent a console error about a rejected promise
        // Related to: https://github.com/angular/angular/issues/31680
        const userAuthenticationPromise = Auth.currentUserPoolUser()
            .catch(() => false);

        return from(userAuthenticationPromise)
            .pipe(
                catchError(() => of(false)),
                map(isAuthorized => !!isAuthorized),
                tap(val => this.loggedInState.next(val))
            );
    }

    redirectToLogin(url: string): Observable<boolean> {
        return from(this.router.navigate([routeParts.login], {
            queryParams: { redir: url }
        }));
    }

    authenticate(userCredentials: UserCredentials): Observable<CognitoUser> {
        const username = userCredentials.username.toLowerCase().trim();
        const password = userCredentials.password;
        return from(Auth.signIn(username, password));
    }

    logout(): Observable<any> {
        return from(Auth.signOut()).pipe(
            switchMap(() => this.userService.cachePreviousUserInfo()),
            switchMap(() => this.userService.clearCurrentUserFromStorage()),
            takeUntil(this.unsubscribe)
        );
    }

    getAuthToken(): Observable<string> {
        return from(Auth.currentSession())
            .pipe(
                catchError(() => of(null)),
                map(u => u && u.getIdToken().getJwtToken())
            );
    }

    /* Returns an Observable of the login state - true means the
       user is logged in */
    getLoginState(): Observable<boolean> {
        return this.loggedInState;
    }

    private onAuthEvent(data: HubCapsule) {
        switch (data.payload.event) {
            case 'signIn': {
                this.loggedInState.next(true);
                break;
            }
            case 'signIn_failure':
            case 'signOut': {
                this.loggedInState.next(false);
                break;
            }
            default: {
                break;
            }
        }
    }
}
