import { Injectable } from '@angular/core';
import { UpcCorrectionConfiguration } from './upc-correction';

@Injectable({
    providedIn: 'root'
})
export class UpcCorrectionService {

    /**
     * @description
     * Trims the leading characters of a UPC and applies a check digit based on the given check digit
     * @param upc The UPC to be corrected
     * @returns Returns the altered UPC
     */
    trimAndAppendCheckDigit(upc: string, configuration: UpcCorrectionConfiguration): string {
        let correctedUpc = upc;

        if(configuration.totalCharactersToTrim > 0) {
            correctedUpc = this.trimLeadingCharacters(correctedUpc, configuration.totalCharactersToTrim);
        }

        if(configuration.requiresCheckDigit){
            correctedUpc = this.appendCheckDigit(correctedUpc);
        }

        return correctedUpc;
    }

    /**
     * @description
     * Returns the given string with the specified number of characters removed from the left of the string.
     * If @param upc has fewer characters than @param charactersToTrim, an empty string will be returned.
     * @param upc The UPC to be trimmed
     * @param charactersToTrim The number of characters to trim from the left of the UPC
     * @returns The trimmed UPC
     */
    trimLeadingCharacters(upc: string, charactersToTrim: number): string {
        // If the value is negative, substr will return N characters from the right
        charactersToTrim = charactersToTrim < 0 ? 0 : charactersToTrim;
        return (upc ?? '').substr(charactersToTrim);
    }

    /**
     * @description
     * Returns the check digit of a given UPC. If the string is empty or has no value, 0 will be returned.
     * @param upc The UPC to calculate a check digit for
     * @returns The check digit for the given UPC
     */
    calculateCheckDigit(upc: string): number {
        const reversedUpcDigits = (upc ?? '')
            .split('')
            .reverse()
            .map(digit => Number(digit));

        const sumOfDigits = reversedUpcDigits.reduce((sum: number, currentDigit: number, index: number) => {
            const digitValue = this.isEven(index) ? currentDigit * 3 : currentDigit;
            return sum + digitValue;
        }, 0);

        return (10 - (sumOfDigits % 10)) % 10;
    }

    /**
     * @description
     * Calculates the check digit of a UPC and returns the UPC with the check digit appended.
     * @param upc The UPC to which the check digit will be appended
     * @returns Returns the UPC with the check digit appended
     */
    appendCheckDigit(upc: string): string {
        const checkDigit = this.calculateCheckDigit(upc);

        return `${upc ?? ''}${checkDigit}`;
    }

    private isEven(value: number): boolean {
        return (value ?? 0) % 2 === 0;
    }
}
