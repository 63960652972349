import { Injectable } from '@angular/core';
import { SortComparisonResult } from './sort';

@Injectable({
    providedIn: 'root'
})
export class SortService {
    private readonly defaultStringValue = '';
    private readonly defaultNumberValue = Number.MIN_SAFE_INTEGER;
    private readonly defaultBooleanValue = false;

    compareStrings(a: string, b: string, isAsc = true): number {
        const value1 = a || this.defaultStringValue;
        const value2 = b || this.defaultStringValue;
        const comparison = value1.localeCompare(value2);

        // The 0 check is needed to explicitly return 0 instead of -0 if the sort direction is descending
        return comparison === 0 ? SortComparisonResult.SameValues : comparison * this.getSortDirectionModifier(isAsc);
    }

    compareNumbers(a: number, b: number, isAsc = true): number {
        const value1 = a || this.defaultNumberValue;
        const value2 = b || this.defaultNumberValue;
        const comparison = value1 - value2;

        // The 0 check is needed to explicitly return 0 instead of -0 if the sort direction is descending
        return comparison === 0 ? SortComparisonResult.SameValues : comparison * this.getSortDirectionModifier(isAsc);
    }

    /**
     * Ascending order: [false, true]; descending order: [true, false]
     * Returns -1, 0, or 1 based on the comparison specs for Array.sort()
     */
    compareBooleans(a: boolean, b: boolean, isAsc = true): number {
        const value1 = a || this.defaultBooleanValue;
        const value2 = b || this.defaultBooleanValue;

        if (value1 === value2) {
            return SortComparisonResult.SameValues;
        }

        // If value1 is true, value2 must be false; if value1 is false, value2 must be true
        const comparison = value1 ? SortComparisonResult.SwapOrder : SortComparisonResult.KeepCurrentOrder;

        return comparison * this.getSortDirectionModifier(isAsc);
    }

    private getSortDirectionModifier(isAsc: boolean): number {
        return isAsc ? 1 : -1;
    }
}
