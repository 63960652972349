import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route } from '@angular/router';
import { OnlineManagerService } from '@buyiq-app/product/services/online-manager.service';
import { DialogService } from '@buyiq-core/dialog/dialog.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class OfflineGuard  {
    constructor(
        private onlineManagerService: OnlineManagerService,
        private dialogService: DialogService
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        return this.isOnline(route);
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot): Observable<boolean> {
        return this.isOnline(childRoute);
    }

    canLoad(route: Route): Observable<boolean> {
        return this.isOnline(route);
    }

    isOnline(route: Route | ActivatedRouteSnapshot): Observable<boolean> {
        const isOnline = this.onlineManagerService.isOnline;

        if (!isOnline) {
            const routeTitle = route?.data?.title;
            const title = 'In Offline Mode';
            const message = routeTitle
                ? `You cannot navigate to the ${routeTitle} page while in offline mode. Please try again when you come back online.`
                : 'You cannot navigate here while in offline mode. Please try again when you come back online';
            return this.dialogService.alert(title, message).pipe(
                map(() => false)
            );
        }

        return of(true);
    }
}
