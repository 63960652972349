import { Advertiser } from '@buyiq-app/promotions/models/promotion';
import { Vendor } from '@buyiq-core/models/vendor';
import { ApiCollectionResponse } from '@cia-front-end-apps/shared/api-interaction';
import { Special } from '@cia-front-end-apps/shared/specials';
import { FilterKey, ProductFilters } from '@buyiq-shared/models/search-filter';

export class MissedDealParameters {
    chainStoreId: number;
    vendorIds: Array<number>;
    brandIds: Array<number>;
    advertiserIds: Array<number>;
    distributorIds: Array<number>;

    constructor(options?: Partial<MissedDealParameters>) {
        Object.assign(this, options);
    }
}

export class MissedDealProduct {
    upc: string;
    description: string;
    productImageUrl: string;
    vendor: Vendor;
    brand: Brand;
    advertiser: Advertiser;
    specials: Array<Special>;

    // UI only
    salesText: string;
    promotionUrl: string;
    promotionId: number;

    constructor(options?: Partial<MissedDealProduct>) {
        Object.assign(this, options);
    }
}

export class MissedDealSearchApiResponse extends ApiCollectionResponse<MissedDealProduct> {
    filters = new ProductFilters();

    constructor(options?: Partial<MissedDealSearchApiResponse>) {
        super();
        Object.assign(this, options);
    }
}

export class Brand {
    id: number;
    name: string;
    productCount: number;
    upc: string;
    itemId: number;
}

export class Type {
    id: number;
    shortLabel: string;
    label: string;
    description: string;
    subRequired: boolean;
}

export class MissedDealFilterState {
    brands = new Array<FilterKey>();

    constructor(options?: Partial<MissedDealFilterState>) {
        Object.assign(this, options);
    }
}

export class MissedDealsCache {
    vendorName: string;
    vendorId: number;

    constructor(options?: Partial<MissedDealsCache>) {
        Object.assign(this, options);
    }
}
