import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {

    constructor(
        private authService: AuthService
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.authService.getAuthToken()
            .pipe(
                switchMap(token => {
                    let request = req.clone();

                    if (token) {
                        request = req.clone({
                            setHeaders: {
                                Authorization: `Bearer ${token}`
                            }
                        });
                    }

                    return next.handle(request);
                })
            );
    }
}
