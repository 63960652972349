import { Injectable } from '@angular/core';
import { AudioFile, audioFileName } from '@buyiq-core/models/audio-files';
import { AudioModule } from './audio.module';
import { WebAudioLibrary } from './web-audio-library';

@Injectable({
    providedIn: AudioModule
})
export class AudioService {
    private supportsAudio = false;
    private readonly baseFilePath = 'assets/audio';

    constructor(private webAudioLibrary: WebAudioLibrary) { }

    /**
     * This should only be called from the AppComponent if the user turns on the audio setting.
     * If the setting is off, we don't need to load audio files in the background.
     */
    enableAudio(): void {
        this.supportsAudio = true;

        const filePaths = new Map<AudioFile, string>();

        Array.from(audioFileName.keys())
            .forEach(key => {
                const filePath = `${this.baseFilePath}/${audioFileName.get(key)}`;
                filePaths.set(key, filePath);
            });

        this.webAudioLibrary.loadAudioFiles(filePaths);
    }

    play(audioFile: AudioFile): void {
        if (this.supportsAudio) {
            this.webAudioLibrary.play(audioFile);
        }
    }
}
