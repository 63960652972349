import { Injectable, OnDestroy } from '@angular/core';
import { Notification, NotificationRef } from '@buyiq-shared/models/notification';
import { BehaviorSubject, Observable, of, race } from 'rxjs';
import { delay, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class NotificationService implements OnDestroy {
    notificationChanges: Observable<Array<NotificationRef>>;

    private nextId = 100;
    private notificationRefs: Array<NotificationRef>;
    private readonly notificationChangeSubject: BehaviorSubject<Array<NotificationRef>>;

    constructor() {
        this.notificationRefs = new Array<NotificationRef>();
        this.notificationChangeSubject = new BehaviorSubject<Array<NotificationRef>>(this.notificationRefs);
        this.notificationChanges = this.notificationChangeSubject.asObservable();
    }

    ngOnDestroy(): void {
        this.dismissAll();
        this.notificationChangeSubject.unsubscribe();
    }

    /**
     * Returns true if the notification action was triggered.
     * Returns false if the notification was dismissed.
     */
    show(notification: Notification): Observable<boolean> {
        const notificationRef = new NotificationRef({
            id: notification.id ?? this.getNextId(),
            data: notification
        });
        this.notificationRefs.push(notificationRef);
        this.notificationChangeSubject.next(this.notificationRefs);

        const observables = [
            notificationRef.onDismiss()
        ];

        if (notification.duration > 0) {
            const onAutoDismiss = of(false)
                .pipe(
                    delay(notification.duration),
                    map(wasActionTriggered => {
                        this.dismiss(notificationRef.id);

                        return wasActionTriggered;
                    })
                );
            observables.push(onAutoDismiss);
        }

        return race(observables);
    }

    dismiss(id: number): void {
        const notificationRef = this.notificationRefs.find(ref => ref.id === id);
        if (notificationRef) {
            notificationRef.dismiss();
            this.notificationRefs = this.notificationRefs.filter(n => notificationRef !== n);
            this.notificationChangeSubject.next(this.notificationRefs);
        }
    }

    dismissAll(): void {
        this.notificationRefs.forEach(ref => ref.dismiss());
        this.notificationRefs = [];
        this.notificationChangeSubject.next(this.notificationRefs);
    }

    private getNextId(): number {
        const nextId = this.nextId;
        this.nextId += 1;
        return nextId;
    }
}
