import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from '@buyiq-core/models/app-settings';
import { DataResource } from '@buyiq-custom-types';
import { Observable } from 'rxjs';
import { VendorRanking, VendorRankingList, VendorRankingRequest } from '../models/vendor-ranking';

@Injectable({
    providedIn: 'root'
})
export class VendorRankingResource implements DataResource<VendorRanking> {
    private baseUrl: string;

    constructor(private http: HttpClient, private appSettings: AppSettings) {
        this.baseUrl = `${this.appSettings.apis.user}`;
    }

    getAll(request: VendorRankingRequest): Observable<Array<VendorRanking>> {
        const { chainId, chainStoreId, userId, isApl, searchText, isSku } = request;
        const url = this.buildUrl(chainId, chainStoreId, userId, isApl, searchText, isSku);
        return this.http.get<Array<VendorRanking>>(url);
    }

    getBatchRankings(
        chainId: number,
        chainStoreId: number,
        userId: number,
        isApl: boolean
    ): Observable<Array<VendorRankingList>> {
        const url = `${this.baseUrl}/chains/${chainId}/stores/${chainStoreId}/users/${userId}/vendor-rankings/batch`;
        const params = new HttpParams({
            fromObject: {
                isApl: isApl.toString()
            }
        });
        return this.http.get<Array<VendorRankingList>>(url, {params});
    }

    private buildUrl(chainId: number, chainStoreId: number, userId: number, isApl: boolean, searchText: string, isSku: boolean): string {
        return `${this.baseUrl}/chains/${chainId}/stores/${chainStoreId}/users/${userId}/vendor-rankings` +
            `?isApl=${isApl}&searchText=${searchText}&isSku=${isSku}`;
    }
}
