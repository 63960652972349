import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Message } from '@buyiq-app/system-messages/models/message';
import { routeParts } from '@buyiq-core/route-parts';
import moment from 'moment';

@Component({
    selector: 'buyiq-unread-messages-dialog',
    templateUrl: './unread-messages-dialog.component.html',
    styleUrls: ['./unread-messages-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnreadMessagesDialogComponent implements OnInit {
    messages: Array<Message>;
    systemMessagesRoute = routeParts.systemMessages;

    constructor(
        public dialogRef: MatDialogRef<UnreadMessagesDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Array<Message>,
        private cdRef: ChangeDetectorRef
    ) {
    }

    ngOnInit(): void {
        this.messages = this.data.map(message => {
            return new Message({
                ...message,
                startDateDisplay: moment.utc(message.startDate).fromNow()
            });
        });
        this.cdRef.detectChanges();
    }
}
