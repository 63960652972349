export class Deal {
    discountPrice: number;
    thresholdQty: number;
    saleText: string;

    constructor(options?: Partial<Deal>) {
        Object.assign(this, options);
    }
}

export class SpecialsType {
    id: number;
    shortLabel: string;
    label: string;
    description: string;
    subRequired: boolean;

    constructor(options?: Partial<SpecialsType>) {
        Object.assign(this, options);
    }
}

export class Special {
    upc: string;
    startDate: Date;
    endDate: Date;
    teaserText: string;
    image: any;
    saveUpTo: string;
    type = new SpecialsType();
    deals = new Array<Deal>();
    vendorId: number;

    constructor(options?: Partial<Special>) {
        Object.assign(this, options);
    }
}

export class SpecialsParameters {
    daysAfter: number;
    daysBefore: number;
    orderId?: number;
    upcs?: Array<string>;
    vendorId: number;
    currentSpecials: boolean;
    chainStoreId: number;

    constructor(options?: Partial<SpecialsParameters>) {
        Object.assign(this, options);
    }
}

export enum ProductSpecialStatus {
    Current,
    Future,
    Expired
}

export class ProductSpecialAlert {
    status: ProductSpecialStatus;
    toolTip: string;
    primaryText: string;
    minimumOrder: number;
    dateAction: string;

    constructor(options?: Partial<ProductSpecialAlert>) {
        Object.assign(this, options);
    }
}

export class SubscriptionSetting {
    vendorId: number;
    specialsTypeId: number;

    constructor(options?: Partial<SubscriptionSetting>) {
        Object.assign(this, options);
    }
}
