import { Injectable } from '@angular/core';
import { BatchItem } from '@buyiq-app/batch/models/batch-item';
import { LocalForageService } from '@buyiq-core/storage/local-forage.service';
import { ItemKey, OfflineStorage, StoreName } from '@buyiq-core/storage/storage';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BatchStorageService implements OfflineStorage<BatchItem> {
    constructor(
        private localForageService: LocalForageService
    ) {
    }

    clearStorage(): Observable<void> {
        return this.localForageService.clearStorage(StoreName.Batch);
    }

    getItem(key: string): Observable<BatchItem> {
        return this.localForageService.getItem(key, StoreName.Batch);
    }

    removeItem(key: string): Observable<void> {
        return this.localForageService.removeItem(key, StoreName.Batch);
    }

    setItem(key: string, value: any): Observable<BatchItem> {
        return this.localForageService.setItem(key, value, StoreName.Batch);
    }

    getAll(): Observable<Array<BatchItem>> {
        return this.localForageService.getAll(StoreName.Batch);
    }

    setItems(items: Array<BatchItem>): Observable<Array<BatchItem>> {
        return this.localForageService.setItems(items, ItemKey.Upc, StoreName.Batch);
    }
}
