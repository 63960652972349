import { ContactInformation } from '@buyiq-core/models/contact-information';
import { Vendor } from '@buyiq-core/models/vendor';
import {
    ApiCollectionResponse,
    ApiFilter,
    SearchState,
} from '@cia-front-end-apps/shared/api-interaction';
import { FilterOptionId } from '@cia-front-end-apps/shared/filter-menu';

export class Product {
    attributes: Array<string>;
    brandId: number;
    brandName: string;
    casePackSize: number;
    category: string;
    categoryId: number;
    department: string;
    departmentId: number;
    description: string;
    extendedDescription: string;
    form: string;
    image: string;
    thumbnail: string;
    itemSize: string;
    orderUnits: string;
    subCategory: string;
    subCategoryId: number;
    upc: string;
    lnUpc?: string;
    aplDiscontinued?: boolean;
    vendorAttributes: Array<VendorAttribute>;
    movementData: Array<MovementData>;
    isAssortmentHostedImg: boolean;

    constructor(options?: Partial<Product>) {
        Object.assign(this, options);
    }
}

export class MovementData {
    order: number;
    numberOfUnitsSold: string | number;

    constructor(options?: Partial<MovementData>) {
        Object.assign(this, options);
    }
}

const movementLength = 7;
export const emptyMovementData: Array<MovementData> = Array.from(
    { length: movementLength },
    () =>
        new MovementData({
            order: 1,
            numberOfUnitsSold: 'N/A',
        })
);

export class VendorAttribute {
    groupId: string;
    sku: string;
    retailerSKU: string;
    casePackSize: number;
    orderUnits: string;
    orderMultiple: number;
    minimumOrder: number;
    wsp: number;
    srp: number;
    freeShippingAmount: number;
    minimumOrderAmount: number;
    distributor: boolean;
    datePriceChanged: Date;
    vendorDiscontinueDate: Date;
    dateNew: Date;
    estimatedRestockDate: Date;
    contactInformation: ContactInformation;
    vendor: Vendor;
    specialsPrice: number;
    gtin: string;
    ean: string;
    isbn: string;
    upc: string;
    extraLeadTime: boolean;
    assortmentAttributes: AssortmentAttribute;
    negotiatedPrice: number;

    constructor(options?: Partial<VendorAttribute>) {
        Object.assign(this, options);
    }
}

export class AssortmentAttribute {
    color: string;
    size: string;
    material: string;
    gender: string;
    availableDate: Date;

    constructor(options?: Partial<AssortmentAttribute>) {
        Object.assign(this, options);
    }
}

export enum ProductStatus {
    New = 1,
    Discontinued,
    PriceChange,
    Sale,
    FutureSale,
    Focus,
    OutOfStock,
}

export class ProductSearchFilters {
    brands = new Array<ApiFilter>();
    categories = new Array<ApiFilter>();
    suppliers = new Array<ApiFilter>();
    productStatuses = new Array<ApiFilter>();

    constructor(options?: Partial<ProductSearchFilters>) {
        Object.assign(this, options);
    }
}

export class ProductSearchApiResponse extends ApiCollectionResponse<Product> {
    filters = new ProductSearchFilters();
    spellCheckSuggestions = new Array<string>();

    constructor(options?: Partial<ProductSearchApiResponse>) {
        super();
        Object.assign(this, options);
    }
}

export class SearchTextChangeEvent {
    searchText: string;
    isExactPhrase?: boolean;

    constructor(options?: Partial<SearchTextChangeEvent>) {
        Object.assign(this, options);
    }
}

export class ProductParameters {
    vendors: Array<number>;
    brands: Array<FilterOptionId>;
    distributors: Array<number>;
    isExactPhrase?: boolean;
    searchType: ProductSearchType;

    constructor(options?: Partial<ProductParameters>) {
        Object.assign(this, options);
    }
}

export enum ProductSearchType {
    Generic = 1,
    Upc,
    Sku,
    RetailerSku,
}

export const upcNormalLength = 12;

export class ProductSearchParameters {
    searchState: SearchState;
    productParameters: ProductParameters;
    temporaryVendorId = 0;
    batchSearch = false;
    freeTextSearch = false;

    constructor(options?: Partial<ProductSearchParameters>) {
        Object.assign(this, options);
    }
}
