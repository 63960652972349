export class BatchSummary {
    totalCost?: number;
    itemCount = 0;
    type = BatchSummaryType.Generic;

    constructor(options?: Partial<BatchSummary>) {
        Object.assign(this, options);
    }
}

export enum BatchSummaryType {
    Generic,
    Product,
    Inventory,
    ShelfTags,
    Receiving
}
