import { HasPendingChanges } from '@buyiq-core/storage/storage';

export enum ErrorType {
    Delete = 'delete',
    Add = 'add',
}

export class ShelfTag implements HasPendingChanges {
    createdDate: Date;
    upc: string;
    scannedOrder: number;
    id: number;
    legacyStoreId: number;
    status: number;
    userId: number;
    hasPendingChanges: boolean;
    errorType: ErrorType;
    storageKey: string;

    constructor(options?: Partial<ShelfTag>) {
        Object.assign(this, options);
    }
}
