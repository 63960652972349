import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StockedProductVendors } from '@buyiq-app/stocked-not-scanned/models/stocked-product';
import { AppSettings } from '@buyiq-core/models/app-settings';
import { DataResource } from '@buyiq-custom-types';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class StockedProductVendorsResource implements DataResource<StockedProductVendors> {
    private readonly baseUrl: string;

    constructor(private http: HttpClient, private appSettings: AppSettings) {
        this.baseUrl = `${this.appSettings.apis.order}`;
    }

    get(chainStoreId: number): Observable<StockedProductVendors> {
        const url = `${this.baseUrl}/stocked-product-vendors`;
        const params = new HttpParams({
            fromObject: {
                chainStoreId: (chainStoreId || 0).toString(),
            }
        });

        return this.http.get<StockedProductVendors>(url, { params });
    }
}
