import { HasPendingChanges } from '@buyiq-core/storage/storage';

export class Inventory implements HasPendingChanges {
    id: number;
    upc: string;
    quantity: number;
    scannedOrder: number;
    createdDate: Date;
    legacyStoreId: number;
    status: number;

    /**
     * @description If the item has pending changes, it has not been synced to the API and only exists in the local app
     */
    hasPendingChanges = false;

    constructor(options?: Partial<Inventory>) {
        Object.assign(this, options);
    }
}

export class InventoryQuantityChangeEvent {
    inventory: Inventory;
    newQuantity: number;

    constructor(options?: Partial<InventoryQuantityChangeEvent>) {
        Object.assign(this, options);
    }
}

export const inventoryKeys = {
    inventories: (chainStoreId: number) => ['inventories', chainStoreId] as const,
    inventory: (chainStoreId: number, upc: string) => [...inventoryKeys.inventories(chainStoreId), upc] as const,
    create: ['createInventory'] as const,
    replace: ['replaceInventory'] as const,
    remove: ['removeInventory'] as const
}
