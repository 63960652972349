import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from '@buyiq-core/models/app-settings';
import { DataResource } from '@buyiq-custom-types';
import { ApiCollectionResponse, ParameterBuilder } from '@cia-front-end-apps/shared/api-interaction';
import { Special, SpecialsParameters } from '@cia-front-end-apps/shared/specials';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class SpecialsResource implements DataResource<Special> {
    private readonly baseUrl: string;

    constructor(private http: HttpClient, private appSettings: AppSettings) {
        this.baseUrl = `${this.appSettings.apis.search}`;
    }

    getAll(specialParameters: SpecialsParameters, useBatch: boolean): Observable<Array<Special>> {
        const url = this.buildUrl();
        let params = new HttpParams({
            fromObject: {
                daysBefore: (specialParameters.daysBefore || 0).toString(),
                daysAfter: (specialParameters.daysAfter || 30).toString(),
                currentSpecials: (specialParameters.currentSpecials || true).toString(),
                vendorId: (specialParameters.vendorId || 0).toString(),
                chainStoreId: (specialParameters.chainStoreId || 0).toString(),
                useBatch: (useBatch || false)
            }
        });

        if (specialParameters.upcs) {
            params = ParameterBuilder.buildArrayParameter(specialParameters.upcs, 'upcs', params);
        }

        return this.http.get<ApiCollectionResponse<Special>>(url, { params })
            .pipe(
                map(response => response ? response.items : new Array<Special>()),
                catchError(error => {
                    if (error.status === 400) {
                        return of([]);
                    }

                    return throwError(() => error);
                })
            );
    }

    private buildUrl(specialId?: number): string {
        let url = `${this.baseUrl}/specials`;

        if (specialId) {
            url = `${url}/${specialId}`;
        }

        return url;
    }
}
