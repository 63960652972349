import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Feedback } from '../models/feedback';
import { FeedbackResource } from '../resources/feedback.resource';

@Injectable({
    providedIn: 'root'
})
export class FeedbackService {

    constructor(private feedbackResource: FeedbackResource) {
    }

    createFeedback(feedback: Feedback): Observable<Feedback> {
        return this.feedbackResource.create(feedback);
    }
}
