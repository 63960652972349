import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from '@buyiq-core/models/app-settings';
import { applicationName } from '@buyiq-core/models/application';
import { User } from '@buyiq-core/models/user';
import { DataResource } from '@buyiq-custom-types';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UserResource implements DataResource<User> {
    private baseUrl: string;

    constructor(private http: HttpClient, private appSettings: AppSettings) {
        this.baseUrl = `${this.appSettings.apis.user}/users`;
    }

    get(): Observable<User> {
        const url = `${this.baseUrl}/self`;
        const params = new HttpParams({
            fromObject: {
                application: (applicationName).toString()
            }
        });
        return this.http.get<User>(url, { params });
    }

    replace(user: User): Observable<User> {
        const url = `${this.baseUrl}/self`;
        const params = new HttpParams({
            fromObject: {
                application: (applicationName).toString(),
            }
        });
        return this.http.put<User>(url, user, { params });
    }
}
