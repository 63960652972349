import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from '@buyiq-core/models/app-settings';
import { DataResource } from '@buyiq-custom-types';
import { Observable } from 'rxjs';
import { Impression } from '../models/impression';

@Injectable({
    providedIn: 'root'
})
export class ImpressionResource implements DataResource<Impression> {
    private baseUrl: string;

    constructor(private http: HttpClient, private appSettings: AppSettings) {
        this.baseUrl = `${this.appSettings.apis.promo}`;
    }

    create(impression: Impression): Observable<Impression> {
        const url = `${this.baseUrl}/impressions`;
        return this.http.post<Impression>(url, impression);
    }
}
