<input class="quantity-input"
       autocomplete="off"
       pattern="[0-9]*"
       max="999"
       min="0"
       maxlength="3"
       type="tel"
       #quantityInput
       [class.warning]="!isValid && !isReadOnly"
       [gcSelectOnClick]="!isReadOnly"
       [formControl]="formControl"
       [readonly]="isReadOnly"
       [buyiqAllowOnlyDigits]="true"
       (click)="onClickInput()"
       (blur)="onBlurInput()"
       (focusin)="onFocusInput()"
       (keyup.enter)="onPressEnter()"/>
