import { Product } from '@buyiq-app/product/models/product';
import { PromotionTypeId } from '@buyiq-app/promotions/models/promotion';
import { ApiCollectionResponse } from '@cia-front-end-apps/shared/api-interaction';
import { FilterKey, ProductFilters } from '@buyiq-shared/models/search-filter';

export class PromotionProductParameters {
    startDate: Date;
    endDate: Date;
    promoId: number;
    chainStoreId: number;
    promotionTypeIds: Array<PromotionTypeId>;
    vendorIds: Array<number>;
    brandIds: Array<number>;
    distributorIds: Array<number>;
    useRankedDistributors: boolean;

    constructor(options?: Partial<PromotionProductParameters>) {
        Object.assign(this, options);
    }
}

export class PromotionProduct extends Product {
    promotionId: number;
    salesDescription: string;

    constructor(options?: Partial<PromotionProduct>) {
        super(options);
        Object.assign(this, options);
    }
}

export class PromotionProductsCollectionResponse extends ApiCollectionResponse<Product> {
    filters = new ProductFilters();

    constructor(options?: Partial<PromotionProductsCollectionResponse>) {
        super(options);
        Object.assign(this, options);
    }
}

export class PromotionProductFilterState {
    brands = new Array<FilterKey>();

    constructor(options?: Partial<PromotionProductFilterState>) {
        Object.assign(this, options);
    }
}
