export enum UploadErrorType {
    Offline,
    Other
}

export class BatchUploadEvent<T> {
    items: Array<T>;
    error: string;
    lastError: number;
    errorType: UploadErrorType;

    constructor(options?: Partial<BatchUploadEvent<T>>) {
        Object.assign(this, options);
    }
}
