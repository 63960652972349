export type FilterKey = number | string;

export enum FilterDate {
    Seven = 7,
    Thirty = 30,
    Sixty = 60,
    Ninety = 90
}

export class FilterGroup {
    displayName: string;
    id: string;

    constructor(options?: Partial<FilterGroup>) {
        Object.assign(this, options);
    }
}

export class SearchFilter {
    key: FilterKey;
    quantity?: number;
    description: string;
    isSelected: boolean;
    group?: string;

    constructor(options?: Partial<SearchFilter>) {
        Object.assign(this, options);
    }
}

export class FilterChangeEvent {
    filterId: FilterKey;
    groupId: string;
    isSelected: boolean;

    constructor(options?: Partial<FilterChangeEvent>) {
        Object.assign(this, options);
    }
}

export function cloneOrderFilters(filters: Array<SearchFilter>): Array<SearchFilter> {
    return filters.map(f => new SearchFilter(f));
}

export class ProductFilters {
    brands = new Array<SearchFilter>();
    suppliers = new Array<SearchFilter>();
    vendors = new Array<SearchFilter>();
    statuses = new Array<SearchFilter>();

    constructor(options?: Partial<ProductFilters>) {
        Object.assign(this, options);
    }
}
