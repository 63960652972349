<h2 mat-dialog-title>
    Select Store
</h2>

<mat-dialog-content>
    <mat-radio-group [(ngModel)]="selectedStore"
                     class="radio-group">
        <mat-radio-button *ngFor="let store of stores"
                          color="accent"
                          class="radio-button"
                          [value]="store">
            <div class="store">
                <strong>{{store.name}}</strong> - <span>Store ID: {{store.legacyStoreId}}</span>
            </div>
            <div class="address">
                <div>
                    {{store.contactInformation.address.address1}}
                    <span *ngIf="store.contactInformation.address.address2">{{store.contactInformation.address.address2}}</span>
                </div>
                <div>
                    {{store.contactInformation.address.city}} {{store.contactInformation.address.state}} {{store.contactInformation.address.postalCode}}
                </div>
            </div>
        </mat-radio-button>
    </mat-radio-group>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button
            (click)="onSubmit()"
            color="accent"
            class="button">
        Select
    </button>
</mat-dialog-actions>
