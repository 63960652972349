import { Injectable } from '@angular/core';
import { AudioFile } from '@buyiq-core/models/audio-files';
import { Howl } from 'howler';
import { AudioModule } from './audio.module';

/**
 * This should only be used in the audio service.
 */
@Injectable({
    providedIn: AudioModule
})
export class WebAudioLibrary {
    private loadedFiles: Map<AudioFile, Howl>;

    loadAudioFiles(audioFilePaths: Map<AudioFile, string>): void {
        this.loadedFiles = new Map<AudioFile, Howl>();

        audioFilePaths.forEach((filePath: string, fileKey: AudioFile) => {
            const file = this.loadAudioFile(filePath);
            this.loadedFiles.set(fileKey, file);
        });
    }

    play(audioFile: AudioFile): void {
        const file = this.loadedFiles.get(audioFile);
        if (file) {
            file.play();
        }
    }

    private loadAudioFile(filePath: string): Howl {
        return new Howl({
            src: [filePath]
        });
    }
}
