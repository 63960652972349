export enum ProductNotificationId {
    NotFound = 0,
    Discontinued = 1,
    PriceChange = 2,
    OutOfStock = 3,
    MinMulti = 4,
    ScheduledScan = 5,
    AplDiscontinued = 6,
    InternalError = 7,
    InvalidData = 8,
    ExtraLeadTime = 9
}
