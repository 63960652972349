export const isoDateFormat = 'YYYY-MM-DD';

export enum DateOption {
    PreviousMonth,
    MonthToDate,
    PreviousYear,
    YearToDate,
    CustomDate
}

export class DateRange {
    startDate: Date;
    endDate: Date;

    constructor(options?: Partial<DateRange>) {
        Object.assign(this, options);
    }
}
