import { FeatureFlagParameter } from './feature-flags';

export class NavigationItem {
    displayName: string;
    icon: string;
    path: string;
    featureFlagParameters: FeatureFlagParameter;

    constructor(options?: Partial<NavigationItem>) {
        Object.assign(this, options);
    }
}

export class NavigationState {
    rawUrl: string;
    sanitizedUrl: string;

    constructor(options?: Partial<NavigationState>) {
        Object.assign(this, options);
    }
}

export class NavigationItemGroup {
    name: string;
    items: Array<NavigationItem>;

    constructor(options?: Partial<NavigationItemGroup>) {
        Object.assign(this, options);
    }
}
