<mat-sidenav-container class="sidenav-container">
    <mat-sidenav mode="over"
                 fixedInViewport="true"
                 #sidenav>
        <nav class="navigation">
            <buyiq-navigation (clickNavigationItem)="sidenav.close()"></buyiq-navigation>
        </nav>
    </mat-sidenav>

    <header class="app-header">
        <buyiq-toolbar class="toolbar"
                       (openNavigationMenu)="sidenav.open()">
        </buyiq-toolbar>
    </header>

    <main *ngIf="!loading else loadingTemplate"
          class="main-content"
          [class.login]="isLoginPage"
          cdkVirtualScrollingElement>
        <router-outlet></router-outlet>
    </main>
</mat-sidenav-container>

<ng-template #loadingTemplate>
    <div class="loading-container">
        <mat-spinner color="accent"></mat-spinner>

        <p class="loading-message">Loading...</p>
    </div>
</ng-template>
