import { Injectable } from '@angular/core';
import { LocalForageService } from '@buyiq-core/storage/local-forage.service';
import { OfflineStorage, StorageData, StoreName } from '@buyiq-core/storage/storage';
import { Observable } from 'rxjs';
import { VendorRanking } from '@buyiq-core/models/vendor-ranking';

@Injectable({
    providedIn: 'root'
})
export class VendorRankingStorageService implements OfflineStorage<StorageData<Array<VendorRanking>>> {
    constructor(
        private localForageService: LocalForageService
    ) {
    }

    clearStorage(): Observable<void> {
        return this.localForageService.clearStorage(StoreName.VendorRanking);
    }

    getItem(key: string): Observable<StorageData<Array<VendorRanking>>> {
        return this.localForageService.getItem(key, StoreName.VendorRanking);
    }

    removeItem(key: string): Observable<void> {
        return this.localForageService.removeItem(key, StoreName.VendorRanking);
    }

    setItem(key: string, value: Array<VendorRanking>): Observable<StorageData<Array<VendorRanking>>> {
        const data = new StorageData({
            data: value,
            lastUpdated: Date.now()
        })
        return this.localForageService.setItem(key, data, StoreName.VendorRanking);
    }
}
