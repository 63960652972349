export class ProductOrderHistory {
    dateOrdered: string | Date;
    quantity: string | number;
    vendor: string;

    constructor(options?: Partial<ProductOrderHistory>) {
        Object.assign(this, options);
    }
}

export const blankProductOrderHistory: Array<ProductOrderHistory> = Array.from(
    { length: 6 },
    () => (new ProductOrderHistory({
        dateOrdered: 'N/A',
        quantity: '-',
        vendor: ''
    }))
);
