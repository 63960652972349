<mat-toolbar color="primary">
    <mat-toolbar-row class="toolbar-row">
        <button type="button"
                mat-icon-button
                [disabled]="!isAuthorized"
                (click)="onOpenNavigationMenu()">
            <mat-icon>
                menu
            </mat-icon>
        </button>
        <buyiq-batch-summary *ngIf="batchSummary && isAuthorized else empty"
                             [batchSummary]="batchSummary"
                             [pageTitle]="pageTitle">
        </buyiq-batch-summary>
        <a class="logo-container" routerLink="./"><img class="logo" src="assets/order-central-logo.svg"></a>
        <a class="smaller-logo-container" routerLink="./"><img class="logo" src="assets/sps-logo.svg"></a>
    </mat-toolbar-row>
</mat-toolbar>

<mat-progress-bar [mode]="progressBarMode"
                  [value]="100"
                  color="accent"
                  class="progress-bar">
</mat-progress-bar>

<ng-template #empty>
    <div></div>
</ng-template>
