import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'buyiq-simple-dialog',
    templateUrl: './simple-dialog.component.html',
    styleUrls: ['./simple-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleDialogComponent {
    title: string;
    content: string;
    actionButtonText: string;
    cancelButtonText: string;

    constructor(@Inject(MAT_DIALOG_DATA) private data: any) {
        this.title = this.data.title;
        this.content = this.data.content;
        this.actionButtonText = this.data.actionButtonText;
        this.cancelButtonText = this.data.cancelButtonText;
    }
}
