// Url fragments should be lowercase, dash-separated words
export const routeParts = {
    unauthorized: 'unauthorized',
    login: 'login',
    logout: 'logout',
    newProducts: 'new-products',
    systemMessages: 'system-messages',
    samples: 'samples',
    settings: 'settings',
    products: 'scan',
    batch: 'review',
    deals: 'deals',
    search: 'search',
    newVendors: 'new-vendors',
    shelfTags: 'shelf-tags',
    inventory: 'inventory',
    stockedNotScanned: 'stocked-not-scanned',
    receiving: 'receiving',
    savedItems: 'saved-items'
};

export const pageTitles = {
    unauthorized: 'Unauthorized',
    login: 'Login',
    logout: 'Logout',
    newProducts: 'New Products',
    notFound: 'Not Found',
    systemMessages: 'System Messages',
    samples: 'Samples',
    settings: 'Settings',
    products: 'Scan',
    batch: 'Review',
    deals: 'Deals',
    search: 'Search',
    newVendors: 'New Vendors',
    shelfTags: 'Shelf Tags',
    inventory: 'Inventory',
    stockedNotScanned: 'Stocked Not Scanned',
    receiving: 'Receiving',
    myFavorites: 'My Favorite Items'
};
