import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { BatchSummary } from '@buyiq-shared/models/batch-summary';

@Component({
    selector: 'buyiq-batch-summary',
    templateUrl: './batch-summary.component.html',
    styleUrls: ['./batch-summary.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BatchSummaryComponent implements OnInit, OnChanges {
    @Input() batchSummary: BatchSummary;
    @Input() pageTitle: string;

    totalCost: number;
    itemCount: number;
    itemLabel: string;
    hasTotalCost = false;
    hasItemCount = false;

    ngOnInit(): void {
        this.updateDisplay(this.batchSummary);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.batchSummary && !changes.batchSummary.firstChange) {
            this.updateDisplay(this.batchSummary);
        }
    }

    private updateDisplay(batchSummary: BatchSummary): void {
        this.totalCost = batchSummary?.totalCost;
        this.hasTotalCost = batchSummary?.totalCost >= 0;
        this.itemCount = batchSummary?.itemCount ?? 0;
        this.hasItemCount = batchSummary?.itemCount >= 0;
        this.itemLabel = batchSummary?.itemCount === 1 ? 'item' : 'items';
    }
}
