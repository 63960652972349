import { NgModule } from '@angular/core';
import { FabContainerComponent } from './fab-container.component';
import { SharedAngularModule } from '../shared-angular.module';

@NgModule({
    declarations: [
        FabContainerComponent
    ],
    imports: [
        SharedAngularModule
    ],
    exports: [
        FabContainerComponent
    ]
})
export class FabContainerModule {
}
