import { ChangeDetectionStrategy, Component } from '@angular/core';

/**
 * @description This component can be used as a generic entry point for feature modules with
 * multiple pages.
 * In the module's routing, add the component as the top-level component with an empty
 * route name. Other components should be added as child routes.
 * @example
 *     {
 *      path: '',
 *      component: RoutablePageComponent,
 *      children: [
 *          {
 *              path: 'dashboard',
 *              component: ManagementDashboardComponent,
 *          }
 *      ]
 *  }
 */
@Component({
    selector: 'buyiq-routable-page',
    templateUrl: './routable-page.component.html',
    styleUrls: ['./routable-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RoutablePageComponent { }
