import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from '@buyiq-core/models/app-settings';
import { LegacyVendor } from '@buyiq-core/models/legacy-vendor';
import { DataResource } from '@buyiq-custom-types';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SupportedVendorsResource implements DataResource<LegacyVendor> {
    private readonly baseUrl: string;

    constructor(private http: HttpClient, private appSettings: AppSettings) {
        this.baseUrl = `${this.appSettings.apis.user}`;
    }

    getAll(chainStoreId: number): Observable<Array<LegacyVendor>> {
        const url = this.buildUrl(chainStoreId);
        return this.http.get<Array<LegacyVendor>>(url);
    }

    private buildUrl(chainStoreId: number): string {
        return `${this.baseUrl}/chain-stores/${chainStoreId}/supported-vendors`;
    }
}
