import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from '@buyiq-core/models/app-settings';
import { DataResource } from '@buyiq-custom-types';
import { Observable } from 'rxjs';
import { OrderAccount } from '../models/order-account';

@Injectable({
    providedIn: 'root'
})
export class AccountsResource implements DataResource<OrderAccount> {
    private readonly baseUrl: string;

    constructor(private http: HttpClient, private appSettings: AppSettings) {
        this.baseUrl = `${this.appSettings.apis.vendorData}/accounts`;
    }

    getAll(vendorId: number, chainStoreId: number): Observable<Array<OrderAccount>> {
        const url = this.baseUrl;
        const params = this.buildParameters(vendorId, chainStoreId);
        return this.http.get<Array<OrderAccount>>(url, { params });
    }

    private buildParameters(vendorId: number, chainStoreId: number): HttpParams {
        return new HttpParams({
            fromObject: {
                vendorId: vendorId.toString(),
                chainStoreId: chainStoreId.toString(),
            }
        });
    }
}
