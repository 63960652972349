import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot } from '@angular/router';
import { FeatureFlagParameter } from '@buyiq-core/models/feature-flags';
import { routeParts } from '@buyiq-core/route-parts';
import { UserService } from '@buyiq-core/user/user.service';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class AuthFeatureFlagGuard  {

    constructor(
        private authService: AuthService,
        private userService: UserService,
        private router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.validateFeatureFlag(route.data.featureFlag);
    }

    canLoad(route: Route): Observable<boolean> {
        return this.validateFeatureFlag(route.data.featureFlag);
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.validateFeatureFlag(childRoute.data.featureFlag);
    }

    /* Redirects to unauthorized page when the user does not have the appropriate feature flag */
    private validateFeatureFlag(featureFlagParameter: FeatureFlagParameter): Observable<boolean> {
        return this.authService.isAuthenticated()
            .pipe(
                switchMap(isLoggedIn => {
                    return isLoggedIn ? this.checkUserHasFeatureFlag(featureFlagParameter) : of(isLoggedIn);
                })
            );
    }

    private checkUserHasFeatureFlag(featureFlagParameter: FeatureFlagParameter): Observable<boolean> {
        return this.userService.getCurrentUser()
            .pipe(
                map(user => {
                    let hasRequiredFeatureFlag = true;
                    if (!!featureFlagParameter.allow) {
                        hasRequiredFeatureFlag = user.features.includes(featureFlagParameter.allow);
                    }

                    let hasRejectedFeatureFlag = false;
                    if (!!featureFlagParameter.prevent) {
                        hasRejectedFeatureFlag = user.features.includes(featureFlagParameter.prevent);
                    }

                    if (!hasRequiredFeatureFlag || hasRejectedFeatureFlag) {
                        this.router.navigate([`/${routeParts.unauthorized}`]);
                        return false;
                    }

                    return true;
                })
            );
    }
}
