import { NgModule } from '@angular/core';
import { PreventMenuCloseModule } from '../prevent-menu-close';
import { SharedAngularModule } from '../shared-angular.module';
import { FilterMenuComponent } from './filter-menu/filter-menu.component';
import { SortOptionComponent } from './sort-option/sort-option.component';

@NgModule({
    imports:[
        SharedAngularModule,
        PreventMenuCloseModule
    ],
    exports: [
        FilterMenuComponent,
        SortOptionComponent
    ],
    declarations: [
        FilterMenuComponent,
        SortOptionComponent
    ]
})
export class FilterMenuModule {
}
