import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from '@buyiq-core/models/app-settings';
import { DataResource } from '@buyiq-custom-types';
import { map, Observable } from 'rxjs';
import { ShelfTag } from '../models/shelf-tag';

@Injectable({
    providedIn: 'root'
})
export class ShelfTagsResource implements DataResource<ShelfTag> {
    private readonly baseUrl: string;

    constructor(private http: HttpClient, private appSettings: AppSettings) {
        this.baseUrl = `${this.appSettings.apis.order}/shelf-tags-batch`;
    }

    getAll(chainStoreId: number): Observable<Array<ShelfTag>> {
        const url = this.baseUrl;
        const params = new HttpParams({
            fromObject: {
                chainStoreId: chainStoreId.toString()
            }
        });
        return this.http.get<Array<ShelfTag>>(url, { params: params });
    }

    create(chainStoreId: number, shelfTag: ShelfTag): Observable<ShelfTag> {
        const url = this.baseUrl;
        const params = new HttpParams({
            fromObject: {
                chainStoreId: chainStoreId.toString()
            }
        });
        return this.http.post<ShelfTag>(url, shelfTag, { params: params });
    }

    update(chainStoreId: number): Observable<Array<ShelfTag>> {
        const url = this.baseUrl;
        const params = new HttpParams({
            fromObject: {
                chainStoreId: chainStoreId.toString()
            }
        });

        return this.http.patch<Array<ShelfTag>>(url, {}, { params: params });
    }

    remove(chainStoreId: number, shelfTagId: number): Observable<boolean> {
        const url = this.baseUrl;
        const params = new HttpParams({
            fromObject: {
                chainStoreId: chainStoreId.toString(),
                shelfTagId: shelfTagId.toString()
            }
        });

        return this.http.delete(url, { params: params })
            .pipe(
                map(successResponse => true)
            );
    }
}
