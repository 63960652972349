import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class FocusService {
    searchInputFocusRequests: Observable<void>;
    private searchInputFocusSubject = new Subject<void>();

    constructor() {
        this.searchInputFocusRequests = this.searchInputFocusSubject.asObservable();
    }

    requestSearchInputFocus(): void {
        this.searchInputFocusSubject.next();
    }
}
