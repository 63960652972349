export class VendorDiscount {
    id: number;
    chainStoreId: number;
    legacyVendorId: number;
    vendorName: string;
    discountPercentage: number;
    expirationDate: Date;

    constructor(options?: Partial<VendorDiscount>) {
        Object.assign(this, options);
    }
}

export class VendorDiscountFilter {
    legacyVendorId?: number;
    startDate?: Date;
    endDate?: Date;

    constructor(options?: Partial<VendorDiscountFilter>) {
        Object.assign(this, options);
    }
}
