import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'buyiq-titled-panel',
    templateUrl: './titled-panel.component.html',
    styleUrls: ['./titled-panel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TitledPanelComponent {
    @Input() panelTitle: string;
}
