import { ContactInformation } from './contact-information';

export class ChainStore {
    legacyStoreId: number;
    id: number;
    name: string;
    isBuyiqEnabled: boolean;
    contactInformation = new ContactInformation();
    isFreeTrial: boolean;
    industryVerticals: number[];


    constructor(options?: Partial<ChainStore>) {
        Object.assign(this, options);
    }
}
