import { VendorDiscount } from '@buyiq-core/models/vendor-discount';

export class VendorInfo {
    legacyVendorId: number;
    partitionEnabled: boolean;
    discounts: Array<VendorDiscount>;

    constructor(options?: Partial<VendorInfo>) {
        Object.assign(this, options);
    }
}
