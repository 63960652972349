import { CommonModule, CurrencyPipe, DatePipe, PercentPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FabContainerModule } from '@cia-front-end-apps/shared/fab-container';
import { FilterMenuModule } from '@cia-front-end-apps/shared/filter-menu';
import { PreventMenuCloseModule } from '@cia-front-end-apps/shared/prevent-menu-close';
import { SelectOnClickModule } from '@cia-front-end-apps/shared/select-on-click';
import { AllowOnlyDigitsDirective } from './allow-only-digits/allow-only-digits.directive';
import { BatchSummaryComponent } from './batch-summary/batch-summary.component';
import { EmptyStateComponent } from './empty-state/empty-state.component';
import { ExpansionPanelTitleComponent } from './expansion-panel/expansion-panel-title.component';
import { ExpansionPanelComponent } from './expansion-panel/expansion-panel.component';
import { FilterMenuComponent } from './filter-menu/filter-menu.component';
import { MaterialModule } from './material/material.module';
import { NotificationComponent } from './notification/notification.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { ProductThumbnailComponent } from './product-thumbnail/product-thumbnail.component';
import { QuantityInputComponent } from './quantity-input/quantity-input.component';
import { RoutablePageComponent } from './routable-page/routable-page.component';
import { SearchInputComponent } from './search-input/search-input.component';
import { SortMenuOptionComponent } from './sort-menu-option/sort-menu-option.component';
import { SortMenuComponent } from './sort-menu/sort-menu.component';
import { TitledPanelComponent } from './titled-panel/titled-panel.component';
import { ProductScanComponent } from './product-scan/product-scan.component';
import { ProductImageProviderComponent } from '@cia-front-end-apps/shared/product-image-provider';
import { SubscribeDirective } from '@ngneat/subscribe';

export const sharedDeclarations = [
    EmptyStateComponent,
    RoutablePageComponent,
    TitledPanelComponent,
    ProductThumbnailComponent,
    SearchInputComponent,
    NotificationComponent,
    NotificationsComponent,
    ExpansionPanelComponent,
    ExpansionPanelTitleComponent,
    FilterMenuComponent,
    SortMenuComponent,
    SortMenuOptionComponent,
    QuantityInputComponent,
    BatchSummaryComponent,
    ProductScanComponent,
    ProductImageProviderComponent
];

export const sharedDirectives = [
    AllowOnlyDigitsDirective
];

export const sharedPipes = [
];

export const sharedProviders = [
    CurrencyPipe,
    DatePipe,
    PercentPipe
];

export const sharedImports = [
    PreventMenuCloseModule,
    SelectOnClickModule,
    FilterMenuModule,
    FabContainerModule,
    SubscribeDirective
];

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        ...sharedImports
    ],
    exports: [
        CommonModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        ...sharedImports,
        ...sharedDeclarations,
        ...sharedDirectives,
        ...sharedPipes
    ],
    declarations: [
        ...sharedDeclarations,
        ...sharedDirectives,
        ...sharedPipes
    ],
    providers: [
        ...sharedProviders
    ]
})
export class SharedModule {
}
