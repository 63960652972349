import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AppStateService } from '@buyiq-core/app-state/app-state.service';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class PreloadStateResolver  {
    constructor(
        private appStateService: AppStateService
    ) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.appStateService.preloadState
            .pipe(
                filter(hasPreloadedData => hasPreloadedData),
                // This observable has to complete to trigger the resolver
                take(1)
            );
    }
}
