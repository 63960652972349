import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from '@buyiq-core/models/app-settings';
import { DataResource } from '@buyiq-custom-types';
import { ApiCollectionResponse, SearchState } from '@cia-front-end-apps/shared/api-interaction';
import { Observable } from 'rxjs';
import { VendorDiscount, VendorDiscountFilter } from '../models/vendor-discount';

@Injectable({
    providedIn: 'root'
})
export class VendorDiscountsResource implements DataResource<VendorDiscount> {
    private readonly baseUrl: string;

    constructor(private http: HttpClient, private appSettings: AppSettings) {
        this.baseUrl = `${this.appSettings.apis.search}`;
    }

    getAll(
        chainStoreId: number,
        searchState: SearchState,
        searchFilter: VendorDiscountFilter
    ): Observable<ApiCollectionResponse<VendorDiscount>> {
        const url = this.buildUrl(chainStoreId);

        const requestParams = new HttpParams({
            fromObject: {
                pageIndex: (searchState.pageIndex || 0).toString(),
                pageSize: (searchState.pageSize || 25).toString(),
                sortBy: searchState.sortBy || '',
                sortDirection: searchState.sortDirection || 'asc',
                search: searchState.search || '',
                legacyVendorId: (searchFilter.legacyVendorId || '').toString(),
                startDate: searchFilter.startDate?.toISOString() || '',
                endDate: searchFilter.endDate?.toISOString() || ''
            }
        });
        return this.http.get<ApiCollectionResponse<VendorDiscount>>(url, { params: requestParams });
    }

    private buildUrl(chainStoreId: number, discountId?: number): string {
        let url = `${this.baseUrl}/chain-stores/${chainStoreId}/vendor-discounts`;

        if (discountId) {
            url = `${url}/${discountId}`;
        }

        return url;
    }
}
