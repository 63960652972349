export enum AudioFile {
    Discontinued,
    OutOfStock,
    PriceChange,
    Sale,
    Failure,
    Success,
}

export const audioFileName = new Map<AudioFile, string>([
    [AudioFile.Discontinued, 'discontinued.mp3'],
    [AudioFile.OutOfStock, 'out-of-stock.mp3'],
    [AudioFile.PriceChange, 'price-change.mp3'],
    [AudioFile.Sale, 'sale.mp3'],
    [AudioFile.Failure, 'failure.mp3'],
    [AudioFile.Success, 'success.mp3']
]);
