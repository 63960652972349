import { HttpErrorResponse } from '@angular/common/http';
import {
    MonoTypeOperatorFunction,
    retryWhen,
    scan,
} from 'rxjs';
import { delay as delayOperator, tap } from 'rxjs/operators';

export enum ApiErrorCode {
    Offline = 0,
    BadRequest = 400,
    Unauthorized = 401,
    NotFound = 404,
    Conflict = 409,
    Internal = 500,
}

export class ModelPropertyError {
    name: string;
    value: any;
    reason: string;

    constructor(options?: Partial<ModelPropertyError>) {
        Object.assign(this, options);
    }
}

export class ApiErrorResponse {
    title: string;
    status: ApiErrorCode;
    detail: string;
    errors: Array<ModelPropertyError>;

    constructor(options?: Partial<ApiErrorResponse>) {
        Object.assign(this, options);
    }
}

/**
 * @description Generally most components will respond to an Observable errors in the same way;
 * in some instances, they will need to recover from or handle differently specific error
 * conditions. The function translates an HttpErrorResponse to an error response with details about
 * the type of error as well as individual validation errors for any invalid fields.
 *
 * @param response - the response returned from an HttpClient call
 * @example
 *   // Resource
 *   replace(order: Order) {
 *     http.put<Order>(url, order)
 *        .pipe(
 *          catchError((response: HttpErrorResponse) => {
 *         const error = parseErrorResponse(response);
 *         return throwError(error); // RxJS method
 *       })
 *      );
 *   }
 *
 *   // Consumer
 *   updateOrder(order)
 *     .subscribe({
 *       next: (successResponse) => {},
 *       error: (errorResponse) => {
 *          // errorResponse.errors contains errors relating to specific fields in a model. These can be used to create form errors
 *       }
 *     });
 */
export function parseErrorResponse(
    response: HttpErrorResponse
): ApiErrorResponse {
    // Accounts for errors which are just an error message
    const hasErrorObject = !(typeof response.error === 'string');
    const errorTitle = hasErrorObject
        ? response.error.title
        : response.statusText;
    const errorDetail = hasErrorObject ? response.error.detail : response.error;
    const validationErrors = hasErrorObject
        ? response.error.errors
        : new Array<ModelPropertyError>();

    return new ApiErrorResponse({
        title: errorTitle,
        detail: errorDetail,
        status: response.status,
        errors: validationErrors,
    });
}

export function retryWithDelay<T>(
    delay: number,
    count = 1
): MonoTypeOperatorFunction<T> {
    return (input) =>
        input.pipe(
            retryWhen((errors) =>
                errors.pipe(
                    scan((acc, error) => ({ count: acc.count + 1, error }), {
                        count: 0,
                        error: undefined,
                    }),
                    tap((current) => {
                        if (count && current.count > count) {
                            throw current.error;
                        }
                    }),
                    delayOperator(delay)
                )
            )
        );
}
