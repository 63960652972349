import { environment } from '@buyiq-environments/environment';

export class AppSettings {
    apis: ApiUrlCollection;
    baseUrl: string;

    constructor(options?: Partial<AppSettings>) {
        Object.assign(this, { environment: {} }, options);
    }
}

class ApiUrlCollection {
    user: string;
    search: string;
    promo: string;
    order: string;
    vendorData: string;
    message: string;

    constructor(options?: Partial<ApiUrlCollection>) {
        Object.assign(this, options);
    }
}

export const appSettings = new AppSettings({
    apis: new ApiUrlCollection({
        order: buildRemoteUrl('orderapi'),
        user: buildRemoteUrl('userapi'),
        vendorData: buildRemoteUrl('vendordataapi'),
        search: buildRemoteUrl('searchapi'),
        promo: buildRemoteUrl('promoapi'),
        message: buildRemoteUrl('messageapi'),

        // Local API URLs - do not check these in uncommented
        // order: `http://localhost:3952`,
        // user: `http://localhost:7777`,
        // vendorData: `http://localhost:21448`,
        // search: `http://localhost:15019`,
        // promo: `http://localhost:7888`
    }),
    baseUrl: environment.baseUrl
});

function buildRemoteUrl(subDomain: string): string {
    return `https://${subDomain}${environment.urlSubDomain}.geniuscentral.com`;
}
