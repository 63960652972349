import { Injectable, OnDestroy } from '@angular/core';
import { BatchUploadEvent, UploadErrorType } from '@buyiq-app/batch/models/batch-upload-event';
import { retryWithDelay } from '@buyiq-core/models/error-handler';
import { ShelfTagStorageService } from '@buyiq-core/storage/shelf-tag-storage.service';
import { ToolbarService } from '@buyiq-core/toolbar/toolbar.service';
import { BatchSummary, BatchSummaryType } from '@buyiq-shared/models/batch-summary';
import { forkJoin, Observable, of, Subject, switchMap } from 'rxjs';
import { catchError, map, takeUntil, tap } from 'rxjs/operators';
import { ErrorType, ShelfTag } from './models/shelf-tag';
import { ShelfTagsResource } from './resources/shelf-tags.resource';

@Injectable({
    providedIn: 'root'
})
export class ShelfTagsService implements OnDestroy {
    private shelfTags: Array<ShelfTag> = [];
    private pendingDeletions = [];
    private hasLoadedShelfTags = false;
    private unsubscribe = new Subject<void>();

    constructor(
        private shelfTagsStorageService: ShelfTagStorageService,
        private shelfTagsResource: ShelfTagsResource,
        private toolbarService: ToolbarService
    ) {
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    getShelfTags(chainStoreId: number): Observable<Array<ShelfTag>> {
        let request = of(this.shelfTags);

        if (!this.hasLoadedShelfTags) {
            request = this.shelfTagsResource.getAll(chainStoreId).pipe(
                map(shelfTags => shelfTags.map(this.mapStorageKey)),
                tap(shelfTags => {
                    this.shelfTags = shelfTags ?? [];
                    this.hasLoadedShelfTags = true;
                    this.hydrateStorage(shelfTags);
                }),
                catchError(() => {
                    return this.shelfTagsStorageService.getAll().pipe(
                        map(shelfTags => {
                            this.shelfTags = (shelfTags ?? []).filter(shelfTag => shelfTag.errorType !== ErrorType.Delete);
                            this.pendingDeletions = (shelfTags ?? []).filter(shelfTag => shelfTag.errorType === ErrorType.Delete);

                            return this.shelfTags;
                        }),
                    );
                })
            );
        }

        return request;
    }

    addShelfTag(chainStoreId: number, shelfTag: ShelfTag): Observable<ShelfTag> {
        return this.shelfTagsResource.create(chainStoreId, shelfTag).pipe(
            catchError(() => {
                const updatedShelfTag = new ShelfTag({
                    ...shelfTag,
                    hasPendingChanges: true,
                    errorType: ErrorType.Add
                });
                return of(updatedShelfTag);
            }),
            tap(newShelfTag => {
                const withStorageKey = this.mapStorageKey(newShelfTag);
                this.shelfTags = [...this.shelfTags, withStorageKey];
                this.updateItemInStorage(withStorageKey);
            })
        );
    }

    submitShelfTags(chainStoreId: number): Observable<BatchUploadEvent<ShelfTag>> {
        const delayInterval = 1000;
        const retries = 4;

        return this.shelfTagsResource.update(chainStoreId).pipe(
            retryWithDelay(delayInterval, retries),
            switchMap(() => {
                this.shelfTags = [];
                this.pendingDeletions = [];
                return this.shelfTagsStorageService.clearStorage();
            }),
            map(() => new BatchUploadEvent({
                items: this.shelfTags
            })),
            catchError((error) => {
                const offlineErrorStatus = 0;
                const errorType = error.status === offlineErrorStatus ? UploadErrorType.Offline : UploadErrorType.Other;
                const batchUploadEvent = new BatchUploadEvent({
                    items: this.shelfTags,
                    error: error.title,
                    lastError: Date.now(),
                    errorType
                });
                return of(batchUploadEvent);
            }),
        );
    }

    updateBatchSummary(batch: Array<ShelfTag>): void {
        const batchSummary = this.getBatchSummary(batch);
        this.toolbarService.updateBatchSummary(batchSummary);
    }

    removeShelfTag(chainStoreId: number, shelfTag: ShelfTag): Observable<boolean> {
        let request = of(true);

        if (shelfTag.id !== null && shelfTag.id !== undefined) {
            request = this.shelfTagsResource.remove(chainStoreId, shelfTag.id);
        }

        return request.pipe(
            tap(() => {
                this.shelfTags = this.shelfTags.filter(i => i.storageKey !== shelfTag.storageKey);
                this.removeItemFromStorage(shelfTag);
            }),
            catchError(() => {
                const updatedShelfTag = new ShelfTag({
                    ...shelfTag,
                    hasPendingChanges: true,
                    errorType: ErrorType.Delete
                });
                this.updateItemInStorage(updatedShelfTag);
                this.shelfTags = this.shelfTags.filter(i => i.storageKey !== shelfTag.storageKey);
                this.pendingDeletions = [...this.pendingDeletions, updatedShelfTag];

                return of(true);
            })
        );
    }

    filterOnlyOfflineChanges = (shelfTag: ShelfTag): boolean => {
        return (shelfTag.id === null || shelfTag.id === undefined) && shelfTag.errorType === ErrorType.Delete;
    };

    removeOfflineShelfTags(offlineShelfTags: Array<ShelfTag>): Observable<void> {
        const deletions = offlineShelfTags.map(shelfTag => {
            return this.shelfTagsStorageService.removeItem(shelfTag.storageKey);
        });
        return (deletions.length > 0 ? forkJoin([...deletions]) : of([]))
            .pipe(map(() => {
            }));
    }

    getPendingDeletions(): Array<ShelfTag> {
        return this.pendingDeletions;
    }

    clear(): void {
        this.pendingDeletions = [];
        this.shelfTags = [];
        this.hasLoadedShelfTags = false;
        this.shelfTagsStorageService.clearStorage()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe();
    }

    private getBatchSummary(batch: Array<ShelfTag>): BatchSummary {
        return new BatchSummary({
            itemCount: batch?.length ?? 0,
            type: BatchSummaryType.ShelfTags
        });
    }

    private removeItemFromStorage(shelfTag: ShelfTag): void {
        this.shelfTagsStorageService.removeItem(shelfTag.storageKey)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe();
    }

    private updateItemInStorage(shelfTag: ShelfTag): void {
        this.shelfTagsStorageService.setItem(shelfTag.storageKey, shelfTag)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe();
    }

    private hydrateStorage(shelfTags: Array<ShelfTag>): void {
        // ensure any leftover items are removed from the cache
        this.shelfTagsStorageService.clearStorage()
            .pipe(
                switchMap(() => this.shelfTagsStorageService.setItems(shelfTags)),
                takeUntil(this.unsubscribe)
            )
            .subscribe();
    }

    private mapStorageKey = (shelfTag: ShelfTag): ShelfTag => {
        return new ShelfTag({
            ...shelfTag,
            storageKey: `${shelfTag.upc}-${shelfTag.scannedOrder}`
        });
    };
}
