import {
    PersistedClient,
    Persister,
    persistQueryClient,
    persistQueryClientRestore
} from '@tanstack/react-query-persist-client';
import { del, get, set } from 'idb-keyval';
import { ENVIRONMENT_INITIALIZER } from '@angular/core';
import { injectQueryClient } from '@ngneat/query';

export function createIDBPersister(idbValidKey: IDBValidKey = "queryCache") {
    return {
        persistClient: async (client: PersistedClient) => {
            await set(idbValidKey, client);
        },
        restoreClient: async () => {
            return await get<PersistedClient>(idbValidKey);
        },
        removeClient: async () => {
            await del(idbValidKey);
        },
    } as Persister;
}
export const provideQueryClientPersistence = (persister) => {
    return {
        provide: ENVIRONMENT_INITIALIZER,
        multi: true,
        useValue: () =>  {
            const queryClient = injectQueryClient();
            persistQueryClient({ queryClient, persister: persister , maxAge: Infinity });
            persistQueryClientRestore({
                queryClient,
                persister: persister,
            });
        }
    };
}

