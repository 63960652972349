import { Injectable } from '@angular/core';
import { environment } from '@buyiq-environments/environment';
import { datadogRum } from '@datadog/browser-rum';
import { User } from '@buyiq-core/models/user';
import { ApiErrorResponse } from '@buyiq-core/models/error-handler';

@Injectable({
    providedIn: 'root'
})
export class DatadogRumService {
    initialize(): void {
        datadogRum.init({
            applicationId: environment.dataDog.applicationId,
            clientToken: environment.dataDog.clientToken,
            site: environment.dataDog.site,
            service: 'buyiq-modern',
            env: environment.useProductionMode ? 'production' : 'development',
            version: environment.buildVersion,
            sampleRate: 100,
            trackInteractions: true
        });
    }

    setUser(user: User): void {
        datadogRum.setUser({
            id: user.id.toString(),
            name: user.profile.username,
            email: user.profile.email,
            role: user.appRole,
            chain: user.chain,
            store: user.currentStore?.id,
            storeName: user.currentStore?.name,
        });
    }

    addAction(name: string, context?: any): void {
        datadogRum.addAction(name, context);
    }

    // https://docs.datadoghq.com/real_user_monitoring/browser/collecting_browser_errors
    addError(
        error: ApiErrorResponse,
        source = 'api',
        additionalData: object = null
    ): void {
        const messageObject = {
            additionalData,
            errors: error.errors,
        };

        datadogRum.addError({
            source,
            type: error.status,
            message: JSON.stringify(messageObject),
        });
    }
}
