import { BatchItemUpdateError } from '@buyiq-app/batch/models/batch-item';
import {
    Product,
    ProductParameters,
    VendorAttribute,
} from '@buyiq-app/product/models/product';
import { User } from './user';

export enum ScanSource {
    FreeTextSearch,
    StockedNotScanned,
    MissedDeals,
    NewProducts,
    SavedItems,
}

export const scanSourceNotificationSuffix = new Map<ScanSource, string>([
    [ScanSource.FreeTextSearch, 'Search'],
    [ScanSource.StockedNotScanned, 'Stocked Not Scanned'],
    [ScanSource.MissedDeals, 'Missed Deals'],
    [ScanSource.NewProducts, 'New Products'],
    [ScanSource.SavedItems, 'My Favorite Items List'],
]);

export class ScheduledScan {
    upc: string;
    legacyVendorId?: number;
    source: ScanSource;
    shouldDisplayNotification = true;
    returnUrl?: string;
    onActionTriggered: () => void;

    constructor(options?: Partial<ScheduledScan>) {
        Object.assign(this, options);
    }
}

export class ProductLookupResult {
    product: Product;
    errors = new Array<BatchItemUpdateError>();

    constructor(options?: Partial<ProductLookupResult>) {
        Object.assign(this, options);
    }
}

export class ProductScanConfiguration {
    searchText: string;
    productParameters: ProductParameters;
    selectedLegacyVendorId?: number;
    selectedVendorAttribute?: VendorAttribute;
    quantityOverride?: number;
    temporaryVendorId: number;
    wspOverride?: number;
    srpOverride?: number;
    pagePartition?: number;
    sku?: string;

    // internal properties
    numberOfTimesScanned = 0;
    user: User;

    constructor(options?: Partial<ProductScanConfiguration>) {
        Object.assign(this, options);
    }
}
