import { Injectable } from '@angular/core';
import { User } from '@buyiq-core/models/user';
import { LocalForageService } from '@buyiq-core/storage/local-forage.service';
import { StoreName, UserStorageKey } from '@buyiq-core/storage/storage';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UserStorageService {
    constructor(
        private localForageService: LocalForageService
    ) {
    }

    clearStorage(): Observable<void> {
        return this.localForageService.clearStorage(StoreName.User);
    }

    removeItem(key: UserStorageKey): Observable<void> {
        return this.localForageService.removeItem(key, StoreName.User);
    }

    getItem(key: UserStorageKey): Observable<User> {
        return this.localForageService.getItem(key, StoreName.User);
    }

    setItem(value: User, key: UserStorageKey): Observable<User> {
        return this.localForageService.setItem(key, value, StoreName.User);
    }
}
