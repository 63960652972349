<p class="title"
   [class.noSubtext]="!hasTotalCost && !hasItemCount">
    {{pageTitle}}
</p>
<p class="description">
    <span *ngIf="hasTotalCost">{{totalCost | currency}} /</span>
    <ng-container *ngIf="hasItemCount">
        {{itemCount}} <span class="item-label">{{itemLabel}}</span>
    </ng-container>
</p>
