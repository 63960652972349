import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from '@buyiq-core/models/app-settings';
import { DataResource } from '@buyiq-custom-types';
import { ApiCollectionResponse, SearchState } from '@cia-front-end-apps/shared/api-interaction';
import { Observable } from 'rxjs';

import { ProductOrderHistory } from '@cia-front-end-apps/shared/order-history/order-history';

@Injectable({
    providedIn: 'root'
})
export class OrderHistoryResource implements DataResource<ProductOrderHistory> {
    private baseUrl: string;

    constructor(private http: HttpClient, private appSettings: AppSettings) {
        this.baseUrl = `${this.appSettings.apis.order}`;
    }

    getAll(searchState: SearchState, chainStoreId: number, upc: string): Observable<ApiCollectionResponse<ProductOrderHistory>> {
        const url = `${this.baseUrl}/products/${upc}/history`;

        const params = new HttpParams({
            fromObject: {
                pageIndex: (searchState.pageIndex || 0).toString(),
                pageSize: (searchState.pageSize || 25).toString(),
                chainStoreId: (chainStoreId || 0).toString(),
                sortBy: searchState.sortBy || 'dateOrdered',
                sortDirection: searchState.sortDirection || 'desc'
            }
        });
        return this.http.get<ApiCollectionResponse<ProductOrderHistory>>(url, { params });
    }
}
