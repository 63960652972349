import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from '@buyiq-core/models/app-settings';
import { DataResource } from '@buyiq-custom-types';
import { ParameterBuilder, SearchState } from '@cia-front-end-apps/shared/api-interaction';
import { Observable } from 'rxjs';
import { MissedDealParameters, MissedDealProduct, MissedDealSearchApiResponse } from '../models/missed-deal';

@Injectable({
    providedIn: 'root'
})

export class MissedDealResource implements DataResource<MissedDealProduct> {
    private baseUrl: string;

    constructor(private http: HttpClient, private appSettings: AppSettings) {
        this.baseUrl = `${this.appSettings.apis.search}`;
    }

    getAll(
        searchState: SearchState,
        missedDealParameters: MissedDealParameters
    ): Observable<MissedDealSearchApiResponse> {
        const url = `${this.baseUrl}/missed-deal/products`;
        let params = new HttpParams({
            fromObject: {
                pageIndex: (searchState.pageIndex || 0).toString(),
                pageSize: (searchState.pageSize || 25).toString(),
                sortBy: searchState.sortBy || '',
                sortDirection: searchState.sortDirection || 'asc',
                chainStoreId: (missedDealParameters.chainStoreId || 0).toString(),
                useRankedDistributors: true
            }
        });
        params = ParameterBuilder.buildArrayParameter(missedDealParameters.advertiserIds, 'advertiserIds', params);
        params = ParameterBuilder.buildArrayParameter(missedDealParameters.vendorIds, 'vendorIds', params);
        params = ParameterBuilder.buildArrayParameter(missedDealParameters.brandIds, 'brandIds', params);
        params = ParameterBuilder.buildArrayParameter(missedDealParameters.distributorIds, 'distributorIds', params);

        return this.http.get<MissedDealSearchApiResponse>(url, { params });
    }
}
