import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AnalyticsService } from '@buyiq-core/analytics/analytics.service';
import { AppStateService } from '@buyiq-core/app-state/app-state.service';
import { AuthService } from '@buyiq-core/auth/auth.service';
import { AnalyticsAction, AnalyticsCategory, AnalyticsEvent } from '@buyiq-core/models/analytics';
import { UserCredentials } from '@buyiq-core/models/login';
import { environment } from '@buyiq-environments/environment';
import { ProgressBarService } from '@cia-front-end-apps/shared/progress-bar';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FeatureFlagService } from '@cia-front-end-apps/shared/feature-flag/feature-flag.service';

@Component({
    selector: 'buyiq-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginComponent implements OnInit, OnDestroy {
    serviceOutageMessage = '';
    isLoggingIn: boolean;
    formGroup: FormGroup;
    buildNumber: string;

    private urlBeforeLogin: string;
    private readonly unsubscribe = new Subject<boolean>();

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthService,
        private snackBar: MatSnackBar,
        private progressBarService: ProgressBarService,
        private formBuilder: FormBuilder,
        private analyticsService: AnalyticsService,
        private cdRef: ChangeDetectorRef,
        private appStateService: AppStateService,
        private featureFlagService: FeatureFlagService,
    ) {
    }

    ngOnInit(): void {
        this.buildNumber = environment.buildVersion;
        this.urlBeforeLogin = this.route.snapshot.queryParamMap.get('redir') ?? '';

        this.formGroup = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });

        this.featureFlagService.getFlag('cia-service-outage')
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(flag => {
                this.serviceOutageMessage = flag;
                this.cdRef.detectChanges();
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe.next(true);
        this.unsubscribe.complete();
    }

    onSubmitForm(userCredentials: UserCredentials): void {
        if (!this.formGroup.valid) {
            return;
        }

        this.progressBarService.startLoadingAnimation();
        this.isLoggingIn = true;

        this.authService.authenticate(userCredentials)
            .pipe(
                takeUntil(this.unsubscribe)
            )
            .subscribe({
                next: () => this.handleLoginSuccess(),
                error: (err: Error) => this.handleLoginError(err)
            });
    }

    private handleLoginSuccess(): void {
        this.trackLoginEvent();
        this.progressBarService.finishLoadingAnimation();

        this.appStateService.resetPreloadState();

        this.router.navigate([this.urlBeforeLogin]);
    }

    private handleLoginError(err: any): void {
        this.progressBarService.finishLoadingAnimation();
        this.isLoggingIn = false;
        this.cdRef.detectChanges();

        let message: string;
        if (!environment.useProductionMode && err.message) {
            message = err.message;
        } else if (err.code === 'InvalidParameterException') {
            message = 'An invalid username was entered';
        } else if (err.code === 'UserNotFoundException') {
            message = 'Specified user does not exist';
        } else if (err.code === 'NetworkError') {
            message = 'A networking error has occurred';
        } else {
            message = 'Unable to login';
        }
        const action = 'DISMISS';

        this.snackBar.open(message, action, {
            duration: 3500
        });
    }

    private trackLoginEvent(): void {
        const event = new AnalyticsEvent({
            category: AnalyticsCategory.Authentication,
            action: AnalyticsAction.Login,
            trackedValue: 'success'
        });

        this.analyticsService.trackEvent(event);
    }
}
