import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from '@buyiq-core/models/app-settings';
import { DataResource } from '@buyiq-custom-types';
import { ParameterBuilder, SearchState } from '@cia-front-end-apps/shared/api-interaction';
import { Observable } from 'rxjs';
import { Product, ProductParameters, ProductSearchApiResponse, ProductSearchType } from '../models/product';

@Injectable({
    providedIn: 'root'
})
export class ProductResource implements DataResource<Product> {
    private baseUrl: string;

    constructor(
        private http: HttpClient,
        private appSettings: AppSettings,
    ) {
        this.baseUrl = this.appSettings.apis.search;
    }

    getAll(
        searchState: SearchState,
        productParameters: ProductParameters,
        chainStoreId: number,
        batchSearch = false
    ): Observable<ProductSearchApiResponse> {
        const url = this.buildUrl();
        let params = new HttpParams({
            fromObject: {
                chainStoreId: (chainStoreId || '').toString(),
                search: searchState.search || '',
                sortBy: searchState.sortBy || 'productname',
                pageIndex: (searchState.pageIndex || 0).toString(),
                sortDirection: searchState.sortDirection || 'asc',
                pageSize: (searchState.pageSize || 25).toString(),
                isExactPhrase: (productParameters.isExactPhrase || false).toString(),
                searchType: (productParameters.searchType || ProductSearchType.Generic).toString(),
                includeBrandFacet: 'true',
                includeSupplierFacet: 'true',
                useRankedDistributors: 'true',
                batchSearch: batchSearch.toString()
            }
        });
        params = ParameterBuilder.buildArrayParameter(productParameters.brands, 'brandIds', params);
        params = ParameterBuilder.buildArrayParameter(productParameters.vendors, 'vendorIds', params);
        params = ParameterBuilder.buildArrayParameter(productParameters.distributors, 'distributorIds', params);

        return this.http.get<ProductSearchApiResponse>(url, { params: params });
    }

    private buildUrl(upc?: string): string {
        let url = `${this.baseUrl}/products`;

        if (upc) {
            url = `${url}/${upc}`;
        }

        return url;
    }
}
