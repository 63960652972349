import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { NotificationRef, notificationTypeToCssClassName } from '@buyiq-shared/models/notification';

@Component({
    selector: 'buyiq-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationComponent implements OnInit {
    @HostBinding('class.mat-elevation-z1') elevationCssClass = true;
    @HostBinding('class') colorCssClass = 'basic';

    @Input() notification: NotificationRef;
    @Output() dismissNotification = new EventEmitter<NotificationRef>();

    notificationText: string;
    notificationIcon: string;

    ngOnInit(): void {
        this.colorCssClass = notificationTypeToCssClassName.get(this.notification?.data?.type) ?? 'basic';
        this.notificationText = this.notification?.data?.text || 'Default Notification';
        this.notificationIcon = this.notification?.data?.icon ?? null;
    }

    onDismissNotification(): void {
        this.notification.dismiss();
        this.dismissNotification.emit(this.notification);
    }

    onTriggerAction(): void {
        this.notification.triggerAction();
        this.dismissNotification.emit(this.notification);
    }
}
