<div class="search-row">
    <input class="search-input"
           #searchInput
           autocapitalize="none"
           (keyup.enter)="onTriggerSearch()"
           [attr.aria-label]="placeholder"
           [buyiqAllowOnlyDigits]="!allowLetterInput"
           [formControl]="formControl"
           [maxlength]="maxLength"
           [placeholder]="placeholder"
           [readonly]="isReadonly"
           [type]="inputType"
           autocomplete="off"
           id="search-input"
           name="search-input"
           role="search"/>

    <mat-divider class="divider"
                 [vertical]="true">
    </mat-divider>

    <button mat-icon-button
            class="search-button"
            aria-label="Search"
            (click)="onTriggerSearch()">
        <mat-icon color="accent">
            search
        </mat-icon>
    </button>

    <button *ngIf="allowCameraScanning"
            mat-icon-button
            class="search-button"
            aria-label="Camera"
            (click)="onCameraOn()">
        <mat-icon color="accent">camera_enhance</mat-icon>
    </button>
</div>

<buyiq-product-scan *ngIf="cameraOn"
                    (cameraOff)="onCameraOff()"
                    (triggerSearch)="onTriggerCameraSearch($event)">
</buyiq-product-scan>

