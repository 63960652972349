import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, RouterStateSnapshot, UrlSegment } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard  {

    constructor(private authService: AuthService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const url: string = state.url;

        return this.isAuthenticated(url);
    }

    canLoad(route: Route, segments: Array<UrlSegment>): Observable<boolean> {
        const url = segments.map(segment => segment.path).join('/');

        return this.isAuthenticated(url);
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const url: string = state.url;

        return this.isAuthenticated(url);
    }

    isAuthenticated(url: string): Observable<boolean> {
        return this.authService.isAuthenticated()
            .pipe(
                map(isAuthenticated => {
                    if (!isAuthenticated) {
                        this.authService.redirectToLogin(url);
                    }

                    return isAuthenticated;
                })
            );
    }
}
