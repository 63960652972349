import { inject, Injectable } from '@angular/core';
import { Observable, of, forkJoin } from 'rxjs';
import { Special, SpecialsParameters, SpecialsService } from '@cia-front-end-apps/shared/specials';
import { catchError, map, take, tap } from 'rxjs/operators';
import { SpecialsResource } from '@buyiq-core/resources/specials.resource';
import { SpecialStorageService } from '@buyiq-core/storage/specials-storage.service';
import { injectQuery } from '@ngneat/query';
import { BatchItem } from '@buyiq-app/batch/models/batch-item';

@Injectable({
    providedIn: 'root'
})
export class BatchSpecialsService {
    private query = injectQuery();
    private specialsResource = inject(SpecialsResource);
    private specialsService = inject(SpecialsService);
    private specialStorageService = inject(SpecialStorageService);

    private readonly GROUP_SIZE = 25;

    getBatchSpecialsQuery(specialParameters: SpecialsParameters, batch: Array<BatchItem>) {
        return this.query({
            queryKey: ['batch-specials', specialParameters],
            queryFn: () => this.getBatchSpecials(specialParameters, batch).pipe(
                map((specials) => specials.filter(s => !this.specialsService.isExpired(s)))
            ),
            networkMode: 'always',
            // 1hr cache time
            staleTime: 3600000
        });
    }

    getBatchSpecials(specialsParameters: SpecialsParameters, batch: Array<BatchItem>): Observable<Array<Special>> {
        return this.specialsResource.getAll(specialsParameters, true).pipe(
            tap(this.storeSpecials),
            catchError(() => {
                return this.fallbackToGroupFetch(specialsParameters, batch);
            })
        );
    }

    private fallbackToGroupFetch(specialsParameters: SpecialsParameters, batch: Array<BatchItem>): Observable<Array<Special>> {
        const upcGroups = this.chunkArray(batch.map(b => b.upc), this.GROUP_SIZE);
        return forkJoin(
            upcGroups.map(upcGroup => this.fetchSpecialsForGroup(specialsParameters, upcGroup))
        ).pipe(
            map(groupResults => groupResults.flat()),
            catchError(() => {
                return this.specialStorageService.getAll().pipe(
                    map((specialArrays) => specialArrays.flat())
                );
            })
        );
    }

    private fetchSpecialsForGroup(baseParameters: SpecialsParameters, upcs: string[]): Observable<Array<Special>> {
        const groupParameters = new SpecialsParameters({
            ...baseParameters,
            upcs: upcs
        });

        return this.specialsResource.getAll(groupParameters, false).pipe(
            tap(this.storeSpecials),
            catchError(() => of([]))
        );
    }

    getSpecialsQuery(specialParameters: SpecialsParameters) {
        return this.query({
            queryKey: ['batch-item-specials', specialParameters],
            queryFn: () => this.getSpecials(specialParameters),
            networkMode: 'always',
            staleTime: 60000
        });
    }

    getSpecials(specialsParameters: SpecialsParameters): Observable<Array<Special>> {
        return this.specialsResource.getAll(specialsParameters, false).pipe(
            map((specials) => {
                return this.specialsService
                    .filterBySubscriptions(specials, [], specialsParameters.vendorId)
                    .filter(special => !this.specialsService.isExpired(special))
                    .filter(special => special.vendorId === specialsParameters.vendorId);
            }),
            tap(specials => {
                if (specialsParameters.upcs && specialsParameters.upcs.length > 0) {
                    this.specialStorageService.setItem(
                        specialsParameters.upcs[0],
                        specials
                    ).pipe(take(1)).subscribe();
                }
            }),
            catchError(() => {
                if (specialsParameters.upcs && specialsParameters.upcs.length > 0) {
                    return this.specialStorageService.getItem(specialsParameters.upcs[0]);
                }
                return of([]);
            })
        );
    }

    private storeSpecials = (specials: Array<Special> = []): void => {
        const groupedByUpc = specials.reduce((hash, obj) => {
            if (obj.upc === undefined) {
                return hash;
            }
            return Object.assign(hash, {
                [obj.upc]: (hash[obj.upc] || []).concat(obj),
            });
        }, {});

        for (const upc in groupedByUpc) {
            if (groupedByUpc.hasOwnProperty(upc)) {
                const upcSpecials = groupedByUpc[upc];
                this.specialStorageService
                    .setItem(upc, upcSpecials)
                    .pipe(take(1))
                    .subscribe();
            }
        }
    };

    private chunkArray<T>(array: T[], size: number): T[][] {
        const chunks = [];
        for (let i = 0; i < array.length; i += size) {
            chunks.push(array.slice(i, i + size));
        }
        return chunks;
    }
}
