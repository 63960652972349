import { SafeHtml } from '@angular/platform-browser';

export enum MessageDestination {
    All = 0,
    BuyIq = 1,
    OrderCentral,
    SalesGenius,
    GcEdi
}

export enum MessageStatus {
    Active,
    Inactive,
    Scheduled
}

export class Message {
    id: number;
    destinations: Array<MessageDestination>;
    title: string;
    body: string;
    dateCreated: Date;
    startDate: Date;
    endDate: Date;
    elevated: boolean;
    sanitizedBody?: SafeHtml;

    // UI only
    startDateDisplay: string;

    constructor(options?: Partial<Message>) {
        Object.assign(this, options);
    }
}

export class MessageParameters {
    elevated: boolean;
    startDate: Date;
    endDate: Date;
    destination: MessageDestination;

    constructor(options?: Partial<MessageParameters>) {
        Object.assign(this, options);
    }
}
