import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { BatchUploadEvent, UploadErrorType } from '@buyiq-app/batch/models/batch-upload-event';
import { Inventory, inventoryKeys } from '@buyiq-app/inventory/models/inventory';
import { InventoryResource } from '@buyiq-app/inventory/resources/inventory.resource';
import { retryWithDelay } from '@buyiq-core/models/error-handler';
import { ToolbarService } from '@buyiq-core/toolbar/toolbar.service';
import { UserService } from '@buyiq-core/user/user.service';
import { BatchSummary, BatchSummaryType } from '@buyiq-shared/models/batch-summary';
import {
    forkJoin,
    Observable,
    of,
    Subject,
    switchMap,
    debounceTime, concatMap, from, bufferCount
} from 'rxjs';
import { catchError, map, takeUntil, tap } from 'rxjs/operators';
import {
    injectQuery,
    injectQueryClient,
    toPromise
} from '@ngneat/query';
import { InventoryStorageService } from '@buyiq-core/storage/inventory-storage.service';

@Injectable({
    providedIn: 'root',
})
export class InventoryService implements OnDestroy {
    private query = injectQuery();
    private queryClient = injectQueryClient();
    private lastScannedOrder: number;
    private batch: Array<Inventory> = [];
    private chainStoreId: number;

    // Subject to trigger the processing of the latest scans
    private scanTrigger = new Subject<void>();
    // array to keep track of the latest scan for each UPC
    private scanQueue: Array<{ upc: string, quantity: number }> = [];
    // Event emitter to broadcast inventory update events to subscribers.
    public scanUpdate = new EventEmitter<{ inventory: Inventory }>();

    private unsubscribe = new Subject<void>();

    constructor(
        private inventoryStorageService: InventoryStorageService,
        private inventoryResource: InventoryResource,
        private toolbarService: ToolbarService,
        private userService: UserService,
    ) {
        this.scanTrigger.pipe(
            debounceTime(1000),
            tap(() => this.processLatestScans())
        ).subscribe();
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    getInventoryQuery(chainStoreId: number, upc: string) {
        return this.query({
            queryKey: inventoryKeys.inventory(chainStoreId, upc),
            queryFn: () => this.getInventory(chainStoreId, upc),
            networkMode: 'offlineFirst',
            initialData: () => {
                const queryData = this.queryClient.getQueryData<Array<Inventory>>(['inventories', chainStoreId]);
                return queryData?.find(inventory => inventory.upc === upc);
            }
        });
    }

    getInventory(chainStoreId: number, upc: string): Observable<Inventory> {
        return this.inventoryResource.get(chainStoreId, upc).pipe(
            tap((inventory: Inventory) => {
                this.updateItemInStorage(inventory);
            }),
            catchError(() => {
                return this.inventoryStorageService.getItem(upc);
            })
        );
    }

    getInventoriesQuery(chainStoreId: number) {
        this.chainStoreId = chainStoreId;
        return this.query({
            queryKey: inventoryKeys.inventories(chainStoreId),
            queryFn: () => this.getInventories(chainStoreId),
            networkMode: 'offlineFirst'
        });
    }

    prefetchInventories(chainStoreId: number): void {
        this.chainStoreId = chainStoreId;
        this.queryClient.prefetchQuery({
            queryKey: inventoryKeys.inventories(chainStoreId),
            queryFn: async () => {
                return toPromise({ source: this.getInventories(chainStoreId) });
            }
        });
    }

    getInventories(chainStoreId: number): Observable<Array<Inventory>> {
        this.chainStoreId = chainStoreId;
        return this.inventoryResource.getAll(chainStoreId).pipe(
            tap((inventories: Array<Inventory>) => {
                this.batch = inventories ?? [];
                this.hydrateStorage(this.batch);
            }),
            // if we run into an error fetching the batch from the server we want to load in the cached inventory
            catchError(() => {
                return this.inventoryStorageService.getAll().pipe(
                    switchMap((inventories: Array<Inventory>) => forkJoin({
                        user: this.userService.getCurrentUser(),
                        inventories: of(inventories)
                    })),
                    tap(({ user, inventories }) => {
                        const allowZeroQuantity = user.settings.allowZeroQtyInv;
                        this.batch = (inventories ?? []).filter(inventory => {
                            return inventory.quantity > 0 || allowZeroQuantity;
                        });
                        this.updateBatchSummary(this.batch);
                    }),
                    map(({ inventories }) => inventories)
                );
            })
        );
    }

    submitInventory(chainStoreId: number): Observable<BatchUploadEvent<Inventory>> {
        const delayInterval = 1000;
        const retries = 4;

        return this.inventoryResource.update(chainStoreId).pipe(
            retryWithDelay(delayInterval, retries),
            switchMap(() => {
                this.batch = [];
                this.queryClient.setQueryData(inventoryKeys.inventories(chainStoreId), this.batch);
                this.queryClient.invalidateQueries({ queryKey: ['inventories', chainStoreId] });
                return this.inventoryStorageService.clearStorage();
            }),
            map(() => new BatchUploadEvent({
                items: this.batch
            })),
            catchError((error) => {
                const offlineErrorStatus = 0;
                const errorType = error.status === offlineErrorStatus ? UploadErrorType.Offline : UploadErrorType.Other;
                const batchUploadEvent = new BatchUploadEvent({
                    items: this.batch,
                    error: error.title,
                    lastError: Date.now(),
                    errorType
                });
                return of(batchUploadEvent);
            })
        );
    }

    updateBatchSummary(batch: Array<Inventory>): void {
        const batchSummary = new BatchSummary({
            itemCount: batch?.length ?? 0,
            type: BatchSummaryType.Inventory
        });
        this.toolbarService.updateBatchSummary(batchSummary);
    }

    /**
     * Processes a scanned UPC and updates the latest scan.
     *
     * @param upc.
     */
    scanInventory(upc: string): void {
        // Update the number of batched scans for the UPC
        const existingScanIndex = this.scanQueue.findIndex(scan => scan.upc === upc);
        // make immutable updates
        if (existingScanIndex > -1) {
            this.scanQueue = [
                ...this.scanQueue.slice(0, existingScanIndex),
                ...this.scanQueue.slice(existingScanIndex + 1),
                {
                    ...this.scanQueue[existingScanIndex],
                    quantity: this.scanQueue[existingScanIndex].quantity + 1
                }
            ];
        } else {
            this.scanQueue = [
                ...this.scanQueue,
                { upc, quantity: 1 }
            ];
        }

        // Trigger the debounce
        this.scanTrigger.next();
    }

    /**
     * Processes the latest scans after a period of inactivity.
     */
    private processLatestScans(): void {
        // make a copy of the current queue and clear the queue
        const currentQueue = this.scanQueue.slice();
        this.scanQueue = [];
        const batchSize = 20;

        // Convert the queue into an observable, then batch it to avoid hammering the API with parallel requests
        from(currentQueue)
            .pipe(
                // bufferCount will release the scans in batches up to 20
                // meaning if we had 25 scans, we would have 2 batches, one with 20 and one with 5
                bufferCount(batchSize),
                // concatMap is used to process each batch sequentially. The buffer won't release the next batch until the
                // previous batch has been processed.
                concatMap(batch => {
                    // Process each batch sequentially
                    return forkJoin(batch.map(({ upc, quantity }) => {
                        const inventory = this.batch.find(item => item.upc === upc) ?? new Inventory({
                            upc,
                            quantity: 0
                        });
                        const updatedInventory = new Inventory({
                            ...inventory,
                            quantity: inventory.quantity + quantity,
                        });
                        return this.upsertInventory(this.chainStoreId, updatedInventory);
                    }));
                }),
                takeUntil(this.unsubscribe)
            )
            .subscribe(batchResults => {
                // Emit the scanUpdate event for each upserted inventory item
                batchResults.forEach(upsertedInventory => {
                    this.scanUpdate.emit({ inventory: upsertedInventory });
                });
            });
    }

    updateInventory(chainStoreId: number, inventory: Inventory): Observable<Inventory> {
        // if a request comes in that is in the scan queue, we want to remove what's in the queue
        const existingScanIndex = this.scanQueue.findIndex(scan => scan.upc === inventory.upc);
        if (existingScanIndex > -1) {
            this.scanQueue = [
                ...this.scanQueue.slice(0, existingScanIndex),
                ...this.scanQueue.slice(existingScanIndex + 1)
            ];
        }

        return this.upsertInventory(chainStoreId, inventory);
    }

    upsertInventory(chainStoreId: number, inventory: Inventory): Observable<Inventory> {
        if (!!this.lastScannedOrder) {
            inventory.scannedOrder = this.lastScannedOrder + 1;
        } else {
            if (this.batch.length > 0) {
                const lastScannedItem = this.batch.reduce((previous, current) => {
                    return previous.scannedOrder > current.scannedOrder ? previous : current;
                });
                inventory.scannedOrder = lastScannedItem.scannedOrder + 1;
            } else {
                inventory.scannedOrder = 1;
            }
        }
        this.lastScannedOrder = inventory.scannedOrder;

        return this.userService.getCurrentUser()
            .pipe(
                switchMap(user => {
                    const allowZeroQuantity = user.settings.allowZeroQtyInv;
                    return this.upsert(chainStoreId, inventory, allowZeroQuantity);
                })
            );
    }

    clear(): void {
        this.batch = [];
        this.inventoryStorageService.clearStorage()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe();
        this.queryClient.invalidateQueries({ queryKey: [inventoryKeys.inventories(this.chainStoreId)] });
    }

    private upsert(chainStoreId: number, inventory: Inventory, allowZeroQuantity: boolean): Observable<Inventory> {
        let request = this.inventoryResource.create(chainStoreId, inventory);

        if (inventory.quantity < 1 && !allowZeroQuantity) {
            request = this.inventoryResource.remove(chainStoreId, inventory.id)
                .pipe(map(() => inventory));
        } else if (inventory.id) {
            request = this.inventoryResource.replace(chainStoreId, inventory);
        }
        return request
            .pipe(
                map(apiInventory => {
                    return new Inventory({
                        ...inventory,
                        ...apiInventory,
                        hasPendingChanges: false
                    });
                }),
                catchError(() => {
                    const result = new Inventory({
                        ...inventory,
                        hasPendingChanges: true,
                    });

                    return of(result);
                }),
                tap(updatedInventory => {
                    // only remove items from storage if the item was successfully removed on the server
                    const shouldUpdateItemInStorage = updatedInventory.hasPendingChanges
                        || updatedInventory.quantity > 0
                        || allowZeroQuantity;
                    if (shouldUpdateItemInStorage) {
                        this.updateItemInStorage(updatedInventory);
                    } else {
                        this.removeItemFromStorage(updatedInventory.upc);
                    }
                }),
                map(updatedInventory => {
                    // update the batch and query cache with the api data
                    this.batch = this.updateItemInBatch(this.batch, updatedInventory, allowZeroQuantity);
                    this.queryClient.setQueryData(inventoryKeys.inventories(chainStoreId), this.batch);
                    return updatedInventory;
                })
            );
    }

    private updateItemInBatch(batch: Array<Inventory>, inventory: Inventory, allowZeroQuantity: boolean): Array<Inventory> {
        let index = batch.findIndex(item => item.upc === inventory.upc);
        index = index === -1 ? batch.length : index;

        const updatedBatch = inventory.quantity < 1 && !allowZeroQuantity
            ? batch.filter(item => item.upc !== inventory.upc)
            : [
                ...batch.slice(0, index),
                inventory,
                ...batch.slice(index + 1)
            ];

        return updatedBatch;
    }

    private removeItemFromStorage(upc: string): void {
        this.inventoryStorageService.removeItem(upc)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe();
    }

    private updateItemInStorage(inventory: Inventory): void {
        this.inventoryStorageService.setItem(inventory.upc, inventory)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe();
    }

    /**
     * We don't want the initial hydration to be block the rendering of the page, so,
     * we hydrate out of the initial loading pipeline.
     *
     * @param inventories
     */
    private hydrateStorage = (inventories: Array<Inventory>): void => {
        // ensure any leftover items are removed from the cache
        this.inventoryStorageService.clearStorage()
            .pipe(
                switchMap(() => {
                    return this.inventoryStorageService.setItems(inventories);
                }),
                takeUntil(this.unsubscribe)
            )
            .subscribe();
    };
}
