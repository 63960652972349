import { Injectable } from '@angular/core';
import { StockedProduct, StockedProductVendors } from '@buyiq-app/stocked-not-scanned/models/stocked-product';
import { StockedProductVendorsResource } from '@buyiq-app/stocked-not-scanned/resources/stocked-product-vendors.resource';
import { StockedProductsResource } from '@buyiq-app/stocked-not-scanned/resources/stocked-products.resource';
import { Observable } from 'rxjs';
import { injectQuery } from '@ngneat/query';

@Injectable({
    providedIn: 'root'
})
export class StockedNotScannedService {
    private query = injectQuery();

    constructor(
        private stockedProductVendors: StockedProductVendorsResource,
        private stockedProductsResource: StockedProductsResource
    ) {
    }

    getStockedProductVendors(storeId: number): Observable<StockedProductVendors> {
        return this.stockedProductVendors.get(storeId);
    }

    getStockedProductVendorsQuery(storeId: number) {
        return this.query({
            queryKey: ['stocked-product-vendors', storeId],
            queryFn: () => this.getStockedProductVendors(storeId),
        });
    }

    getStockedProducts(storeId: number, vendorId: number): Observable<Array<StockedProduct>> {
        return this.stockedProductsResource.getAll(storeId, vendorId);
    }
}
