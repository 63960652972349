<div class="sticky-wrapper">
    <button *ngIf="!route"
            mat-fab
            color="accent"
            matTooltipPosition="above"
            [matTooltip]="label"
            [attr.aria-label]="label"
            [disabled]="disabled"
            (click)="onTriggerAction()">
        <mat-icon>
            {{icon}}
        </mat-icon>
    </button>

    <a *ngIf="route"
       mat-fab
       color="accent"
       matTooltipPosition="above"
       [matTooltip]="label"
       [routerLink]="route"
       [attr.aria-label]="label"
       [disabled]="disabled"
       (click)="onTriggerAction()">
        <mat-icon>
            {{icon}}
        </mat-icon>
    </a>
</div>
