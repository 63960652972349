import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Product } from '@buyiq-app/product/models/product';
import { AppSettings } from '@buyiq-core/models/app-settings';
import { DataResource } from '@buyiq-custom-types';
import { ParameterBuilder, SearchState } from '@cia-front-end-apps/shared/api-interaction';
import { Observable } from 'rxjs';
import { PromotionProductParameters, PromotionProductsCollectionResponse } from '../models/promotion-products';

@Injectable({
    providedIn: 'root'
})
export class PromotionProductsResource implements DataResource<Product> {
    private baseUrl: string;

    constructor(private http: HttpClient, private appSettings: AppSettings) {
        this.baseUrl = `${this.appSettings.apis.search}`;
    }

    getAll(
        searchState: SearchState,
        promotionProductParameters: PromotionProductParameters
    ): Observable<PromotionProductsCollectionResponse> {
        const url = this.buildUrl();
        let params = new HttpParams({
            fromObject: {
                pageIndex: (searchState.pageIndex || 0).toString(),
                pageSize: (searchState.pageSize || 25).toString(),
                sortBy: searchState.sortBy || '',
                sortDirection: searchState.sortDirection || 'asc',
                search: searchState.search || '',
                startDate: (promotionProductParameters.startDate?.toISOString() || ''),
                endDate: (promotionProductParameters.endDate?.toISOString() || ''),
                promoId: (promotionProductParameters.promoId || '').toString(),
                chainStoreId: (promotionProductParameters.chainStoreId || 0).toString(),
                useRankedDistributors: (promotionProductParameters.useRankedDistributors).toString()
            }
        });
        params = ParameterBuilder.buildArrayParameter(promotionProductParameters.promotionTypeIds, 'promotionTypeIds', params);
        params = ParameterBuilder.buildArrayParameter(promotionProductParameters.vendorIds, 'vendorIds', params);
        params = ParameterBuilder.buildArrayParameter(promotionProductParameters.brandIds, 'brandIds', params);
        params = ParameterBuilder.buildArrayParameter(promotionProductParameters.distributorIds, 'distributorIds', params);

        return this.http.get<PromotionProductsCollectionResponse>(url, { params });
    }

    private buildUrl(promotionProductsId?: number): string {
        let url = `${this.baseUrl}/promotion-products`;

        if (promotionProductsId) {
            url = `${url}/${promotionProductsId}`;
        }

        return url;
    }
}
