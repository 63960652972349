export class VendorRanking {
    id: number;
    chainStoreId: number;
    userId: number;
    legacyVendorId: number;
    rank: number;
    displayName: string;

    constructor(options?: Partial<VendorRanking>) {
        Object.assign(this, options);
    }
}

export class VendorRankingList {
    chainStoreId: number;
    userId: number;
    upc: string;
    vendorRankings?: Array<VendorRanking>;

    constructor(options?: Partial<VendorRankingList>) {
        Object.assign(this, options);
    }
}

export class VendorRankingRequest {
    chainStoreId: number;
    userId: number;
    chainId: number;
    vendorRankings?: Array<VendorRanking>;
    isApl: boolean;
    searchText: string;
    isSku: boolean;

    constructor(options?: Partial<VendorRankingRequest>) {
        Object.assign(this, options);
    }
}
