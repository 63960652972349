import { Injectable } from '@angular/core';
import { ShelfTag } from '@buyiq-app/shelf-tags/models/shelf-tag';
import { LocalForageService } from '@buyiq-core/storage/local-forage.service';
import { ItemKey, OfflineStorage, StoreName } from '@buyiq-core/storage/storage';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ShelfTagStorageService implements OfflineStorage<ShelfTag> {
    constructor(
        private localForageService: LocalForageService
    ) {
    }

    clearStorage(): Observable<void> {
        return this.localForageService.clearStorage(StoreName.ShelfTags);
    }

    getItem(key: string): Observable<ShelfTag> {
        return this.localForageService.getItem(key, StoreName.ShelfTags);
    }

    removeItem(key: string): Observable<void> {
        return this.localForageService.removeItem(key, StoreName.ShelfTags);
    }

    setItem(key: string, value: any): Observable<ShelfTag> {
        return this.localForageService.setItem(key, value, StoreName.ShelfTags);
    }

    getAll(): Observable<Array<ShelfTag>> {
        return this.localForageService.getAll(StoreName.ShelfTags);
    }

    setItems(items: Array<ShelfTag>): Observable<Array<ShelfTag>> {
        return this.localForageService.setItems(items, ItemKey.StorageKey, StoreName.ShelfTags);
    }
}
