import { Injectable } from '@angular/core';
import { ItemKey, OfflineStores, StoreName } from '@buyiq-core/storage/storage';
import * as localForage from 'localforage';
import { forkJoin, from, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class LocalForageService {
    private stores: OfflineStores;

    constructor() {
        localForage.config({
            name: 'App Storage',
            driver: [localForage.INDEXEDDB, localForage.LOCALSTORAGE]
        });

        this.stores = new OfflineStores({
            inventory: localForage.createInstance({
                storeName: StoreName.Inventory
            }),
            batch: localForage.createInstance({
                storeName: StoreName.Batch
            }),
            shelfTags: localForage.createInstance({
                storeName: StoreName.ShelfTags
            }),
            user: localForage.createInstance({
                storeName: StoreName.User
            }),
            vendorInfo: localForage.createInstance({
                storeName: StoreName.VendorInfo
            }),
            orderHistory: localForage.createInstance({
                storeName: StoreName.OrderHistory
            }),
            specials: localForage.createInstance({
                storeName: StoreName.Specials
            }),
            vendorRanking: localForage.createInstance({
                storeName: StoreName.VendorRanking
            })
        });
    }

    getItem<T>(key: string, storeName: StoreName): Observable<T> {
        const store = this.getStore(storeName);
        return from(store.getItem<T>(key));
    }

    setItem<T>(key: string, value: T, storeName: StoreName): Observable<T> {
        const store = this.getStore(storeName);
        return from(store.setItem<T>(key, value));
    }

    removeItem(key: string, storeName: StoreName): Observable<void> {
        const store = this.getStore(storeName);
        return from(store.removeItem(key));
    }

    clearStorage(storeName: StoreName): Observable<void> {
        const store = this.getStore(storeName);
        return from(store.clear());
    }

    getAll<T>(storeName: StoreName): Observable<Array<T>> {
        const store = this.getStore(storeName);
        return from(store.keys() ?? [])
            .pipe(
                switchMap((keys: Array<string>) => {
                    const items = keys.map((key: string) => this.getItem<T>(key, storeName));
                    return items.length > 0 ? forkJoin([...items]) : of([]);
                })
            );
    }

    setItems<T>(items: Array<T>, key: ItemKey, storeName: StoreName): Observable<Array<T>> {
        const itemTransactions = items.map(item => {
            return this.setItem<T>(item[key], item, storeName);
        });
        return forkJoin([...itemTransactions]);
    }

    private getStore(storeName: StoreName): LocalForage {
        return this.stores[storeName];
    }
}
