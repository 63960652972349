<div class="login-content">
    <div *ngIf="serviceOutageMessage"
         class="emergency-message">
        {{serviceOutageMessage}}
    </div>

    <img src="assets/stacked-blu.svg"
         class="login-logo"/>

    <form class="login-form"
          [formGroup]="formGroup"
          (ngSubmit)="onSubmitForm(formGroup.value)">
        <div class="form-section">
            <div class="row">
                <mat-form-field class="field">
                    <input matInput
                           type="text"
                           placeholder="Username"
                           formControlName="username"
                           autocomplete="on"
                           id="username"
                           name="username">
                    <mat-error *ngIf="formGroup.get('username').hasError('required')">
                        Field is required.
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="row">
                <mat-form-field class="field">
                    <input matInput
                           type="password"
                           placeholder="Password"
                           formControlName="password"
                           autocomplete="on"
                           id="password"
                           name="password">
                    <mat-error *ngIf="formGroup.get('password').hasError('required')">
                        Field is required.
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="row button-container">
                <button mat-raised-button
                        type="submit"
                        value="Login"
                        class="login-button"
                        color="accent"
                        [disabled]="isLoggingIn">
                    Log In
                </button>
            </div>
        </div>
    </form>
    <p class="build-version">Version: {{buildNumber}}</p>
</div>
<div class="privacy-policy">
    By signing into this site, you agree and acknowledge the <a target="_blank" href="https://www.spscommerce.com/about/privacy-policy/"> Privacy Policy</a>
</div>
