import { Injectable } from '@angular/core';
import {
    NewProductsCache,
    Promotion,
    PromotionFilterFn,
    PromotionParameters,
} from '@buyiq-app/promotions/models/promotion';
import {
    PromotionProductParameters,
    PromotionProductsCollectionResponse,
} from '@buyiq-app/promotions/models/promotion-products';
import { PromotionResponse } from '@buyiq-app/promotions/models/promotion-response';
import { PromotionProductsResource } from '@buyiq-app/promotions/resources/promotion-products.resource';
import { PromotionResponseResource } from '@buyiq-app/promotions/resources/promotion-response.resource';
import { PromotionResource } from '@buyiq-app/promotions/resources/promotion.resource';
import { ChainStoreService } from '@buyiq-core/chain-store/chain-store.service';
import { emitValueOnError } from '@cia-front-end-apps/shared/api-interaction';
import { FeatureFlag } from '@buyiq-core/models/feature-flags';
import {
    VendorRanking,
    VendorRankingRequest,
} from '@buyiq-core/models/vendor-ranking';
import { UserService } from '@buyiq-core/user/user.service';
import { SearchState } from '@cia-front-end-apps/shared/api-interaction';
import { forkJoin, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class PromotionService {
    newProductsCache: NewProductsCache = null;

    constructor(
        private userService: UserService,
        private promotionResource: PromotionResource,
        private chainStoreService: ChainStoreService,
        private promotionResponseResource: PromotionResponseResource,
        private promotionProductsResource: PromotionProductsResource
    ) {}

    getPromotions(
        searchState: SearchState,
        promotionParameters: PromotionParameters
    ) {
        return this.promotionResource.getAll(searchState, promotionParameters);
    }

    getPromotionProducts(
        searchState: SearchState,
        promotionProductParameters: PromotionProductParameters
    ): Observable<PromotionProductsCollectionResponse> {
        return this.userService.getCurrentUser().pipe(
            switchMap((user) => {
                const isApl = user.features.includes(FeatureFlag.SupportsApl);
                const requestConfig = new VendorRankingRequest({
                    chainId: user.chain.id,
                    chainStoreId: user.currentStore.id,
                    userId: user.id,
                    searchText: '',
                    isApl: isApl,
                    isSku: false,
                });
                const vendorRankingRequest = emitValueOnError<
                    Array<VendorRanking>
                >(
                    this.chainStoreService.getVendorRankings(requestConfig),
                    new Array<VendorRanking>()
                );
                return forkJoin({
                    user: of(user),
                    rankings: vendorRankingRequest,
                });
            }),
            switchMap(({ user, rankings }) => {
                const chainStoreId = user.currentStore.id;
                const distributorIds = rankings.map((d) => d.id);
                const useRankedDistributors = true;
                const parameters = new PromotionProductParameters({
                    ...promotionProductParameters,
                    distributorIds,
                    chainStoreId,
                    useRankedDistributors,
                });
                return this.promotionProductsResource.getAll(
                    searchState,
                    parameters
                );
            })
        );
    }
    createPromotionResponse(
        promotionResponse: PromotionResponse
    ): Observable<PromotionResponse> {
        return this.promotionResponseResource.create(promotionResponse);
    }

    getPromotionResponses(
        promotionTypeIds: Array<number> = [],
        promotionId?: number
    ): Observable<Array<PromotionResponse>> {
        return this.userService.getCurrentUser().pipe(
            switchMap((user) => {
                const chainStoreIds = user.stores.map((store) => store.id);
                return this.promotionResponseResource.getAll(
                    chainStoreIds,
                    promotionTypeIds,
                    promotionId
                );
            }),
            map((response) => response.items ?? [])
        );
    }

    filterByIndustryVerticals(industryVerticals: Array<number>): PromotionFilterFn {
        return (promotion: Promotion): boolean => {
            const promotionIndustryVerticals = promotion?.industryVerticals ?? [];
            return promotionIndustryVerticals.some(industryVertical => {
                return industryVerticals.includes(industryVertical);
            });
        };
    }
}
