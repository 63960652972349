import { ProductStatus } from '@buyiq-app/batch/models/product-status-filters';
import { AssortmentAttribute, Product, VendorAttribute } from '@buyiq-app/product/models/product';
import { Vendor } from '@buyiq-core/models/vendor';
import { HasPendingChanges } from '@buyiq-core/storage/storage';
import { Deal } from '@cia-front-end-apps/shared/specials';

export class BatchItem implements HasPendingChanges {
    accountNumber: string;
    brand: string;
    casePackSize: number;
    dateCreated: Date;
    department: string;
    description: string;
    id: number;
    itemSize: string;
    orderSource: number;
    orderUnits: string;
    pagePartition: number;
    quantity: number;
    scannedOrder: number;
    sku: string;
    retailerSKU: string;
    srp: number;
    upc: string;
    lnUpc: string;
    vendor: Vendor;
    vendorId: number;
    wsp: number;
    gtin: string;
    ean: string;
    isbn: string;
    productUpc: string;
    assortmentAttributes: AssortmentAttribute;
    salePrice: number;


    // BUYiQ added properties
    hasValidQuantity = true;

    /**
     * @description If the item has pending changes, it has not been synced to the API and only exists in the local app
     */
    hasPendingChanges = false;
    product: Product;
    selectedVendorAttribute: VendorAttribute;
    statuses: Array<ProductStatus> = [];
    /**
     * @description the itemCost is either the wsp or the sale price if the item has a special
     */
    itemCost: number;
    deal: Deal

    constructor(options?: Partial<BatchItem>) {
        Object.assign(this, options);
    }
}

export class BatchItemQuantityChangeEvent {
    batchItem: BatchItem;
    newQuantity: number;

    constructor(options?: Partial<BatchItemQuantityChangeEvent>) {
        Object.assign(this, options);
    }
}

export class BatchItemVendorChangeEvent {
    batchItem: BatchItem;
    newVendorAttribute: VendorAttribute;

    constructor(options?: Partial<BatchItemVendorChangeEvent>) {
        Object.assign(this, options);
    }
}

export class BatchItemPagePartitionChangeEvent {
    batchItem: BatchItem;
    newPagePartition: number;
    updateBatch: boolean;

    constructor(options?: Partial<BatchItemPagePartitionChangeEvent>) {
        Object.assign(this, options);
    }
}

export class BatchItemUpdate {
    searchText: string;
    batchItemSnapshot: BatchItem;
    updatedBatchItem: BatchItem;
    product: Product;
    errors = new Array<BatchItemUpdateError>();

    constructor(options?: Partial<BatchItemUpdate>) {
        Object.assign(this, options);
    }
}

export enum BatchItemUpdateError {
    NotAuthenticated = 'NotAuthenticated',
    ProductLookupOffline = 'ProductLookupOffline',
    ProductNotFound = 'ProductNotFound',
    UpsertOffline = 'UpsertOffline',
    InternalServerError = 'InternalServerError',
    UndefinedError = 'UndefinedError'
}

export class BatchRouteState {
    upc: string;

    constructor(options?: Partial<BatchRouteState>) {
        Object.assign(this, options);
    }
}
