import { Injectable, OnDestroy } from '@angular/core';
import { BatchItem, BatchItemUpdate } from '@buyiq-app/batch/models/batch-item';
import { BatchService } from '@buyiq-app/batch/services/batch.service';
import { Inventory, inventoryKeys } from '@buyiq-app/inventory/models/inventory';
import { OnlineManagerService } from '@buyiq-app/product/services/online-manager.service';
import { ErrorType, ShelfTag } from '@buyiq-app/shelf-tags/models/shelf-tag';
import { ShelfTagsService } from '@buyiq-app/shelf-tags/shelf-tags.service';
import { ProductScanConfiguration } from '@buyiq-core/models/scan';
import { ScanPipelineService } from '@buyiq-core/scan/scan-pipeline.service';
import { ScanService } from '@buyiq-core/scan/scan.service';
import { HasPendingChanges } from '@buyiq-core/storage/storage';
import { UserService } from '@buyiq-core/user/user.service';
import { forkJoin, Observable, of, Subject, tap } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { DatadogRumService } from '@buyiq-core/analytics/datadog-rum.service';
import { InventoryStorageService } from '@buyiq-core/storage/inventory-storage.service';
import { InventoryService } from '@buyiq-app/inventory/services/inventory.service';
import { injectQueryClient } from '@ngneat/query';

@Injectable({
    providedIn: 'root'
})
export class OfflineSyncService implements OnDestroy {
    syncedBatchChanges: Observable<Array<BatchItemUpdate>>;
    syncedInventoryChanges: Observable<Array<Inventory>>;
    syncedShelfTagChanges: Observable<Array<ShelfTag>>;

    private queryClient = injectQueryClient();

    private readonly shelfTagSyncSubject = new Subject<Array<ShelfTag>>();
    private readonly batchSyncSubject = new Subject<Array<BatchItemUpdate>>();
    private readonly inventorySyncSubject = new Subject<Array<Inventory>>();
    private readonly unsubscribe = new Subject<void>();

    constructor(
        private inventoryService: InventoryService,
        private inventoryStorageService: InventoryStorageService,
        private onlineManagerService: OnlineManagerService,
        private batchService: BatchService,
        private scanService: ScanService,
        private userService: UserService,
        private scanPipelineService: ScanPipelineService,
        private shelfTagsService: ShelfTagsService,
        private dataDogRumService: DatadogRumService
    ) {
        this.syncedBatchChanges = this.batchSyncSubject.asObservable();
        this.syncedShelfTagChanges = this.shelfTagSyncSubject.asObservable();
        this.syncedInventoryChanges = this.inventorySyncSubject.asObservable();
    }

    /**
     * Begins watching the online state of the application and syncs pending changes
     * when we go from offline -> online
     */
    initialize(chainStoreId: number): void {
        this.onlineManagerService.onlineChanges
            .pipe(
                filter(isOnline => isOnline),
                switchMap(() => {
                    return forkJoin({
                        syncedBatchItems: this.syncPendingBatchChanges(),
                        syncedShelfTags: this.syncPendingShelfTagChanges(),
                        syncedInventoryItems: this.syncPendingInventoryChanges()
                    });
                })
            )
            .subscribe(({ syncedBatchItems, syncedShelfTags, syncedInventoryItems }) => {
                if (syncedBatchItems.length > 0) {
                    this.batchSyncSubject.next(syncedBatchItems);
                    this.batchService.updateBatchSummary();
                }

                if (syncedInventoryItems.length > 0) {
                    this.queryClient.refetchQueries({ queryKey: inventoryKeys.inventories(chainStoreId) })
                    this.inventorySyncSubject.next(syncedInventoryItems);
                }

                if (syncedShelfTags.length > 0) {
                    this.shelfTagSyncSubject.next(syncedShelfTags);
                }
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.batchSyncSubject.complete();
    }

    syncPendingInventoryChanges(): Observable<Array<Inventory>> {
        return forkJoin({
            inventories: this.inventoryStorageService.getAll(),
            user: this.userService.getCurrentUser()
        })
            .pipe(
                switchMap(({ inventories, user }) => {
                    const pendingInventories = this.filterPendingChanges<Inventory>(inventories).sort((a, b) => {
                        // sort by scannedOrder with the highest number last
                        return a.scannedOrder - b.scannedOrder;
                    });

                    // if we have any pending inventories we want to wait to refetch until after the changes have been
                    // synced
                    if (pendingInventories?.length > 0  ) {
                        this.queryClient.cancelQueries({
                            queryKey: inventoryKeys.inventories(user.currentStore.id)
                        })
                    }

                    const upsertRequests = pendingInventories.map(inventory => {
                        return this.inventoryService.upsertInventory(user.currentStore.id, inventory);
                    });
                    return upsertRequests.length > 0 ? forkJoin([...upsertRequests]) : of([]);
                })
            );
    }

    syncPendingBatchChanges(): Observable<Array<BatchItemUpdate>> {
        return this.getBatch()
            .pipe(
                map(batch => {
                    const pendingChanges = [
                        ...this.filterPendingChanges<BatchItem>(batch),
                        ...this.batchService.getPendingDeletions()
                    ];
                    const uniqueChanges = this.removeDuplicates(pendingChanges);
                    return uniqueChanges;
                }),
                tap(pendingBatchItems => {
                    this.dataDogRumService.addAction('Syncing pending batch changes', {
                        itemCount: pendingBatchItems.length,
                        pendingBatchItems
                    });
                }),
                switchMap(pendingBatchItems => this.rehydratePendingBatchItems(pendingBatchItems)),
                switchMap(rehydratedBatch => this.syncOfflineBatchChanges(rehydratedBatch)),
                tap(syncedBatch => this.batchService.removeProductsNotFound(syncedBatch)),
            );
    }

    syncPendingShelfTagChanges(): Observable<Array<ShelfTag>> {
        let chainStoreId: number;

        return this.userService.getCurrentUser()
            .pipe(
                switchMap(user => {
                    chainStoreId = user.currentStore.id;
                    return this.shelfTagsService.getShelfTags(chainStoreId);
                }),
                switchMap(shelfTags => {
                    const pendingShelfTags = [
                        ...this.filterPendingChanges<ShelfTag>(shelfTags),
                        ...this.shelfTagsService.getPendingDeletions()
                    ];
                    // if the shelf tag is to be removed and has no ID then it never made it to the server,
                    // and can be removed from offline storage
                    const onlyOfflineChanges = pendingShelfTags.filter(this.shelfTagsService.filterOnlyOfflineChanges);
                    const shelfTagsToSync = pendingShelfTags.filter(shelfTag => {
                        return !this.shelfTagsService.filterOnlyOfflineChanges(shelfTag);
                    });
                    return forkJoin({
                        removeOfflineShelfTags: this.shelfTagsService.removeOfflineShelfTags(onlyOfflineChanges),
                        shelfTagsToSync: of(shelfTagsToSync)
                    });
                }),
                switchMap(({ shelfTagsToSync }) => {
                    const requests = shelfTagsToSync.map(shelfTag => {
                        if (shelfTag.errorType === ErrorType.Delete) {
                            return this.shelfTagsService.removeShelfTag(chainStoreId, shelfTag).pipe(
                                map(() => shelfTag)
                            );
                        }
                        return this.shelfTagsService.addShelfTag(chainStoreId, shelfTag);
                    });

                    return requests.length > 0 ? forkJoin([...requests]) : of([]);
                })
            );
    }

    private getBatch(): Observable<Array<BatchItem>> {
        return this.userService.getCurrentUser()
            .pipe(
                switchMap(user => {
                    return this.batchService.getBatch(user.currentStore.id);
                })
            );
    }

    private filterPendingChanges<T extends HasPendingChanges>(batch: Array<T>): Array<T> {
        return batch.filter(item => item?.hasPendingChanges);
    }

    private rehydratePendingBatchItems(batch: Array<BatchItem>): Observable<Array<BatchItemUpdate>> {
        const toRehydrate = batch.filter(batchItem => !this.hasVendorAttribute(batchItem));
        const rehydrationRequests = toRehydrate.map(batchItem => {
            return this.scanService.rehydrateBatchItem(batchItem);
        });
        const hydrated = batch
            .filter(this.hasVendorAttribute)
            .map(batchItem => {
                const batchItemUpdate = new BatchItemUpdate({
                    updatedBatchItem: batchItem
                });
                return of(batchItemUpdate);
            });
        const batchItemUpdates = [
            ...rehydrationRequests,
            ...hydrated
        ];
        return batchItemUpdates.length > 0 ? forkJoin(batchItemUpdates) : of([]);
    }

    private syncOfflineBatchChanges(batchItemUpdates: Array<BatchItemUpdate>): Observable<Array<BatchItemUpdate>> {
        return this.userService.getCurrentUser()
            .pipe(
                switchMap(user => {
                    const upsertRequests = batchItemUpdates.map(batchItemUpdate => {
                        return this.scanPipelineService.uploadBatchItemChanges(
                            batchItemUpdate,
                            new ProductScanConfiguration({
                                user
                            })
                        );
                    });
                    return upsertRequests.length > 0 ? forkJoin([...upsertRequests]) : of([]);
                })
            );
    }

    private hasVendorAttribute = (batchItem: BatchItem): boolean => {
        return batchItem.selectedVendorAttribute !== null && batchItem.selectedVendorAttribute !== undefined;
    };

    private removeDuplicates(items: Array<BatchItem>): Array<BatchItem> {
        return items.filter((item, index) => {
            return items.findIndex(i => i.upc === item.upc) === index;
        });
    }
}
