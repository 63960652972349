
<div class="scan-container" >
    <div class="close-camera">
        <gc-fab-container (triggerAction)="onCameraOff()"
            icon="close"
            label="Turn Camera Off ">
        </gc-fab-container>
    </div>

    <div id="div-video"
        class="viewport cell flex-50">
    </div>
</div>
