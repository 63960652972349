import { Injectable } from '@angular/core';
import { VendorAttribute } from '@buyiq-app/product/models/product';
import { VendorDiscount, VendorDiscountFilter } from '@buyiq-core/models/vendor-discount';
import { VendorDiscountsResource } from '@buyiq-core/resources/vendor-discounts.resource';
import { ApiCollectionResponse, SearchState } from '@cia-front-end-apps/shared/api-interaction';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class VendorDiscountService {
    constructor(
        private vendorDiscountResource: VendorDiscountsResource,
    ) {
    }

    getDiscounts(
        chainStoreId: number,
        searchState: SearchState,
        filter: VendorDiscountFilter
    ): Observable<ApiCollectionResponse<VendorDiscount>> {
        return this.vendorDiscountResource.getAll(chainStoreId, searchState, filter);
    }

    calculateDiscountPrice(
        { wsp }: VendorAttribute,
        { discountPercentage }: VendorDiscount
    ): number {
        const discountPrice = wsp - (wsp * discountPercentage) / 100;
        const roundedDiscount = Math.round(discountPrice * 10) / 10;
        return roundedDiscount;
    }
}
