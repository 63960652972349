import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Data, Event, NavigationEnd, Router } from '@angular/router';
import { NavigationState } from '@buyiq-core/models/navigation';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, filter, map, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    currentState: BehaviorSubject<NavigationState>;
    private previousUrl = '';
    private currentUrl = '';

    constructor(private router: Router) {
        this.currentState = new BehaviorSubject<NavigationState>(new NavigationState());
        this.currentUrl = this.router.url;
    }

    /* Starts the service monitoring for page changes in the app and updating the state observable */
    startNavigationMonitor(): void {
        this.router.events
            .pipe(
                filter((current: Event) => {
                    return current instanceof NavigationEnd;
                }),
                map((event: Event) => event as NavigationEnd),
                tap((current: NavigationEnd) => {
                    this.previousUrl = this.currentUrl;
                    this.currentUrl = current.url;
                }),
                distinctUntilChanged((previous: NavigationEnd, current: NavigationEnd) => {
                    const currentUrl = current.urlAfterRedirects || current.url;
                    return this.sanitizeUrl(previous.url) === this.sanitizeUrl(currentUrl);
                })
            )
            .subscribe((current: NavigationEnd) => {
                const navigationState = new NavigationState({
                    rawUrl: current.url,
                    sanitizedUrl: this.sanitizeUrl(current.url)
                });
                this.currentState.next(navigationState);
            });
    }

    getPreviousUrl(): string {
        return this.previousUrl;
    }

    /** @description Removes identifiers and parameters from a given URL. This includes any id
     *  embedded between slashes, query parameters, UIDs, and hash tag links
     *  @example /vendor/1/edit -> /vendor/edit
     *  @example /vendor?id=1 -> /vendor
     *  @example /vendor#section -> /vendor
     */
    sanitizeUrl(url: string): string {
        const digitOrUidRegex = /^\d+$|^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
        return url
            .split('/')
            .map(part => part.split('?')[0]) // Remove query parameters
            .map(part => part.split('#')[0]) // Remove id token
            .filter(part => !part.match(digitOrUidRegex)) // Remove digit identifiers and UIDs
            .join('/');
    }

    findRouteData(snapshot: ActivatedRouteSnapshot): Data {
        let data = snapshot.data;
        if (snapshot.children?.length > 0) {
            data = {
                ...data,
                ...this.findRouteData(snapshot.children[0])
            };
        }
        return data;
    }
}
