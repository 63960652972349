import { Chain } from '@buyiq-core/models/chain';
import { ChainStore } from '@buyiq-core/models/chain-store';
import { Address } from '@buyiq-core/models/contact-information';
import { FeatureFlag } from './feature-flags';

export class Profile {
    email?: string;
    firstName: string;
    lastName: string;
    username: string;
    address = new Address();

    constructor(options?: Partial<Profile>) {
        Object.assign(this, options);
    }
}

export class UserRegionalDistributor {
    id: number;
    sortIndex: number;

    constructor(options?: Partial<UserRegionalDistributor>) {
        Object.assign(this, options);
    }
}

export class SearchPreferences {
    futureSpecials: number;
    expiredSpecials: number;
    newProducts: number;
    discontinuedProducts: number;
    priceChange: number;

    constructor(options?: Partial<SearchPreferences>) {
        Object.assign(this, options);
    }
}

export class SubscriptionSetting {
    vendorId: number;
    specialsTypeId: number;

    constructor(options?: Partial<SubscriptionSetting>) {
        Object.assign(this, options);
    }
}

export class Settings {
    orderHistorySize: number;
    scanIncrementsQuantity: boolean;
    skuLookupEnabled: boolean;
    breakAfterXChars: number;
    skuLookupVendorId: number;
    skuLookupAlphaNumeric: boolean;
    allowZeroQtyInv: boolean;
    displaySRP: boolean;
    displayCPS: boolean;
    displayMinMulti: boolean;
    resetPartitionsOnSubmission: boolean;
    menuUploadDialog: boolean;
    showProductNameColumn: boolean;
    upcCorrection: boolean;
    calculateCheckDigit: boolean;
    trimLeadingValues: number;
    currentTemporaryVendorId: number;
    displayMinMax: boolean;
    isSprouts: boolean;
    scanFromAnywhere: boolean;
    scanLineTerminator: number;
    isAudioEnabled: boolean;
    qBrowser: boolean;

    constructor(options?: Partial<Settings>) {
        Object.assign(this, options);
    }
}

export class User {
    id: number;
    profile = new Profile();
    chain = new Chain();
    currentStore = new ChainStore();
    stores = new Array<ChainStore>();
    distributors = new Array<UserRegionalDistributor>();
    isEnabled: boolean;
    settings = new Settings();
    features = new Array<FeatureFlag>();
    appRole: string;
    department: string;

    constructor(options?: Partial<User>) {
        Object.assign(this, options);
    }
}
