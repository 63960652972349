import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MessageRead } from '@buyiq-app/system-messages/models/message-reads';
import { AppSettings } from '@buyiq-core/models/app-settings';
import { DataResource } from '@buyiq-custom-types';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class MessageReadsResource implements DataResource<MessageRead> {
    private baseUrl: string;

    constructor(private http: HttpClient, private appSettings: AppSettings) {
        this.baseUrl = `${this.appSettings.apis.promo}`;
    }

    getAll(userId: number): Observable<Array<MessageRead>> {
        const url = this.buildUrl(userId);
        return this.http.get<Array<MessageRead>>(url);
    }

    create(messageRead: MessageRead): Observable<MessageRead> {
        const url = this.buildUrl(messageRead.userId);
        return this.http.post<MessageRead>(url, messageRead);
    }

    private buildUrl(userId: number): string {
        return `${this.baseUrl}/users/${userId}/system-message-reads`;
    }
}
