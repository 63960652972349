import { Injectable, OnDestroy } from '@angular/core';
import { LegacyVendor } from '@buyiq-core/models/legacy-vendor';
import { VendorRanking, VendorRankingList, VendorRankingRequest } from '@buyiq-core/models/vendor-ranking';
import { SupportedVendorsResource } from '@buyiq-core/resources/supported-vendors.resource';
import { VendorRankingResource } from '@buyiq-core/resources/vendor-ranking.resource';
import { forkJoin, Observable, of, Subject, switchMap } from 'rxjs';
import { catchError, map, takeUntil, tap } from 'rxjs/operators';
import { VendorRankingStorageService } from '@buyiq-core/storage/vendor-ranking-storage.service';

@Injectable({
    providedIn: 'root'
})
export class ChainStoreService implements OnDestroy {
    private cachedSupportVendors: Array<LegacyVendor>;
    private readonly staleTime = 10 * 60 * 1000; // 10 minutes
    private readonly unsubscribe = new Subject<void>();

    constructor(
        private vendorRankingStorageService: VendorRankingStorageService,
        private vendorRankingResource: VendorRankingResource,
        private supportVendorsResource: SupportedVendorsResource
    ) {
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    getVendorRankings(requestConfig: VendorRankingRequest): Observable<Array<VendorRanking>> {
        // key for non APL users is chainStoreId-userId
        // key for APL users is chainStoreId-userId-searchText-isSku as the extra properties are used by the API
        const baseKey = `${requestConfig.chainStoreId}-${requestConfig.userId}`;
        const storageKey = requestConfig.isApl
            ? `${baseKey}-${requestConfig.searchText}-${requestConfig.isSku}`
            : baseKey;
        const rankingsRequest = this.vendorRankingResource.getAll(requestConfig).pipe(
            tap(rankings => {
                this.vendorRankingStorageService.setItem(storageKey, rankings)
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe();
            }),
            catchError(() => {
                    return this.vendorRankingStorageService.getItem(storageKey)
                        .pipe(
                            map(cachedRankings => cachedRankings?.data ?? [])
                        )
                }
            ),
            takeUntil(this.unsubscribe)
        );
        // we attempt to first get the rankings out of the cache
        return this.vendorRankingStorageService.getItem(storageKey).pipe(
            switchMap(cachedRankings => {
                // do we have cached rankings?
                if (cachedRankings && cachedRankings.data.length > 0) {
                    // if 10 minutes has elapsed, this will return the cached data and then update the cache going forward
                    if (this.isDataStale(cachedRankings.lastUpdated)) {
                        // make the HTTP request to get the remote rankings, but don't return the result
                        rankingsRequest
                            .pipe(
                                catchError(() => of(cachedRankings.data)),
                                takeUntil(this.unsubscribe)
                            )
                            .subscribe();
                    }

                    return of(cachedRankings.data);
                }

                // no cached rankings, so, we make the HTTP request to get the remote rankings
                return rankingsRequest;
            })
        );
    }

    getVendorRankingsBatch(
        chainId: number,
        chainStoreId: number,
        userId: number,
        isApl: boolean
    ): Observable<Array<VendorRankingList>>  {
        return this.vendorRankingResource.getBatchRankings(chainId, chainStoreId, userId, isApl).pipe(
            switchMap(rankings => {
                if (rankings.length === 0) {
                    return of([])
                }

                const baseKey = `${chainStoreId}-${userId}`;
                const requests = rankings.map(ranking => {
                    const storageKey = isApl ? `${baseKey}-${ranking.upc}-false` : baseKey;
                    return this.vendorRankingStorageService.setItem(storageKey, ranking.vendorRankings).pipe(
                        map(() => ranking),
                        catchError(() => of(null))
                    );
                });
                return forkJoin(requests);
            }),
            catchError(() => of([])),
        );
    }

    getSupportVendors(chainStoreId: number): Observable<Array<LegacyVendor>> {
        let request = of(this.cachedSupportVendors);

        if (!this.cachedSupportVendors) {
            request = this.supportVendorsResource.getAll(chainStoreId)
                .pipe(
                    map(supportVendors => {
                        this.cachedSupportVendors = supportVendors;
                        return this.cachedSupportVendors;
                    })
                );
        }

        return request;
    }

    clearRankings(): void {
        this.cachedSupportVendors = null;
    }

    private isDataStale(lastUpdated: number): boolean {
        return Date.now() - lastUpdated > this.staleTime;
    }
}
