import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { OfflineSyncService } from '@buyiq-app/product/services/offline-sync.service';
import { OnlineManagerService } from '@buyiq-app/product/services/online-manager.service';
import { MessageService } from '@buyiq-app/system-messages/services/message.service';
import {
    UnreadMessagesDialogComponent
} from '@buyiq-app/system-messages/unread-messages-dialog/unread-messages-dialog.component';
import { AnalyticsService } from '@buyiq-core/analytics/analytics.service';
import { AppStateService } from '@buyiq-core/app-state/app-state.service';
import { AudioService } from '@buyiq-core/audio/audio.service';
import { AuthService } from '@buyiq-core/auth/auth.service';
import { CustomIconService } from '@buyiq-core/custom-icon/custom-icon.service';
import { DialogService } from '@buyiq-core/dialog/dialog.service';
import { FocusService } from '@buyiq-core/focus/focus.service';
import { DialogConfig } from '@buyiq-core/models/dialog';
import { emitValueOnError } from '@cia-front-end-apps/shared/api-interaction';
import { NavigationService } from '@buyiq-core/navigation/navigation.service';
import { routeParts } from '@buyiq-core/route-parts';
import { UserService } from '@buyiq-core/user/user.service';
import { forkJoin, of, Subject, tap } from 'rxjs';
import {
    distinctUntilChanged,
    filter,
    switchMap,
    takeUntil,
} from 'rxjs/operators';
import { injectQueryClient } from '@ngneat/query';
import { FeatureFlagService } from '@cia-front-end-apps/shared/feature-flag/feature-flag.service';

@Component({
    selector: 'buyiq-app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    isLoginPage: boolean;
    loading = false;

    private queryClient = injectQueryClient();
    private readonly unsubscribe = new Subject<void>();

    constructor(
        private router: Router,
        private snackBar: MatSnackBar,
        private cdRef: ChangeDetectorRef,
        private navigationService: NavigationService,
        private analyticsService: AnalyticsService,
        private customIconService: CustomIconService,
        private authService: AuthService,
        private appStateService: AppStateService,
        private userService: UserService,
        private audioService: AudioService,
        private offlineSyncService: OfflineSyncService,
        private featureFlagService: FeatureFlagService,
        private onlineManagerService: OnlineManagerService,
        private messageService: MessageService,
        private dialogService: DialogService,
        private focusService: FocusService
    ) {
    }

    ngOnInit(): void {
        this.navigationService.startNavigationMonitor();
        this.analyticsService.initialize();
        this.customIconService.initialize();
        this.onlineManagerService.initialize();
        this.featureFlagService.initialize();

        this.navigationService.currentState
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((navigationState) => {
                const currentUrl = navigationState.sanitizedUrl || '';
                this.isLoginPage = currentUrl.includes(routeParts.login);
            });

        this.authService
            .getLoginState()
            .pipe(
                distinctUntilChanged(),
                tap(hasLoggedIn => {
                    if (!hasLoggedIn) {
                        this.queryClient.removeQueries();
                    }
                }),
                filter((hasLoggedIn) => hasLoggedIn),
                switchMap(() => this.userService.getCurrentUser()),
                tap(user => this.featureFlagService.identifyUser(user)),
                switchMap((user) => {
                    if (!user.isEnabled) {
                        this.logoutAndNotifyUser(
                            'Your account is not enabled. Please contact Customer Service for further help.'
                        );
                    } else if (!user.currentStore.isBuyiqEnabled) {
                        this
                            .logoutAndNotifyUser(`The selected store does not have In Aisle enabled. Either select a different store
                         or contact Customer Service for further help.`);
                    }

                    return of(user);
                }),
                filter(
                    (user) => user.isEnabled && user.currentStore.isBuyiqEnabled
                ),
                switchMap((user) => {
                    if (user.settings.isAudioEnabled) {
                        this.audioService.enableAudio();
                    }

                    this.loading = true;
                    this.cdRef.detectChanges();
                    return forkJoin({
                        user: of(user),
                        preload: this.appStateService.preloadCriticalData(user),
                    });
                }),
                switchMap(({ user }) => {
                    this.loading = false;
                    this.cdRef.detectChanges();
                    return forkJoin({
                        user: of(user),
                        preload: this.appStateService.preloadAllOtherData(user),
                        unreadMessages: emitValueOnError(
                            this.messageService.getUnreadMessages(user.id),
                            []
                        ),
                    });
                }),
                tap(({user}) => {
                    this.preloadModules();
                    this.offlineSyncService.initialize(user.currentStore.id);
                }),
                switchMap(({ unreadMessages }) => {
                    if (unreadMessages.length > 0) {
                        const config = new DialogConfig({
                            data: unreadMessages,
                            component: UnreadMessagesDialogComponent,
                        });
                        return this.dialogService
                            .show(config)
                            .pipe(
                                tap(() =>
                                    this.focusService.requestSearchInputFocus()
                                )
                            );
                    }
                    return of(null);
                }),
                takeUntil(this.unsubscribe)
            )
            .subscribe();
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    /**
     * @description Modules to be manually preloaded when the user has authenticated
     *
     * By default we don't allow modules to be loaded without being authenticated, so,
     * we can't make use of the Angular route preloadingStrategy
     *
     * We want to preload any modules integral to offline use of the app
     *
     * @private
     */
    private preloadModules(): void {
        import('./batch/batch.module').then((m) => m.BatchModule);
        import('./product/product.module').then((m) => m.ProductModule);
        import('./inventory/inventory.module').then((m) => m.InventoryModule);
        import('./shelf-tags/shelf-tags.module').then((m) => m.ShelfTagsModule);
    }

    private logoutAndNotifyUser(message: string): void {
        this.snackBar.open(message, 'OK', { duration: 10000 });
        this.router.navigateByUrl(routeParts.logout);
    }
}
