export enum DialogType {
    Simple,
    FullWidth
}

export class DialogConfig {
    component: any;
    data: any = {};
    disableClose = false;
    type = DialogType.Simple;

    constructor(options?: Partial<DialogConfig>) {
        Object.assign(this, options);
    }
}

export const dialogTypeToClassName = new Map<DialogType, string>([
    [DialogType.Simple, 'simple-dialog'],
    [DialogType.FullWidth, 'full-width-dialog']
]);
