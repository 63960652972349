export const upcCorrectionTrimOptions = Array.from(Array(10).keys());

export class UpcCorrectionConfiguration {
    requiresCheckDigit = false;
    totalCharactersToTrim = 0;

    constructor(options?: Partial<UpcCorrectionConfiguration>) {
        Object.assign(this, options);
    }
}
