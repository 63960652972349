import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from '@buyiq-core/models/app-settings';
import { DataResource } from '@buyiq-custom-types';
import { parseErrorResponse } from '@cia-front-end-apps/shared/api-interaction';
import { map, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Inventory } from '../models/inventory';

@Injectable({
    providedIn: 'root'
})
export class InventoryResource implements DataResource<Inventory> {
    private readonly baseUrl: string;

    constructor(private http: HttpClient, private appSettings: AppSettings) {
        this.baseUrl = `${this.appSettings.apis.order}`;
    }

    get(chainStoreId: number, upc: string): Observable<Inventory> {
        const url = `${this.baseUrl}/inventory-batch/${upc}`;
        const params = new HttpParams({
            fromObject: {
                chainStoreId: (chainStoreId || 0).toString(),
            }
        });

        return this.http.get<Inventory>(url, { params }).pipe(
            catchError(err => {
                const error = parseErrorResponse(err);
                return throwError(() => error);
            })
        );

    }

    getAll(chainStoreId: number): Observable<Array<Inventory>> {
        const url = `${this.baseUrl}/inventory-batch`;
        const params = new HttpParams({
            fromObject: {
                chainStoreId: (chainStoreId || 0).toString()
            }
        });

        return this.http.get<Array<Inventory>>(url, { params }).pipe(
            catchError(err => {
                const error = parseErrorResponse(err);
                return throwError(() => error);
            })
        );
    }

    create(chainStoreId: number, inventory: Inventory): Observable<Inventory> {
        const url = `${this.baseUrl}/inventory-batch`;
        const params = new HttpParams({
            fromObject: {
                chainStoreId: chainStoreId.toString()
            }
        });
        return this.http.post<Inventory>(url, inventory, { params }).pipe(
            catchError(err => {
                const error = parseErrorResponse(err);
                return throwError(() => error);
            })
        );
    }

    replace(chainStoreId: number, inventory: Inventory): Observable<Inventory> {
        const url = `${this.baseUrl}/inventory-batch`;
        const params = new HttpParams({
            fromObject: {
                chainStoreId: chainStoreId.toString()
            }
        });

        return this.http.put<Inventory>(url, inventory, { params }).pipe(
            catchError(err => {
                const error = parseErrorResponse(err);
                return throwError(() => error);
            })
        );
    }

    update(chainStoreId: number): Observable<Array<Inventory>> {
        const url = `${this.baseUrl}/inventory-batch`;
        const params = new HttpParams({
            fromObject: {
                chainStoreId: chainStoreId.toString()
            }
        });

        return this.http.patch<Array<Inventory>>(url, {}, { params }).pipe(
            catchError(err => {
                const error = parseErrorResponse(err);
                return throwError(() => error);
            })
        );
    }

    remove(chainStoreId: number, inventoryId: number): Observable<boolean> {
        const url = `${this.baseUrl}/inventory-batch`;
        const params = new HttpParams({
            fromObject: {
                chainStoreId: chainStoreId.toString(),
                inventoryId: inventoryId.toString()
            }
        });

        return this.http.delete(url, { params })
            .pipe(
                map(successResponse => true),
                catchError(err => {
                    const error = parseErrorResponse(err);
                    return throwError(() => error);
                })
            );
    }
}
