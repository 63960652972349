import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MissedDealService } from '@buyiq-app/missed-deals/services/missed-deal.services';
import { Click } from '@buyiq-app/promotions/models/click';
import { PromotionTypeId } from '@buyiq-app/promotions/models/promotion';
import { components, promotionDisplayName } from '@buyiq-app/promotions/models/promotion-tracking';
import { PromotionTrackingService } from '@buyiq-app/promotions/services/promotion-tracking.service';
import { PromotionService } from '@buyiq-app/promotions/services/promotion.service';
import { AuthService } from '@buyiq-core/auth/auth.service';
import { FeatureFlag, FeatureFlagParameter, LaunchDarklyFeatureFlag } from '@buyiq-core/models/feature-flags';
import { pageTitles, routeParts } from '@buyiq-core/route-parts';
import { UserService } from '@buyiq-core/user/user.service';
import { emitValueOnError } from '@cia-front-end-apps/shared/api-interaction';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { NavigationItem, NavigationItemGroup } from '../models/navigation';
import { AnalyticsService } from '@buyiq-core/analytics/analytics.service';
import { AnalyticsAction, AnalyticsCategory, AnalyticsEvent } from '@buyiq-core/models/analytics';
import { FeatureFlagService } from '@cia-front-end-apps/shared/feature-flag/feature-flag.service';

@Component({
    selector: 'buyiq-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationComponent implements OnInit, OnDestroy {
    @Output() clickNavigationItem = new EventEmitter<void>();

    username: string;
    storeName: string;
    navigationGroups: Array<NavigationItemGroup>;
    myFavorites: string;

    private readonly navigationGroupWhichShouldBeLast = new NavigationItemGroup({
        name: 'Tools',
        items: [
            new NavigationItem({
                displayName: pageTitles.systemMessages,
                icon: 'info',
                path: `/${routeParts.systemMessages}`
            }),
            new NavigationItem({
                displayName: pageTitles.settings,
                icon: 'settings',
                path: `/${routeParts.settings}`
            }),
            new NavigationItem({
                displayName: pageTitles.logout,
                icon: 'exit_to_application',
                path: `/${routeParts.logout}`
            })
        ]
    });

    private unsubscribe = new Subject<void>();

    constructor(
        private router: Router,
        private cdRef: ChangeDetectorRef,
        private authService: AuthService,
        private userService: UserService,
        private analyticsService: AnalyticsService,
        private promotionService: PromotionService,
        private missedDealService: MissedDealService,
        private promotionTrackingService: PromotionTrackingService,
        private featureFlagService: FeatureFlagService
    ) {
    }

    ngOnInit(): void {
        this.myFavorites = pageTitles.myFavorites;
        this.navigationGroups = [
            new NavigationItemGroup({
                name: 'Order',
                items: [
                    new NavigationItem({
                        displayName: pageTitles.products,
                        icon: 'settings_remote',
                        path: `/${routeParts.products}`
                    }),
                    new NavigationItem({
                        displayName: pageTitles.receiving,
                        icon: 'local_shipping',
                        path: `/${routeParts.receiving}`,
                        featureFlagParameters: new FeatureFlagParameter({
                            allow: FeatureFlag.SupportsReceiving
                        })
                    }),
                    new NavigationItem({
                        displayName: pageTitles.shelfTags,
                        icon: 'sell',
                        path: `/${routeParts.shelfTags}`
                    }),
                    new NavigationItem({
                        displayName: pageTitles.inventory,
                        icon: 'inventory_2',
                        path: `/${routeParts.inventory}`
                    }),
                    new NavigationItem({
                        displayName: pageTitles.search,
                        icon: 'search',
                        path: `/${routeParts.search}`,
                        featureFlagParameters: new FeatureFlagParameter({
                            prevent: FeatureFlag.SupportsApl
                        })
                    }),
                    new NavigationItem({
                        displayName: pageTitles.batch,
                        icon: 'preview',
                        path: `/${routeParts.batch}`
                    })
                ]
            }),
            new NavigationItemGroup({
                name: 'Promotions',
                items: [
                    new NavigationItem({
                        displayName: pageTitles.newProducts,
                        icon: 'science',
                        path: `/${routeParts.newProducts}`,
                        featureFlagParameters: new FeatureFlagParameter({
                            allow: FeatureFlag.NewProducts
                        })
                    }),
                    new NavigationItem({
                        displayName: pageTitles.samples,
                        icon: 'card_giftcard',
                        path: `/${routeParts.samples}`,
                        featureFlagParameters: new FeatureFlagParameter({
                            allow: FeatureFlag.Samples
                        })
                    }),
                    new NavigationItem({
                        displayName: pageTitles.deals,
                        icon: 'attach_money',
                        path: `/${routeParts.deals}`,
                        featureFlagParameters: new FeatureFlagParameter({
                            allow: FeatureFlag.MissedDeals
                        })
                    }),
                    new NavigationItem({
                        displayName: pageTitles.newVendors,
                        icon: 'local_shipping',
                        path: `/${routeParts.newVendors}`,
                        featureFlagParameters: new FeatureFlagParameter({
                            allow: FeatureFlag.NewVendors
                        })
                    })
                ]
            }),
            this.navigationGroupWhichShouldBeLast
        ];

        this.authService.getLoginState()
            .pipe(
                distinctUntilChanged(),
                filter(hasLoggedIn => hasLoggedIn),
                switchMap(hasLoggedIn => this.userService.getCurrentUser()),
                switchMap(user => {
                    // Now we have the user, let's fetch the feature flag
                    return this.featureFlagService.getFlag(LaunchDarklyFeatureFlag.ReleaseSavedItems).pipe(
                        map(hasReleaseSavedItemsEnabled => ({ user, hasReleaseSavedItemsEnabled }))
                    );
                }),
                takeUntil(this.unsubscribe)
            )
            .subscribe(({user, hasReleaseSavedItemsEnabled}) => {
                const profile = user.profile;
                this.username = `${profile.firstName} ${profile.lastName}`;
                this.storeName = user.currentStore?.name || '';

                this.navigationGroups.forEach(group => {
                    const updateGroupItems = group.items.filter(item => {
                        const hasFeatureFlagParameter = !!item.featureFlagParameters;

                        if (hasFeatureFlagParameter) {
                            const allowRequirement = item.featureFlagParameters.allow;
                            if (!!allowRequirement) {
                                return user.features.includes(allowRequirement);
                            }

                            const rejectRequirement = item.featureFlagParameters.prevent;
                            if (!!rejectRequirement) {
                                return !user.features.includes(rejectRequirement);
                            }
                        }

                        return true;
                    });
                    group.items = updateGroupItems;
                });

                // TODO: Remove this once Saved Items is ready for production
               if (this.navigationGroups[0].items.findIndex(a => a.displayName === pageTitles.myFavorites) > 0) {
                this.navigationGroups[0].items.splice(
                        this.navigationGroups[0].items.findIndex(a => a.displayName === pageTitles.myFavorites), 1
                    )
               }

                if (hasReleaseSavedItemsEnabled) {
                    this.navigationGroups[0].items.push(
                        new NavigationItem({
                            displayName: pageTitles.myFavorites,
                            icon: 'star',
                            path: `/${routeParts.savedItems}`,
                        }),
                    );
                }

                this.cdRef.detectChanges();
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    onClickNavigationItem(navigationItem: NavigationItem): void {
        this.logClick(navigationItem)
            .pipe(take(1))
            .subscribe(() => {
                this.clearNavigationCache(navigationItem);
                this.clickNavigationItem.emit();
            });
    }

    onKeyPressed(navigationItem: NavigationItem): void {
        this.logClick(navigationItem)
            .pipe(take(1))
            .subscribe(() => {
                this.clearNavigationCache(navigationItem);
                this.clickNavigationItem.emit();
                this.router.navigate([navigationItem.path]);
            });
    }

    private clearNavigationCache(navigationItem: NavigationItem): void {
        switch (navigationItem.displayName) {
            case pageTitles.deals:
                this.missedDealService.cache = null;
                break;
            case pageTitles.newProducts:
                this.promotionService.newProductsCache = null;
                break;
            default:
        }
    }

    private logClick(navigationItem: NavigationItem): Observable<Click> {
        let component = '';
        let promotionType = '';
        switch (navigationItem.displayName) {
            case pageTitles.samples:
                component = components.navigationMenuSample;
                promotionType = promotionDisplayName.get(PromotionTypeId.Sample);
                break;
            case pageTitles.deals:
                component = components.allMissedDealsMenuItem;
                promotionType = promotionDisplayName.get(PromotionTypeId.Deal);
                break;
            case pageTitles.newVendors:
                component = components.newVendorsMenuItem;
                promotionType = promotionDisplayName.get(PromotionTypeId.NewVendor);
                break;
            case pageTitles.receiving:
                this.analyticsService.trackEvent(new AnalyticsEvent({
                    action: AnalyticsAction.ClickReceivingMenuItem,
                    category: AnalyticsCategory.Receiving,
                }));
                break;
            case pageTitles.myFavorites:
                this.analyticsService.trackEvent(new AnalyticsEvent({
                    action: AnalyticsAction.ClickSavedItemsMenuItem,
                    category: AnalyticsCategory.SavedItems,
                }));
                break;
            default:
        }

        const click = new Click({
            component: component,
            promotionType: promotionType,
            page: ''
        });

        return emitValueOnError(
            this.promotionTrackingService.logClick(click),
            new Click()
        );
    }
}
