<h2 mat-dialog-title>{{title}}</h2>

<mat-dialog-content>
    {{content}}
</mat-dialog-content>

<mat-dialog-actions>
    <button *ngIf="cancelButtonText"
            class="button"
            color="accent"
            mat-button
            mat-dialog-close>
        {{cancelButtonText}}
    </button>
    <button [mat-dialog-close]="true"
            class="button"
            color="accent"
            mat-button>
        {{actionButtonText}}
    </button>
</mat-dialog-actions>
