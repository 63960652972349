import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from '@buyiq-core/models/app-settings';
import { DataResource } from '@buyiq-custom-types';
import { ApiCollectionResponse, ParameterBuilder } from '@cia-front-end-apps/shared/api-interaction';
import { Observable } from 'rxjs';
import { PromotionResponse } from '../models/promotion-response';

@Injectable({
    providedIn: 'root'
})
export class PromotionResponseResource implements DataResource<PromotionResponse> {
    private baseUrl: string;

    constructor(private http: HttpClient, private appSettings: AppSettings) {
        this.baseUrl = `${this.appSettings.apis.promo}`;
    }

    create(promotionResponse: PromotionResponse): Observable<PromotionResponse> {
        const url = this.buildUrl();
        return this.http.post<PromotionResponse>(url, promotionResponse);
    }

    getAll(
        chainStoreIds: Array<number>,
        promotionTypeIds: Array<number>,
        promotionId?: number
    ): Observable<ApiCollectionResponse<PromotionResponse>> {
        const url = this.buildUrl();
        let params = new HttpParams({
            fromObject: {
                promotionId: promotionId ? promotionId.toString() : ''
            }
        });
        params = ParameterBuilder.buildArrayParameter(chainStoreIds, 'chainStoreIds', params);
        params = ParameterBuilder.buildArrayParameter(promotionTypeIds, 'promoTypes', params);

        return this.http.get<ApiCollectionResponse<PromotionResponse>>(url, { params });
    }

    private buildUrl(): string {
        return `${this.baseUrl}/promotion-responses`;
    }
}
