import { Injectable } from '@angular/core';
import { LocalForageService } from '@buyiq-core/storage/local-forage.service';
import { OfflineStorage, StoreName } from '@buyiq-core/storage/storage';
import { Special } from '@cia-front-end-apps/shared/specials';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SpecialStorageService implements OfflineStorage<Array<Special>> {
    constructor(
        private localForageService: LocalForageService
    ) {
    }

    clearStorage(): Observable<void> {
        return this.localForageService.clearStorage(StoreName.Specials);
    }

    getItem(key: string): Observable<Array<Special>> {
        return this.localForageService.getItem(key, StoreName.Specials);
    }

    removeItem(key: string): Observable<void> {
        return this.localForageService.removeItem(key, StoreName.Specials);
    }

    setItem(key: string, value: any): Observable<Array<Special>> {
        return this.localForageService.setItem(key, value, StoreName.Specials);
    }

    getAll(): Observable<Array<Array<Special>>> {
        return this.localForageService.getAll(StoreName.Specials);
    }
}
