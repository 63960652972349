import { ApplicationId } from '@buyiq-core/models/application';

export class Click {
    id: number;
    promotionId: number;
    promotionType: string;
    userId: number;
    storeId: number;
    vendorId: number;
    application: ApplicationId;
    page: string;
    component: string;
    upc: string;
    quantity: number;

    constructor(options?: Partial<Click>) {
        Object.assign(this, options);
    }
}
