<h2 mat-dialog-title>
    Send Feedback
</h2>

<mat-dialog-content>
    <form [formGroup]="feedbackForm">
        <div class="row">
            <mat-form-field>
                <input matInput
                       formControlName="firstName"
                       placeholder="First Name">
            </mat-form-field>
        </div>
        <div class="row">
            <mat-form-field>
                <input matInput
                       formControlName="lastName"
                       placeholder="Last Name">
            </mat-form-field>
        </div>
        <div class="row">
            <mat-form-field>
                <input matInput
                       formControlName="email"
                       placeholder="Email">
                <mat-error *ngIf="email.hasError('email') && !email.hasError('required')">
                    Please enter a valid email address
                </mat-error>
                <mat-error *ngIf="email.hasError('required')">
                    Your email is required
                </mat-error>
            </mat-form-field>
        </div>
        <div class="row">
            <mat-form-field>
                <textarea class="feedback"
                          matInput
                          [cdkTextareaAutosize]="true"
                          formControlName="feedback"
                          placeholder="Describe your issue or share your ideas">
                </textarea>
                <mat-error *ngIf="feedback.hasError('required')">
                    Feedback is required
                </mat-error>
            </mat-form-field>
        </div>
    </form>
    <p>Version: {{buildNumber}}</p>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button
            mat-dialog-close
            color="accent"
            class="button">
        Cancel
    </button>
    <button mat-button
            (click)="onSubmit()"
            [disabled]="!feedbackForm.valid || this.loading"
            color="accent"
            class="button">
        Send
    </button>
</mat-dialog-actions>
