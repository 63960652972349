import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SimpleDialogComponent } from '@buyiq-core/dialog/simple-dialog/simple-dialog.component';
import { DialogConfig, DialogType, dialogTypeToClassName } from '@buyiq-core/models/dialog';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DialogService {
    constructor(private matDialogService: MatDialog) {
    }

    /**
     * @description Displays a simple confirm dialog with an action and cancel button
     * @param title The title to be displayed in the dialog
     * @param content The message to be displayed in the dialog
     * @param actionButtonText Text to display on the action button
     * @returns a truthy value will be emitted if the dialog's action button
     * is clicked; a falsy value will be emitted if the dialog is cancelled or closed without the
     * user clicking an option
     */
    confirm(title: string, content?: string, actionButtonText?: string): Observable<boolean> {
        const classNameForType = this.getClassNameForType(DialogType.Simple);
        const classNames = [
            'base-dialog',
            classNameForType
        ];

        const dialogRef = this.matDialogService.open(SimpleDialogComponent, {
            data: {
                title: title,
                content: content,
                actionButtonText: actionButtonText || 'Confirm',
                cancelButtonText: 'Cancel'
            },
            panelClass: classNames
        });

        return dialogRef.afterClosed();
    }

    /**
     * @description Displays a simple alert dialog with a single button to dismiss it
     * @param title The title to be displayed in the dialog
     * @param content The message to be displayed in the dialog
     * @returns  emits true when the dialog is closed
     */
    alert(title: string, content?: string): Observable<boolean> {
        const classNameForType = this.getClassNameForType(DialogType.Simple);
        const classNames = [
            'base-dialog',
            classNameForType
        ];

        const dialogRef = this.matDialogService.open(SimpleDialogComponent, {
            data: {
                title: title,
                content: content,
                actionButtonText: 'Ok'
            },
            panelClass: classNames
        });

        return dialogRef.afterClosed();
    }

    /**
     * @description Allows any component to be displayed within a plain dialog. An optional data
     * object can be injected into the component to provide view data upon its creation.
     *
     * Note: there is no type-enforcement for the component or data objects in order to allow any
     * component with custom data to be passed.
     *
     * @param dialogConfig the configuration for the dialog
     * @returns A value emitted by the dialog when it is closed
     */
    show(dialogConfig: DialogConfig): Observable<any> {
        const classNameForType = this.getClassNameForType(dialogConfig.type || DialogType.Simple);
        const classNames = [
            'base-dialog',
            classNameForType
        ];

        const dialogRef = this.matDialogService.open(dialogConfig.component, {
            data: dialogConfig.data,
            disableClose: dialogConfig.disableClose,
            panelClass: classNames,
            maxWidth: 'none'
        });

        return dialogRef.afterClosed();
    }

    private getClassNameForType(type: DialogType): string {
        return dialogTypeToClassName.get(type);
    }
}
