import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from '@buyiq-core/models/app-settings';
import { DataResource } from '@buyiq-custom-types';
import { Observable } from 'rxjs';
import { StockedProduct } from '../models/stocked-product';

@Injectable({
    providedIn: 'root'
})
export class StockedProductsResource implements DataResource<StockedProduct> {
    private readonly baseUrl: string;

    constructor(private http: HttpClient, private appSettings: AppSettings) {
        this.baseUrl = `${this.appSettings.apis.order}`;
    }

    getAll(storeId: number, vendorId: number): Observable<Array<StockedProduct>> {
        const url = `${this.baseUrl}/stocked-products`;
        const params = new HttpParams({
            fromObject: {
                chainStoreId: (storeId || 0).toString(),
                vendorId: (vendorId || 0).toString(),
            }
        });
        return this.http.get<Array<StockedProduct>>(url, { params });
    }
}
