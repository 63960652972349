import { NgModule } from '@angular/core';
import { SharedModule } from '@buyiq-shared/shared.module';
import { httpsInterceptorProvider } from './https-interceptor.provider';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';

@NgModule({
    imports: [
        SharedModule
    ],
    declarations: [
        UnauthorizedComponent
    ],
    providers: [
        httpsInterceptorProvider
    ]
})
export class AuthModule { }
