import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from '@buyiq-core/models/app-settings';
import { DataResource } from '@buyiq-custom-types';
import { Observable } from 'rxjs';
import { Click } from '../models/click';

@Injectable({
    providedIn: 'root'
})
export class ClickResource implements DataResource<Click> {
    private baseUrl: string;

    constructor(private http: HttpClient, private appSettings: AppSettings) {
        this.baseUrl = `${this.appSettings.apis.promo}`;
    }

    create(click: Click): Observable<Click> {
        const url = `${this.baseUrl}/clicks`;
        return this.http.post<Click>(url, click);
    }
}
