import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, Router } from '@angular/router';
import { NavigationService } from '@buyiq-core/navigation/navigation.service';
import { routeParts } from '@buyiq-core/route-parts';
import { BatchSummary, BatchSummaryType } from '@buyiq-shared/models/batch-summary';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ToolbarService implements OnDestroy {
    private activeBatchSummaryType = BatchSummaryType.Generic;
    private batchSummaries = new Map<BatchSummaryType, BatchSummary>();

    private readonly batchSummaryChanges = new Subject<BatchSummary>();
    private readonly pageTitleChanges = new Subject<string>();
    private readonly unsubscribe = new Subject<boolean>();

    constructor(
        private router: Router,
        private navigationService: NavigationService,
        private activatedRoute: ActivatedRoute
    ) {
        this.router.events
            .pipe(
                filter((event: Event) => event instanceof NavigationEnd),
                map((event: Event) => {
                    const navigationEvent = event as NavigationEnd;
                    return this.navigationService.sanitizeUrl(navigationEvent.url);
                }),
                takeUntil(this.unsubscribe)
            )
            .subscribe(url => {
                const routeData = this.navigationService.findRouteData(this.activatedRoute.snapshot);
                this.pageTitleChanges.next(routeData.title);

                let activeType = BatchSummaryType.Product;
                if (url.includes(routeParts.shelfTags)) {
                    activeType = BatchSummaryType.ShelfTags;
                }
                else if (url.includes(routeParts.inventory)) {
                    activeType = BatchSummaryType.Inventory;
                }
                else if (url.includes(routeParts.receiving)) {
                    activeType = BatchSummaryType.Receiving;
                }

                // Only refresh the batch summary when the active type changes. If the type is already active,
                // the current summary will already be displayed.
                if (activeType !== this.activeBatchSummaryType) {
                    const summary = this.batchSummaries.get(activeType);
                    this.batchSummaryChanges.next(summary);
                }
                this.activeBatchSummaryType = activeType;
            });
    }

    ngOnDestroy(): void {
        this.batchSummaryChanges.complete();
        this.pageTitleChanges.complete();
        this.unsubscribe.next(true);
        this.unsubscribe.complete();
    }

    getActiveBatchSummary(): Observable<BatchSummary> {
        return this.batchSummaryChanges.asObservable();
    }

    getPageTitle(): Observable<string> {
        return this.pageTitleChanges.asObservable();
    }

    updateBatchSummary(summary: BatchSummary): void {
        this.batchSummaries.set(summary.type, summary);

        if (summary.type === this.activeBatchSummaryType) {
            this.batchSummaryChanges.next(summary);
        }
    }
}
