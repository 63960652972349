import { Observable } from 'rxjs';

import { ProductOrderHistory } from '@cia-front-end-apps/shared/order-history/order-history';

export interface OfflineStorage<T> {
    getAll?(): Observable<Array<T>>;

    getItem?(key: string): Observable<T>;

    getItemPromise?(key: string): Promise<T>;

    setItems?(items: Array<T>, key: ItemKey): Observable<Array<T>>;

    setItem?(key: string, value: any): Observable<T>;

    removeItem?(key: string): Observable<void>;

    clearStorage?(): Observable<void>;

    getKeyValuePairs?(): Promise<Array<{key: string, value: Array<ProductOrderHistory>}>>;
}

export enum StoreName {
    Batch = 'batch',
    Inventory = 'inventory',
    ShelfTags = 'shelfTags',
    User = 'user',
    VendorInfo = 'vendorInfo',
    OrderHistory = 'orderHistory',
    Specials = 'specials',
    VendorRanking = 'vendorRanking'
}

export class OfflineStores {
    inventory: LocalForage;
    batch: LocalForage;
    shelfTags: LocalForage;
    user: LocalForage;
    vendorInfo: LocalForage;
    orderHistory: LocalForage;
    specials: LocalForage;
    vendorRanking: LocalForage;

    constructor(options?: Partial<OfflineStores>) {
        Object.assign(this, options);
    }
}

export enum ItemKey {
    Upc = 'upc',
    StorageKey = 'storageKey'
}

export interface HasPendingChanges {
    hasPendingChanges: boolean;
}

export enum UserStorageKey {
    User = 'user',
    PreviousUser = 'previousUser'
}

export class StorageData<T> {
    data: T;
    lastUpdated: number;

    constructor(options?: Partial<StorageData<T>>) {
        Object.assign(this, options);
    }
}
