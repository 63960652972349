import { ProductOrderHistory } from '@cia-front-end-apps/shared/order-history';

export const historyLength = 6;
export const blankProductOrderHistory: Array<ProductOrderHistory> = Array.from(
    { length: historyLength },
    () => (new ProductOrderHistory({
        dateOrdered: 'N/A',
        quantity: '-',
        vendor: ''
    }))
);

export class ProductOrderHistoryList {
    storeId: number;
    upc: string;
    history: ProductOrderHistory[];

    constructor(options?: Partial<ProductOrderHistoryList>) {
        Object.assign(this, options);
    }
}
