import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TrackByFunction } from '@angular/core';
import { MatLegacySelectionListChange as MatSelectionListChange } from '@angular/material/legacy-list';
import { FilterChangeEvent, FilterGroup, ProductFilters, SearchFilter } from '@buyiq-shared/models/search-filter';
import { SortOption, SortOptionChangeEvent } from '@buyiq-shared/models/sort';
import { Subject } from 'rxjs';

@Component({
    selector: 'buyiq-filter-menu',
    templateUrl: './filter-menu.component.html',
    styleUrls: ['./filter-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterMenuComponent {
    @Output() clearFilters = new EventEmitter<void>();
    @Output() changeFilter = new EventEmitter<FilterChangeEvent>();
    @Output() changeSortOption = new EventEmitter<SortOptionChangeEvent>();

    @Input() sortOptions: Array<SortOption>;
    @Input() defaultSortType: string;
    @Input() filterGroups: Array<FilterGroup>;
    @Input() openFilterGroup: string;
    @Input() filters: ProductFilters;
    @Input() displayIcon = false;

    filterTrackBy: TrackByFunction<SearchFilter> = (index: number, filter: SearchFilter) => filter.key;
    clearSort = new Subject<boolean>();

    onClearFilters(): void {
        this.clearSort.next(true);
        this.clearFilters.emit();
    }

    onChangeSortOption(event: SortOptionChangeEvent): void {
        this.changeSortOption.emit(event);
    }

    onChangeFilter(event: MatSelectionListChange, groupId: string): void {
        const changedFilter = event.options[0];
        const filterChangeEvent = new FilterChangeEvent({
            isSelected: changedFilter.selected,
            filterId: changedFilter.value,
            groupId
        });

        this.changeFilter.emit(filterChangeEvent);
    }

    getFilterGroupScrollHeight(group: Array<any>): number {
        // should max out at 6 items, but be dynamic up to that point. Each item is 48px tall.
        return (group.length > 6 ? 6 : group.length) * 48;
    }
}
