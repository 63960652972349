import { Injectable } from '@angular/core';
import {
    PromotionParameters,
    PromotionTypeId,
} from '@buyiq-app/promotions/models/promotion';
import { PromotionService } from '@buyiq-app/promotions/services/promotion.service';
import { ChainStoreService } from '@buyiq-core/chain-store/chain-store.service';
import { emitValueOnError } from '@cia-front-end-apps/shared/api-interaction';
import { FeatureFlag } from '@buyiq-core/models/feature-flags';
import {
    VendorRanking,
    VendorRankingRequest,
} from '@buyiq-core/models/vendor-ranking';
import { UserService } from '@buyiq-core/user/user.service';
import { SearchState } from '@cia-front-end-apps/shared/api-interaction';
import moment from 'moment';
import { forkJoin, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
    MissedDealParameters,
    MissedDealsCache,
    MissedDealSearchApiResponse,
} from '../models/missed-deal';
import { MissedDealResource } from '../resources/missed-deals.resource';

@Injectable({
    providedIn: 'root',
})
export class MissedDealService {
    cache: MissedDealsCache = null;
    private readonly promotionSearchState = new SearchState({
        pageSize: 10000,
        sortBy: 'description',
    });

    private readonly promotionParameters = new PromotionParameters({
        promoTypes: [PromotionTypeId.Deal],
        startDate: moment().endOf('day').toDate(),
        endDate: moment().startOf('day').toDate(),
    });

    constructor(
        private missedDealResource: MissedDealResource,
        private chainStoreService: ChainStoreService,
        private promotionService: PromotionService,
        private userService: UserService
    ) {}

    getMissedDealProducts(
        searchState: SearchState,
        missedDealParameters: MissedDealParameters
    ): Observable<MissedDealSearchApiResponse> {
        return this.userService.getCurrentUser().pipe(
            switchMap((user) => {
                const isApl = user.features.includes(FeatureFlag.SupportsApl);
                const requestConfig = new VendorRankingRequest({
                    chainId: user.chain.id,
                    chainStoreId: user.currentStore.id,
                    userId: user.id,
                    searchText: '',
                    isApl: isApl,
                    isSku: false,
                });
                const vendorRankingRequest = emitValueOnError<
                    Array<VendorRanking>
                >(
                    this.chainStoreService.getVendorRankings(requestConfig),
                    new Array<VendorRanking>()
                );
                return forkJoin({
                    user: of(user),
                    rankings: vendorRankingRequest,
                });
            }),
            switchMap(({ user, rankings }) => {
                const chainStoreId = user.currentStore.id;
                const distributorIds = rankings.map((d) => d.legacyVendorId);
                const parameters = new MissedDealParameters({
                    ...missedDealParameters,
                    distributorIds,
                    chainStoreId,
                });
                return this.missedDealResource.getAll(searchState, parameters);
            })
        );
    }

    availableVendorDeals(vendorId: number): Observable<boolean> {
        return this.getMissedDealsForVendor(vendorId).pipe(
            map(
                (missedDeals) =>
                    missedDeals.items && missedDeals.items.length > 0
            )
        );
    }

    getMissedDealsForVendor(
        vendorId: number
    ): Observable<MissedDealSearchApiResponse> {
        return this.promotionService
            .getPromotions(this.promotionSearchState, this.promotionParameters)
            .pipe(
                switchMap((promotions) => {

                    const advertiserIds = promotions.items.map(
                        (promotion) => promotion.advertiser.legacyId
                    );
                    const missedDealParameters = new MissedDealParameters({
                        advertiserIds: [...new Set(advertiserIds)],
                        vendorIds: [vendorId],
                    });

                    return this.getMissedDealProducts(
                        new SearchState({ sortBy: 'relevance', pageSize: 1 }),
                        missedDealParameters
                    );
                })
            );
    }
}
