<mat-icon *ngIf="notificationIcon"
          class="notification-icon">
    {{ notificationIcon }}
</mat-icon>

<p class="notification-text"
   (click)="onTriggerAction()">
    {{ notificationText }}
</p>

<button mat-icon-button
        class="close-button"
        (click)="onDismissNotification()">
    <mat-icon>close</mat-icon>
</button>
