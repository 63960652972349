<img (error)="onFailToLoad($event)"
     *ngIf="hasProductImage"
     [src]="fullUrl"
     alt="Product thumbnail"
     class="image"/>

<mat-icon *ngIf="!hasProductImage"
          class="missing">
    photo_camera
</mat-icon>
