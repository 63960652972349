import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from '@buyiq-app/login/login.component';
import { PreloadStateResolver } from '@buyiq-core/app-state/preload-state.resolver';
import { AuthFeatureFlagGuard } from '@buyiq-core/auth/auth-feature-flag.guard';
import { AuthGuard } from '@buyiq-core/auth/auth.guard';
import { UnauthorizedComponent } from '@buyiq-core/auth/unauthorized/unauthorized.component';
import { FeatureFlag } from '@buyiq-core/models/feature-flags';
import { NotFoundComponent } from '@buyiq-core/not-found/not-found.component';
import { OfflineGuard } from '@buyiq-core/offline/offline.guard';
import { pageTitles, routeParts } from '@buyiq-core/route-parts';
import { UserResolver } from '@buyiq-core/user/user.resolver';

const definitionsThatMustBeFirst: Routes = [
    {
        path: 'v1',
        redirectTo: '',
    },
    {
        path: 'v2',
        redirectTo: '',
    },
    {
        path: routeParts.login,
        pathMatch: 'full',
        data: {
            title: pageTitles.login,
        },
        component: LoginComponent,
    },
    {
        path: routeParts.logout,
        data: {
            title: pageTitles.logout,
        },
        loadChildren: () =>
            import('./logout/logout.module').then((m) => m.LogoutModule),
    },
];

const definitionsThatMustBeLast: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: routeParts.products,
    },
    {
        path: '**',
        pathMatch: 'full',
        component: NotFoundComponent,
        data: {
            title: pageTitles.notFound,
        },
        canActivate: [AuthGuard],
    },
];

// The paths with a component specified are eagerly loaded. They are components needed when the app
// loads. They should be part of the CoreModule.
// The paths with loadChildren are lazy-loaded. They should be part of feature modules.
export const routes: Routes = [
    ...definitionsThatMustBeFirst,
    {
        path: '',
        canActivateChild: [AuthGuard],
        resolve: {
            user: UserResolver,
            hasPreloadedData: PreloadStateResolver,
        },
        children: [
            {
                path: routeParts.systemMessages,
                data: {
                    title: pageTitles.systemMessages,
                },
                loadChildren: () =>
                    import('./system-messages/system-messages.module').then(
                        (m) => m.SystemMessagesModule
                    ),
                canLoad: [AuthGuard, OfflineGuard],
                canActivate: [OfflineGuard],
            },
            {
                path: routeParts.samples,
                data: {
                    title: pageTitles.samples,
                    featureFlag: {
                        allow: FeatureFlag.Samples,
                    },
                },
                loadChildren: () =>
                    import('./samples/samples.module').then(
                        (m) => m.SamplesModule
                    ),
                canLoad: [AuthGuard, AuthFeatureFlagGuard, OfflineGuard],
                canActivate: [OfflineGuard],
            },
            {
                path: routeParts.newProducts,
                data: {
                    title: pageTitles.newProducts,
                    featureFlag: {
                        allow: FeatureFlag.NewProducts,
                    },
                },
                loadChildren: () =>
                    import('./new-products/new-products.module').then(
                        (m) => m.NewProductsModule
                    ),
                canLoad: [AuthGuard, AuthFeatureFlagGuard, OfflineGuard],
                canActivate: [OfflineGuard],
            },
            {
                path: routeParts.settings,
                data: {
                    title: pageTitles.settings,
                },
                loadChildren: () =>
                    import('./settings/settings.module').then(
                        (m) => m.SettingsModule
                    ),
                canLoad: [AuthGuard, OfflineGuard],
                canActivate: [OfflineGuard],
            },
            {
                path: routeParts.products,
                data: {
                    title: pageTitles.products,
                },
                loadChildren: () =>
                    import('./product/product.module').then(
                        (m) => m.ProductModule
                    ),
                canLoad: [AuthGuard],
            },
            {
                path: routeParts.shelfTags,
                data: {
                    title: pageTitles.shelfTags,
                },
                loadChildren: () =>
                    import('./shelf-tags/shelf-tags.module').then(
                        (m) => m.ShelfTagsModule
                    ),
                canLoad: [AuthGuard],
            },
            {
                path: routeParts.stockedNotScanned,
                data: {
                    title: pageTitles.stockedNotScanned,
                },
                loadChildren: () =>
                    import(
                        './stocked-not-scanned/stocked-not-scanned.module'
                    ).then((m) => m.StockedNotScannedModule),
                canLoad: [AuthGuard, OfflineGuard],
                canActivate: [OfflineGuard],
            },
            {
                path: routeParts.inventory,
                data: {
                    title: pageTitles.inventory,
                },
                loadChildren: () =>
                    import('./inventory/inventory.module').then(
                        (m) => m.InventoryModule
                    ),
                canLoad: [AuthGuard],
            },
            {
                path: routeParts.batch,
                data: {
                    title: pageTitles.batch,
                },
                loadChildren: () =>
                    import('./batch/batch.module').then((m) => m.BatchModule),
                canLoad: [AuthGuard],
            },
            {
                path: routeParts.deals,
                data: {
                    title: pageTitles.deals,
                    featureFlag: {
                        allow: FeatureFlag.MissedDeals,
                    },
                },
                loadChildren: () =>
                    import('./missed-deals/missed-deals.module').then(
                        (m) => m.MissedDealsModule
                    ),
                canLoad: [AuthGuard, AuthFeatureFlagGuard, OfflineGuard],
                canActivate: [OfflineGuard],
            },
            {
                path: routeParts.newVendors,
                data: {
                    title: pageTitles.newVendors,
                    featureFlag: {
                        allow: FeatureFlag.NewVendors,
                    },
                },
                loadChildren: () =>
                    import('./new-vendors/new-vendors.module').then(
                        (m) => m.NewVendorsModule
                    ),
                canLoad: [AuthGuard, AuthFeatureFlagGuard, OfflineGuard],
                canActivate: [OfflineGuard],
            },
            {
                path: routeParts.search,
                data: {
                    title: pageTitles.search,
                    featureFlag: {
                        prevent: FeatureFlag.SupportsApl,
                    },
                },
                loadChildren: () =>
                    import('./search/search.module').then(
                        (m) => m.SearchModule
                    ),
                canLoad: [AuthGuard, AuthFeatureFlagGuard, OfflineGuard],
                canActivate: [OfflineGuard],
            },
            {
                path: routeParts.receiving,
                data: {
                    title: pageTitles.receiving,
                    featureFlag: {
                        allow: FeatureFlag.SupportsReceiving,
                    },
                },
                loadChildren: () =>
                    import('./receiving/receiving.module').then(
                        (m) => m.ReceivingModule
                    ),
                canLoad: [AuthGuard, AuthFeatureFlagGuard, OfflineGuard],
                canActivate: [OfflineGuard],
            },
            {
                path: routeParts.savedItems,
                data: {
                    title: pageTitles.myFavorites,
                },
                loadChildren: () =>
                    import('./saved-items/saved-items.module').then(
                        (m) => m.SavedItemsModule
                    ),
                canLoad: [AuthGuard, OfflineGuard],
                canActivate: [OfflineGuard],
            },

            {
                path: routeParts.unauthorized,
                data: {
                    title: pageTitles.unauthorized,
                },
                component: UnauthorizedComponent,
                canLoad: [AuthGuard],
            },
            ...definitionsThatMustBeLast,
        ],
    },
];

@NgModule({
    // Set enableTracing to true to view routing events in the console
    imports: [RouterModule.forRoot(routes, { enableTracing: false })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
