import { Injectable } from '@angular/core';
import { VendorResource } from '@buyiq-app/new-vendors/resources/vendor.resource';
import { Vendor } from '@buyiq-core/models/vendor';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class NewVendorsService {
    constructor(
        private vendorResource: VendorResource
    ) {
    }

    getVendor(legacyId: number): Observable<Vendor> {
        return this.vendorResource.get(legacyId, true);
    }
}
