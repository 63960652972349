import { Injectable } from '@angular/core';
import moment, { Moment } from 'moment';
import { DateOption, DateRange, isoDateFormat } from './date';

@Injectable({
    providedIn: 'root'
})
export class DateService {

    whenIsThisDate(date: Date): string {
        const daysUntilToday = this.daysUntilDate(date);
        let when = 'today';

        if (daysUntilToday > 1) {
            when = `in ${daysUntilToday} days`;
        } else if (daysUntilToday === 1) {
            when = 'tomorrow';
        } else if (daysUntilToday < 1) {
            when = `${Math.abs(daysUntilToday)} days ago`;
        }

        return when;
    }

    isDateInRange(date: Date, daysAgo: number): boolean {
        if (date) {
            const dateToCheck = moment(date).startOf('day');
            const rangeStart = moment().startOf('day').subtract(daysAgo, 'days');
            return dateToCheck.isSameOrAfter(rangeStart);
        }
        return false;
    }

    convertToDate(date: Moment): Date {
        return moment(date).toDate();
    }

    getStartOfDay(date: Moment | Date): Moment {
        return moment(date).startOf('day');
    }

    getEndOfDay(date: Moment | Date): Moment {
        return moment(date).endOf('day');
    }

    /**
     * @description
     * Returns a Moment assuming the given date is in the UTC+0/GMT+0 timezone.
     *
     * If the date string contains an offset, timezone, or Z mark, the timezone will not be adjusted.
     */
    toUtcMoment(date: string | Date | Moment): Moment {
        return moment.utc(date);
    }

    /**
     * @description
     * Returns a Moment assuming the given date is in the user's local timezone.
     *
     * If the date string contains an offset, timezone, or Z mark, the timezone will not be adjusted.
     */
    toLocalMoment(date: string | Date | Moment): Moment {
        return moment(date);
    }

    getExactDateActionDescription(date: Date, action: string): string {
        return `${action} on ${moment(date).format('MM/DD/YYYY')}`;
    }

    getRelativeDateActionDescription(date: Date, action: string): string {
        return `${action} ${moment(date).fromNow()}`;
    }

    isFutureDate(startDate: Date): boolean {
        const today = moment();
        return moment(startDate).isAfter(today);
    }

    isPastDate(endDate: Date): boolean {
        const today = moment();
        return moment(endDate).isBefore(today);
    }

    combineDateAndTimeParts(datePart: Date, timePart: string): Date {
        const dateString = moment(datePart).format('YYYY-MM-DD');
        return moment(`${dateString}T${timePart}`).toDate();
    }

    getTimeString(date: Date): string {
        return moment(date).format('HH:mm');
    }

    getPresetLocalDateRange(dateOptionId: DateOption): DateRange {
        const start = moment();
        const end = moment();

        return this.getDateRange(dateOptionId, start, end);
    }

    getPresetUtcDateRange(dateOptionId: DateOption): DateRange {
        const start = moment.utc();
        const end = moment.utc();

        return this.getDateRange(dateOptionId, start, end);
    }

    getLocalDateRange(startDate: Date, endDate: Date): DateRange {
        const start = moment(startDate);
        const end = moment(endDate);

        return this.getDateRange(DateOption.CustomDate, start, end);
    }

    getUtcDateRange(startDate: Date, endDate: Date): DateRange {
        const start = moment.utc(startDate);
        const end = moment.utc(endDate);

        return this.getDateRange(DateOption.CustomDate, start, end);
    }

    /**
     * @description
     * Returns a date string in ISO 8601 format (YYYY-MM-DD). Useful for representing a calendar date
     * without a specific time attached to it.
     *
     * Does not validate the incoming input, so it is up to the consumer to provide a valid input
     * @param date A date (and optional time) string in ISO 8601 format (YYYY-MM-DDThh:mm:ssZ)
     */
     getIsoDateFromString(date: string): string {
        return (date ?? '').substr(0, 10);
    }

    /**
     * @description
     * Returns a date string in ISO 8601 format (YYYY-MM-DD). Useful for representing a calendar date
     * without a specific time attached to it.
     *
     * @param date A MomentJS moment
     */
    getIsoDateFromMoment(date: Moment): string {
        return (date ?? moment()).format(isoDateFormat);
    }

    private getDateRange(dateOptionId: DateOption, startDate: Moment, endDate: Moment): DateRange {
        let start = startDate.clone();
        let end = endDate.clone();

        switch (dateOptionId) {
            case DateOption.CustomDate:
                start = start.startOf('day');
                end = end.endOf('day');
                break;
            case DateOption.MonthToDate:
                start = start.startOf('month');
                break;
            case DateOption.PreviousMonth:
                start = start.subtract(1, 'month').startOf('month');
                end = end.subtract(1, 'month').endOf('month');
                break;
            case DateOption.YearToDate:
                start = start.startOf('year');
                break;
            case DateOption.PreviousYear:
                start = start.subtract(1, 'year').startOf('year');
                end = end.subtract(1, 'year').endOf('year');
                break;
        }

        return new DateRange({
            startDate: start.toDate(),
            endDate: end.toDate(),
        });
    }

    private daysUntilDate(date: Date): number {
        const today = moment().startOf('day');
        return moment(date, 'YYYY-MM-DD').startOf('day').diff(today, 'days');
    }
}
