import { Injectable } from '@angular/core';
import { Message, MessageDestination, MessageParameters } from '@buyiq-app/system-messages/models/message';
import { MessageRead } from '@buyiq-app/system-messages/models/message-reads';
import { MessageReadsResource } from '@buyiq-app/system-messages/resources/message-reads.resource';
import { MessageResource } from '@buyiq-app/system-messages/resources/message.resource';
import { ApiCollectionResponse, SearchState } from '@cia-front-end-apps/shared/api-interaction';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class MessageService {
    constructor(
        private messageResource: MessageResource,
        private messageReadsResource: MessageReadsResource
    ) {
    }

    getUnreadMessages(userId: number): Observable<Array<Message>> {
        const searchState = new SearchState({
            sortBy: 'startDate',
            sortDirection: 'desc'
        });
        const messageParameters = new MessageParameters({
            elevated: true,
            startDate: new Date(),
            endDate: new Date(),
            destination: MessageDestination.BuyIq
        });
        return forkJoin({
            messages: this.getMessages(searchState, messageParameters),
            messageReads: this.getMessageReads(userId)
        }).pipe(
            map(({ messages, messageReads }) => {
                const readMessageIds = messageReads.map(messageRead => messageRead.messageId);
                const unreadMessages = messages.items.filter(message => {
                    return !readMessageIds.includes(message.id);
                });

                return unreadMessages;
            })
        );
    }

    getMessages(searchState: SearchState, messageParameters: MessageParameters): Observable<ApiCollectionResponse<Message>> {
        return this.messageResource.getAll(searchState, messageParameters);
    }

    createMessageRead(messageRead: MessageRead): Observable<MessageRead> {
        return this.messageReadsResource.create(messageRead);
    }

    getMessageReads(userId: number): Observable<Array<MessageRead>> {
        return this.messageReadsResource.getAll(userId);
    }
}
