import { HttpClientModule } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LoginModule } from '@buyiq-app/login/login.module';
import { FeedbackDialogComponent } from '@buyiq-core/feedback/feedback-dialog/feedback-dialog.component';
import { FeedbackComponent } from '@buyiq-core/feedback/feedback/feedback.component';
import { SharedModule } from '@buyiq-shared/shared.module';
import { AppSettingsModule } from './app-settings/app-settings.module';
import { AudioModule } from './audio/audio.module';
import { AuthModule } from './auth/auth.module';
import { SimpleDialogComponent } from './dialog/simple-dialog/simple-dialog.component';
import { NavigationComponent } from './navigation/navigation.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { StoreSelectComponent } from './store-select/store-select.component';

@NgModule({
    imports: [
        SharedModule,
        AppSettingsModule,
        RouterModule,
        HttpClientModule,
        LoginModule,
        AuthModule,
        AudioModule
    ],
    exports: [
        ToolbarComponent,
        NavigationComponent,
        FeedbackComponent
    ],
    declarations: [
        NotFoundComponent,
        ToolbarComponent,
        NavigationComponent,
        SimpleDialogComponent,
        FeedbackDialogComponent,
        FeedbackComponent,
        StoreSelectComponent
    ]
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        if (parentModule) {
            throw new Error('CoreModule is already loaded. Import it in the AppModule only');
        }
    }
}
