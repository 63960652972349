import { Injectable } from '@angular/core';
import { DialogService } from '@buyiq-core/dialog/dialog.service';
import { DialogConfig, DialogType } from '@buyiq-core/models/dialog';
import { UserStorageKey } from '@buyiq-core/storage/storage';
import { UserStorageService } from '@buyiq-core/storage/user-storage.service';
import { StoreSelectComponent } from '@buyiq-core/store-select/store-select.component';
import { UpcCorrectionConfiguration } from '@cia-front-end-apps/shared/upc-correction';
import { forkJoin, Observable, of } from 'rxjs';
import { map, publishLast, refCount, switchMap, tap } from 'rxjs/operators';
import { User } from '../models/user';
import { UserResource } from '../resources/user.resource';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private user: User;
    private previousUser: User;
    private userRequest: Observable<User>;

    constructor(
        private userResource: UserResource,
        private dialogService: DialogService,
        private userStorageService: UserStorageService
    ) {
    }

    /**
     * @description Returns the user who is currently logged in. If the user information has already
     * been retrieved, it will be returned from the cache; otherwise it will be retrieved from the
     * server.
     */
    getCurrentUser(): Observable<User> {
        const hasLoadedCurrentUser = !!(this.userRequest);

        if (!hasLoadedCurrentUser) {
            // publishLast().refCount() prevents multiple requests to the user resource (and by
            // extension, multiple calls to the API) by turning this request into a ReplaySubject.
            // Subsequent subscribers will get the result from the first call instead of querying
            // the API
            this.userRequest = this.userResource.get()
                .pipe(
                    switchMap(user => forkJoin({
                        remoteUser: of(user),
                        localUser: this.userStorageService.getItem(UserStorageKey.User)
                    })),
                    switchMap(({ remoteUser, localUser }) => {
                        const shouldUpdateLocalUser = this.shouldUpdateLocalUser(remoteUser, localUser);
                        const storeIsDisabled = !remoteUser.stores.find(store => store.id === localUser?.currentStore?.id)?.isBuyiqEnabled;
                        if (shouldUpdateLocalUser || storeIsDisabled) {
                            return this.selectStore(remoteUser);
                        }

                        return of(localUser);
                    }),
                    publishLast(),
                    refCount(),
                    map(user => {
                        this.user = user;
                        // Map userRequest to cached user so any changes to the user object by the
                        // service are reflected in future calls to this function
                        this.userRequest = of(this.user);
                        return this.user;
                    }),
                    tap(user => this.userStorageService.setItem(user, UserStorageKey.User))
                );
        }
        return this.userRequest;
    }

    getPreviousUser(): Observable<User> {
        let request = of(this.previousUser);

        if (!this.previousUser) {
            request = this.userStorageService.getItem(UserStorageKey.PreviousUser)
                .pipe(
                    map(user => {
                        this.previousUser = user;
                        return this.previousUser;
                    })
                );
        }

        return request;
    }

    updateUser(user: User): Observable<User> {
        return this.userResource.replace(user)
            .pipe(
                switchMap(updatedUser => {
                    this.user.settings = updatedUser.settings;
                    return this.userStorageService.setItem(updatedUser, UserStorageKey.User);
                })
            );
    }

    clearCurrentUser(): void {
        this.user = null;
        this.userRequest = null;
        this.previousUser = null;
    }

    getUpcCorrectionConfiguration(user: User): UpcCorrectionConfiguration {
        const isEnabled = user.settings.upcCorrection;

        return new UpcCorrectionConfiguration({
            requiresCheckDigit: (isEnabled && user.settings.calculateCheckDigit) ?? false,
            totalCharactersToTrim: isEnabled ? (user.settings.trimLeadingValues ?? 0) : 0
        });
    }

    cachePreviousUserInfo(): Observable<void> {
        return this.userStorageService.getItem(UserStorageKey.User)
            .pipe(
                switchMap(previousUser => {
                    this.previousUser = previousUser;
                    return this.userStorageService.setItem(previousUser, UserStorageKey.PreviousUser);
                }),
                map(() => {
                })
            );
    }

    clearCurrentUserFromStorage(): Observable<void> {
        return this.userStorageService.removeItem(UserStorageKey.User);
    }

    private selectStore(user: User): Observable<User> {
        const dialogConfig = new DialogConfig({
            component: StoreSelectComponent,
            disableClose: true,
            type: DialogType.FullWidth,
            data: user
        });
        return (user.stores.length > 1 ? this.dialogService.show(dialogConfig) : of(user.currentStore))
            .pipe(
                map(selectedStore => {
                    return new User({
                        ...user,
                        currentStore: selectedStore
                    });
                }),
            );
    }

    /**
     * @description Compares the local user to the remote user to determine if we need to update the local user.
     * @param remoteUser
     * @param localUser
     * @private
     */
    private shouldUpdateLocalUser(remoteUser: User, localUser: User): boolean {
        // If the local user is null or undefined, we need to update the local user
        const hasLocalUser = localUser !== null && localUser !== undefined;
        if (!hasLocalUser) {
            return true;
        }

        // are they even the same user?
        const sameId = localUser?.id === remoteUser.id;
        // have the stores been changed?
        const sameStore = localUser?.currentStore?.id === remoteUser.currentStore.id;
        // are they assigned more or less or different stores?
        const sameStores = localUser?.stores?.length === remoteUser.stores.length && localUser?.stores?.every(store => remoteUser.stores.find(s => s.id === store.id));
        // are the feature flags the same?
        const sameFeatureFlags = localUser?.features?.length === remoteUser.features.length && localUser?.features?.every(flag => remoteUser.features.find(f => f === flag));
        // have their settings been adjusted?
        const sameSettings = Object.keys(localUser.settings).every(key => {
            if (typeof localUser.settings[key] === 'object') {
                const isSameObject = JSON.stringify(localUser.settings[key]) === JSON.stringify(remoteUser.settings[key])
                return isSameObject;
            }
            const isSame = localUser.settings[key] === remoteUser.settings[key];
            return isSame;
        });

        return !sameId
            || !sameStore
            || !sameStores
            || !sameFeatureFlags
            || !sameSettings;
    }
}
