<div (click)="onTogglePanel($event)"
     [class.expanded]="isExpanded"
     [class.scroll]="limitHeight"
     class="header">
    <ng-content select="buyiq-expansion-panel-title"></ng-content>
    <span [class.expanded]="isExpanded"
          class="expansion-indicator">
    </span>
</div>
<div [class.expanded]="isExpanded"
     [class.scroll]="limitHeight"
     class="content">
    <ng-content></ng-content>
</div>
