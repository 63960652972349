import { Injectable } from '@angular/core';
import { NewVendorsService } from '@buyiq-app/new-vendors/services/new-vendors.service';
import { VendorInfo } from '@buyiq-app/product/models/vendor-info';
import { VendorDiscountService } from '@buyiq-app/product/services/vendor-discount.service';
import { VendorInfoStorageService } from '@buyiq-app/product/services/vendor-info-storage.service';
import { VendorDiscountFilter } from '@buyiq-core/models/vendor-discount';
import { UserService } from '@buyiq-core/user/user.service';
import { SearchState } from '@cia-front-end-apps/shared/api-interaction';
import { forkJoin, Observable, of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class VendorInfoService {
    constructor(
        private userService: UserService,
        private newVendorsService: NewVendorsService,
        private vendorDiscountService: VendorDiscountService,
        private vendorInfoStorageService: VendorInfoStorageService
    ) {}

    getVendorInfo(legacyVendorId: number): Observable<VendorInfo> {
        return this.vendorInfoStorageService.getItem(`${legacyVendorId}`).pipe(
            switchMap((vendorInfo) => {
                const hasVendorInfo = !!vendorInfo;
                if (hasVendorInfo) {
                    return of(vendorInfo);
                }

                const searchState = new SearchState({
                    pageSize: 25,
                });
                const filters = new VendorDiscountFilter({
                    legacyVendorId,
                    startDate: new Date(),
                });

                return forkJoin({
                    partitionEnabled: this.newVendorsService
                        .getVendor(legacyVendorId)
                        .pipe(
                            map(
                                (vendor) =>
                                    vendor?.legacyVendor?.partitionEnabled
                            ),
                            catchError(() => of(false))
                        ),
                    discounts: this.userService.getCurrentUser().pipe(
                        switchMap((user) =>
                            this.vendorDiscountService.getDiscounts(
                                user.currentStore.id,
                                searchState,
                                filters
                            )
                        ),
                        map((discounts) => discounts?.items),
                        catchError(() => of([]))
                    ),
                }).pipe(
                    switchMap(({ partitionEnabled, discounts }) => {
                        const newVendorInfo = new VendorInfo({
                            legacyVendorId,
                            partitionEnabled,
                            discounts,
                        });

                        return this.vendorInfoStorageService.setItem(
                            `${legacyVendorId}`,
                            newVendorInfo
                        );
                    })
                );
            })
        );
    }
}
