import { Directive, HostListener, Input } from '@angular/core';
import { onlyNumbersRegex } from '@buyiq-core/models/forms';

@Directive({
    selector: '[buyiqAllowOnlyDigits]'
})
export class AllowOnlyDigitsDirective {
    @Input('buyiqAllowOnlyDigits') allowOnlyDigits = true;

    // keypress is used because it will only be triggered for printable characters
    // We want to allow inputs like Tab, Enter, Delete, and arrow keys
    @HostListener('keypress', ['$event']) onKeyDown(event: KeyboardEvent) {
        if (this.allowOnlyDigits && !this.isNumberInput(event)) {
            event.preventDefault();
        }
    }

    private isNumberInput(event: KeyboardEvent): boolean {
        // event.which is needed for older browsers which don't support event.key
        const eventValue = event.key ?? String.fromCharCode(event.which);
        return onlyNumbersRegex.test(eventValue);
    }
}
