import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from '@buyiq-core/models/app-settings';
import { parseErrorResponse } from '@buyiq-core/models/error-handler';
import { Vendor } from '@buyiq-core/models/vendor';
import { DataResource } from '@buyiq-custom-types';
import { ApiCollectionResponse, SearchState } from '@cia-front-end-apps/shared/api-interaction';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class VendorResource implements DataResource<Vendor> {
    private baseUrl: string;
    private endpointName = 'vendors';

    constructor(private http: HttpClient, private appSettings: AppSettings) {
        this.baseUrl = `${this.appSettings.apis.user}/${this.endpointName}`;
    }

    get(id: number, isLegacyId = false): Observable<Vendor> {
        const url = `${this.baseUrl}/${id}`;
        const requestParams = new HttpParams({
            fromObject: {
                isLegacyId: isLegacyId.toString()
            }
        });
        return this.http.get<Vendor>(url, { params: requestParams });
    }

    getAll(parameters: SearchState): Observable<ApiCollectionResponse<Vendor>> {
        const url = `${this.baseUrl}`;
        const requestParams = new HttpParams({
            fromObject: {
                pageIndex: (parameters.pageIndex || 0).toString(),
                pageSize: (parameters.pageSize || 25).toString(),
                sortBy: parameters.sortBy || '',
                sortDirection: parameters.sortDirection || 'asc',
                search: parameters.search || ''
            }
        });

        return this.http.get<ApiCollectionResponse<Vendor>>(url, { params: requestParams });
    }

    create(vendor: Vendor): Observable<Vendor> {
        const url = `${this.baseUrl}`;
        return this.http.post<Vendor>(url, vendor)
            .pipe(
                catchError(response => {
                    const error = parseErrorResponse(response);
                    return throwError(error);
                })
            );
    }

    replace(vendor: Vendor): Observable<Vendor> {
        const url = `${this.baseUrl}/${vendor.id}`;
        return this.http.put<Vendor>(url, vendor)
            .pipe(
                catchError(response => {
                    const error = parseErrorResponse(response);
                    return throwError(error);
                })
            );
    }
}
