export const defaultFormChangeDebounceTime = 400;
export const wholeNumberRegex = /^[-]?\d+$/;
export const alphanumericRegex = /^[A-Za-z0-9]+$/;
export const nonSpaceCharacterRegex = /^\S+$/;
export const onlyNumbersRegex = /[0-9]/;

export type FormGroupValueFn = () => any;
export type FormUpdateTrigger = 'change' | 'submit' | 'blur';

export class FormChangeEvent<T> {
    value: T;
    isValid: boolean;

    constructor(options?: Partial<FormChangeEvent<T>>) {
        Object.assign(this, options);
    }
}

export class FormSubmitEvent<T> {
    value: T;
    isValid: boolean;

    constructor(options?: Partial<FormSubmitEvent<T>>) {
        Object.assign(this, options);
    }
}

export enum InputType {
    Telephone = 'tel',
    Search = 'search'
}
