import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { fromEvent, mapTo, merge, Observable, of, pairwise, share, Subject, tap } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { DatadogRumService } from '@buyiq-core/analytics/datadog-rum.service';
import { onlineManager } from '@tanstack/react-query';

@Injectable({
    providedIn: 'root'
})
export class OnlineManagerService {
    onlineChanges: Observable<boolean>;
    private online = true;
    private readonly manualOnlineChanges = new Subject<boolean>();

    constructor(
        private datadogRumService: DatadogRumService,
        @Inject(PLATFORM_ID) protected platformId: string,
    ) {
    }

    /**
     * Static getter for the online state
     */
    get isOnline(): boolean {
        return this.online;
    }

    initialize() {
        this.onlineChanges = !isPlatformBrowser(this.platformId) ? of(true) : merge(
            of(navigator.onLine),
            fromEvent(window, 'online').pipe(mapTo(true)),
            fromEvent(window, 'offline').pipe(mapTo(false)),
            this.manualOnlineChanges.asObservable()
        ).pipe(
            share(),
            // to ensure adequate offline detection we allow the manual setting of offline mode
            // because of that we don't want to trigger the app state unless it has changed from
            // offline -> online and vice versa
            distinctUntilChanged(),
            pairwise(),  // This will emit the previous and current value as an array.
            tap(([previous, current]) => {
                if (!previous && current) {
                    // This condition checks if the app went from offline (previous) to online (current).
                    this.datadogRumService.addAction('App went from offline to online.');
                }
            }),
            map(([_, current]) => current),  // Extract only the current value for further processing.
            tap(isOnline => this.online = isOnline)
        );

        onlineManager.subscribe(isOnline => {
            this.setOnline(isOnline);
        });
    }

    /**
     * Sets the online state manually.
     *
     * To be used when we manually detect an offline/online state change via an HTTP request.
     *
     * @param isOnline
     */
    setOnline(isOnline: boolean): void {
        this.manualOnlineChanges.next(isOnline);
    }
}
