import { Injectable } from '@angular/core';
import { DateService } from '../date';
import moment from 'moment';
import { Deal, ProductSpecialAlert, ProductSpecialStatus, Special, SpecialsType, SubscriptionSetting } from './specials';

@Injectable({
    providedIn: 'root'
})
export class SpecialsService {
    constructor(
        private dateService: DateService
    ) {
    }

    isCurrent(special: Special): boolean {
        const startDate = moment(special.startDate, 'YYYY-MM-DD').startOf('day');
        const endDate = moment(special.endDate, 'YYYY-MM-DD').endOf('day');
        return moment().isBetween(startDate, endDate);
    }

    isFuture(special: Special): boolean {
        const startDate = moment(special.startDate, 'YYYY-MM-DD').startOf('day');
        return moment().isBefore(startDate);
    }

    isExpired(special: Special): boolean {
        const endDate = moment(special.endDate, 'YYYY-MM-DD').startOf('day');
        return moment().isAfter(endDate);
    }

    filterBySubscriptions(specials: Array<Special>, subscriptionSpecials: Array<SubscriptionSetting>, vendorId: number): Array<Special> {
        const vendorSubscriptionSetting = (subscriptionSpecials || []).find(s => s.vendorId === vendorId);
        return specials.filter(special => {
            const specialType = special.type || new SpecialsType();
            return (specialType && !specialType.subRequired) ||
                (
                    vendorSubscriptionSetting &&
                    vendorSubscriptionSetting.vendorId === vendorId &&
                    vendorSubscriptionSetting.specialsTypeId === specialType.id
                );
        });
    }

    getSalePrice(specials: Array<Special>): number {
        const correctDeal = this.getBestDeal(specials);
        return correctDeal.discountPrice;
    }

    getBestDeal(specials: Array<Special>): Deal {
        const currentSpecials = specials.filter(special => this.isCurrent(special));
        const sortedSpecials = this.sortByPrecedence(currentSpecials);
        const firstSpecial = sortedSpecials[0] || new Special();
        const firstDeal = firstSpecial.deals[0] || new Deal();
        return firstDeal;
    }

    sortByPrecedence(specials: Array<Special>): Array<Special> {
        return (specials || []).sort((a, b) => {
            // We have to check the individual conditions because if the subRequired value is the same,
            // we want to use the discount to rank specials
            if (a.type.subRequired < b.type.subRequired) {
                return 1;
            }

            if (a.type.subRequired > b.type.subRequired) {
                return -1;
            }

            // Sorts by discount descending
            return b.deals[0].discountPrice - a.deals[0].discountPrice;
        });
    }

    getDateActionText(special: Special): string {
        const datePrefix = this.getDatePrefix(special);
        const dateWhenActionOccurs = this.isFuture(special) ? special.startDate : special.endDate;
        const whenActionWillOccur = this.dateService.whenIsThisDate(dateWhenActionOccurs);

        return `${datePrefix} ${whenActionWillOccur}`;
    }

    buildProductSpecialAlert(special: Special): ProductSpecialAlert {
        let alert = null;

        if (special?.deals?.length > 0) {
            const status = this.getSpecialStatus(special);
            const deal = special.deals[0];
            const primaryText = deal.saleText;
            const minOrder = deal.thresholdQty;
            const datePrefix = this.getDatePrefix(special);
            const dateReference = this.isFuture(special) ? special.startDate : special.endDate;
            const exactDateAction = this.dateService.getExactDateActionDescription(dateReference, datePrefix);
            const relativeDateAction = this.dateService.getRelativeDateActionDescription(dateReference, datePrefix);

            alert = new ProductSpecialAlert({
                dateAction: relativeDateAction,
                minimumOrder: minOrder,
                primaryText: primaryText,
                status: status,
                toolTip: exactDateAction
            });
        }

        return alert;
    }

    buildIconProductSpecialAlert(special: Special): ProductSpecialAlert {
        const fullAlert = this.buildProductSpecialAlert(special);
        let iconAlert = null;

        if (fullAlert) {
            iconAlert = new ProductSpecialAlert({
                status: fullAlert.status,
                toolTip: `${fullAlert.primaryText}, Min Order: ${fullAlert.minimumOrder}, ${fullAlert.dateAction}`
            });
        }

        return iconAlert;
    }

    private getSpecialStatus(special: Special): ProductSpecialStatus {
        let status = ProductSpecialStatus.Expired;
        if (this.isCurrent(special)) {
            status = ProductSpecialStatus.Current;
        }

        if (this.isFuture(special)) {
            status = ProductSpecialStatus.Future;
        }

        return status;
    }

    private getDatePrefix(special: Special): string {
        let prefix = 'Expires';

        if (this.isExpired(special)) {
            prefix = 'Expired';
        } else if (this.isFuture(special)) {
            prefix = 'Starts';
        }

        return prefix;
    }
}
