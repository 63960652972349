import { ApplicationId } from '@buyiq-core/models/application';

export class Impression {
    id: number;
    promotions: Array<number>;
    promotionType: string;
    userId: number;
    storeId: number;
    vendorId: number;
    application: ApplicationId;
    page: string;
    component: string;
    upc: string;
    quantity: number;

    constructor(options?: Partial<Impression>) {
        Object.assign(this, options);
    }
}
