import { Injectable } from '@angular/core';
import { BatchItem, BatchItemUpdate } from '@buyiq-app/batch/models/batch-item';
import { ProductParameters, ProductSearchType } from '@buyiq-app/product/models/product';
import { ProductScanConfiguration, scanSourceNotificationSuffix, ScheduledScan } from '@buyiq-core/models/scan';
import { UserService } from '@buyiq-core/user/user.service';
import { Notification, NotificationType } from '@buyiq-shared/models/notification';
import { combineLatest, concatMap, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { ScanPipelineService } from './scan-pipeline.service';
import { ScanQueueService } from './scan-queue.service';

@Injectable({
    providedIn: 'root'
})
export class ScanService {
    private scheduledScan: ScheduledScan;
    private inProgressScans = new Set<string>();
    private lastScannedOrder: number;

    constructor(
        private userService: UserService,
        private scanPipelineService: ScanPipelineService,
        private scanQueueService: ScanQueueService
    ) {
    }

    getScheduledScan(): ScheduledScan {
        const scan = this.scheduledScan;
        this.scheduledScan = null;
        return scan;
    }

    scheduleScan(scheduledScan: ScheduledScan): void {
        this.scheduledScan = scheduledScan;
    }

    buildScheduledScanNotification(scheduledScan: ScheduledScan): Notification {
        const destination = scanSourceNotificationSuffix.get(scheduledScan.source) ?? 'Previous Page';

        return new Notification({
            type: NotificationType.Info,
            text: `Return to ${destination}`
        });
    }

    /**
     * @description Scans a product and updates it in the batch. This method does not set the product as the active product in the batch
     * service. Individual components should set that value. This allows scans to happen in the background without affecting the currently
     * displayed item.
     */
    scanProduct(config: ProductScanConfiguration): Observable<BatchItemUpdate> {
        this.scanQueueService.queueScan(config.searchText);

        // On the first scan, we generate an API request. Subsequent scans return null to avoid multiple requests.
        // The product page will filter out null requests, so only the first scan's API request will result in a UI update.
        let apiRequest = of(null) as Observable<BatchItemUpdate>;

        if (!this.inProgressScans.has(config.searchText)) {
            this.inProgressScans.add(config.searchText);

            const batchItemRequest = this.userService.getCurrentUser()
                .pipe(
                    tap(currentUser => config.user = currentUser),
                    map(() => this.scanPipelineService.getBatchItem(
                            config.searchText,
                            config?.productParameters?.searchType ?? ProductSearchType.Upc
                        )
                    ),
                    concatMap(batchItemUpdate => this.scanPipelineService.getBatchItemProductData(batchItemUpdate, config))
                );
            apiRequest = combineLatest({
                batchItemUpdate: batchItemRequest,
                // This will be triggered when the user scans a different value or stops scanning for the debounce period
                queueBuffer: this.scanQueueService.bufferFlushes
            })
                .pipe(
                    map(streams => {
                        const batchItemUpdate = streams.batchItemUpdate;
                        const aplDiscontinued = batchItemUpdate?.product?.aplDiscontinued;
                        config.numberOfTimesScanned = streams.queueBuffer.length ?? 1;
                        config.quantityOverride = aplDiscontinued ? 0 : null;
                        config.selectedVendorAttribute = batchItemUpdate?.batchItemSnapshot?.selectedVendorAttribute ?? null;
                        return this.scanPipelineService.mapUpdatesToBatchItem(batchItemUpdate, config);
                    }),
                    map(batchItemUpdate => this.scanPipelineService.mapScannedOrderToBatchItem(batchItemUpdate)),
                    concatMap(batchItemUpdate => {
                        const aplDiscontinued = batchItemUpdate?.product?.aplDiscontinued;
                        const hasQuantity = batchItemUpdate?.updatedBatchItem?.quantity > 0;
                        return aplDiscontinued || !hasQuantity
                            ? of(batchItemUpdate)
                            : this.scanPipelineService.uploadBatchItemChanges(batchItemUpdate, config);
                    }),
                    tap(() => this.inProgressScans.delete(config.searchText))
                );
        }

        return apiRequest;
    }

    /**
     * @description
     * Looks up the product data for a batch item and updates internal properties
     */
    rehydrateBatchItem(batchItem: BatchItem): Observable<BatchItemUpdate> {
        const config = new ProductScanConfiguration({
            searchText: batchItem.upc,
            selectedLegacyVendorId: batchItem.vendorId,
            quantityOverride: batchItem.quantity,
            productParameters: new ProductParameters({
                isExactPhrase: true,
                searchType: ProductSearchType.Upc
            }),
            srpOverride: batchItem?.srp ?? null,
            wspOverride: batchItem?.wsp ?? null
        });

        const initialBatchItemUpdate = new BatchItemUpdate({
            batchItemSnapshot: batchItem,
            product: null
        });

        return this.userService.getCurrentUser()
            .pipe(
                tap(currentUser => {
                    const isUpcSearch = !(currentUser.settings.skuLookupEnabled);
                    const vendorIds = [];
                    if (!isUpcSearch && currentUser.settings.skuLookupVendorId) {
                        vendorIds.push(currentUser.settings.skuLookupVendorId);
                    }

                    config.user = currentUser;
                    config.productParameters.searchType = isUpcSearch ? ProductSearchType.Upc : ProductSearchType.Sku;
                    config.productParameters.vendors = vendorIds;
                }),
                switchMap(() => this.scanPipelineService.getBatchItemProductData(initialBatchItemUpdate, config)),
                map(batchItemUpdate => this.scanPipelineService.mapUpdatesToBatchItem(batchItemUpdate, config))
            );
    }
}
