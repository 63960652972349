import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BatchItem } from '@buyiq-app/batch/models/batch-item';
import { AppSettings } from '@buyiq-core/models/app-settings';
import { parseErrorResponse } from '@buyiq-core/models/error-handler';
import { DataResource } from '@buyiq-custom-types';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DatadogRumService } from '@buyiq-core/analytics/datadog-rum.service';

@Injectable({
    providedIn: 'root'
})
export class BatchResource implements DataResource<BatchItem> {
    private readonly baseUrl: string;

    constructor(
        private http: HttpClient,
        private appSettings: AppSettings,
        private datadogRumService: DatadogRumService
    ) {
        this.baseUrl = this.appSettings.apis.order;
    }

    getAll(chainStoreId: number): Observable<Array<BatchItem>> {
        const url = this.buildUrl();

        const requestParams = new HttpParams({
            fromObject: {
                chainStoreId: chainStoreId.toString()
            }
        });
        return this.http.get<Array<BatchItem>>(url, { params: requestParams });
    }

    create(chainStoreId: number, batchItem: BatchItem): Observable<BatchItem> {
        const url = this.buildUrl();
        const requestParams = new HttpParams({
            fromObject: {
                chainStoreId: chainStoreId.toString()
            }
        });
        if (batchItem.scannedOrder === null){
            batchItem.scannedOrder = 1;
        }

        return this.http.post<BatchItem>(url, batchItem, { params: requestParams }).pipe(
                catchError((err) => {
                    const errDescription = parseErrorResponse(err);
                    this.datadogRumService.addError(
                        errDescription,
                        'Post Batch Item',
                        batchItem
                    );
                    return throwError(() => errDescription);
                })
               );
    }

    replace(batchItemId: number, batchItem: BatchItem): Observable<BatchItem> {
        const url = this.buildUrl(batchItemId);
        return this.http.put<BatchItem>(url, batchItem).pipe(
            catchError(err => {
                return throwError(() => parseErrorResponse(err));
            })
        );
    }

    remove(batchItemId: number): Observable<boolean> {
        const url = this.buildUrl(batchItemId);
        return this.http.delete(url)
            .pipe(
                map(successResponse => true),
                catchError(err => {
                return throwError(() => parseErrorResponse(err));
            })
        );
    }

    update(
        chainStoreId: number,
        accountNumber: string,
        vendorId: number,
        directToVendor: boolean,
    ): Observable<boolean> {
        const url =  `${this.buildUrl()}/queue`;
        const requestParams = new HttpParams({
            fromObject: {
                chainStoreId: chainStoreId.toString(),
                accountNumber: accountNumber || '',
                isToDirect: (directToVendor).toString(),
                vendorId: (vendorId || '').toString()
            }
        });
        return this.http.patch<BatchItem>(url, {}, { params: requestParams })
            .pipe(
                map(successResponse => true),
                catchError(response => {
                    const error = parseErrorResponse(response);
                    return throwError(() => error);
                })
            );
    }


    private buildUrl(batchItemId?: number): string {
        let url = `${this.baseUrl}/batch-items`;

        if (batchItemId) {
            url = `${url}/${batchItemId}`;
        }

        return url;
    }
}
